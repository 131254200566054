import { Equipment } from '@/shared/types/equipment.types.ts';
import { Employee } from '@/shared/types/assets.types.ts';
import { ReadersProps } from '@/shared/types/readers.types.ts';

export type AlertPageProps = {
  forward?: boolean;
  hasNext: boolean;
  pageEndResPointer: string;
  pageItemsCount: number;
  pageStartResPointer: string;
};

export type AlertDataProps = {
  page: AlertPageProps;
  results: AlertProps[];
};

export type MetricDataProps = {
  metrics: MetricProps[];
};

export interface MetaProps extends Equipment, Employee, ReadersProps {
  id: string;
  alertResName: string;
  actionUrls?: string;
}

export type AlertProps = {
  alertResName: string;
  accountResName: string;
  siteResName: string;
  deviceResName: string;
  assetResName: string;
  alertName: string;
  alertDescription: string;
  alertCategory: ActionCategoryEnum;
  attribute: ActionSubCategoryEnum;
  alertSeverity: ActionSeverityEnum;
  metadata?: string;
  actionUrls?: string;
  active: boolean;
  timestamp: number;
} & { meta?: MetaProps };

export type MetricProps = {
  alertResName: string; // TODO: Determine if this is valid for metrics
  accountResName: string;
  siteResName: string;
  deviceResName: string;
  metricName: string;
  metricValue: string;
  timestamp: number;
} & { meta?: MetaProps };

export enum ActionSeverityEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  DEFAULT = 'default',
}

export enum ActionCategoryEnum {
  SYSTEM = 'system',
  SAFETY = 'safety',
  EQUIPMENT = 'equipment',
  ZONE = 'zone',
  RESERVATION = 'reservation',
}

export enum ActionSubCategoryEnum {
  TAG = 'tag',
  READER = 'reader',
  AFTER_HOURS = 'after-hours',
  DANGER = 'danger',
  EMERGENCY = 'emergency',
  UTILIZATION = 'utilization',
  RESERVATION = 'reservation',
  WORK_ZONE = 'work_zone',
  RED_ZONE = 'red_zone',
  BEHIND = 'behind',
  UPDATE = 'update',
  ZONE = 'zone',
}

export enum ActionTitleEnum {
  LOW_BATTERY = 'device.battery.low',
  CRITICAL_BATTERY = 'device.battery.critical',
  DEVICE_UNRESPONSIVE = 'device.state.unresponsive',
  TAG_ONLINE = 'new.tag.online',
  TAG_OFFLINE = 'device.tag.offline',
  TAG_LEFT_SITE = 'tag.left.site',
  READER_ONLINE = 'new.reader.online',
  READER_OFFLINE = 'device.reader.offline',
  EQUIPMENT_NOT_UTILIZED_ON_WORKDAY = 'equipment.utilization.notutilizedworkday',
  NEW_RESERVATION = 'equipment.reservation.new',
  RESERVATION_CONFLICT = 'equipment.reservation.conflict',
  EQUIPMENT_REDZONE_ACTIVATED = 'equipment.redzone.activated',
  EQUIPMENT_REDZONE_RELEASED = 'equipment.redzone.released',
  EQUIPMENT_ENTERED_RED_ZONE = 'equipment.redzone.entered',
  EQUIPMENT_RESERVATION_REQUEST = 'equipment.reservation.subcontractor.request',
  NO_UPCOMING_EQUIPMENT_RESERVATION = 'equipment.reservation.no_upcoming',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED = 'equipment.reservation.subcontractor.request.approved',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED = 'equipment.reservation.subcontractor.request.rejected',
  EQUIPMENT_RESERVATION_APPROVED = 'equipment.reservation.approved',
  WORKERS_LEFT_SITE_FOR_DAY = 'workers.left.site.for.day',
  WORKERS_STILL_ON_SITE_AFTER_5PM = 'workers.still.on.site.after.5pm',
  WORKER_REDZONE_ENTERED = 'worker.redzone.entered',
  EQUIPMENT_RESERVED_NOTINUSE = 'equipment.reserved.notinuse',
  EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS = 'equipment.still.in.use.after.reservation.ends',
  EQUIPMENT_IN_USE_WITHOUT_RESERVATION = 'equipment.in.use.without.reservation',
}
