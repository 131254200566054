import {
  SettingsKeyEnum,
  SettingsNameEnum,
  SettingsResultProps,
} from '@/shared/api/settings/settings.types.ts';
import { LocaleEnum } from '@/shared/types/i18n.types.ts';
import { GetSettingValueProps } from '@/shared/types/settings.types.ts';
import {
  CurrencyEnum,
  DisplayTimeZoneEnum,
  SystemViewEnum,
  TimeFormatEnum,
} from '@/shared/types/system.types.ts';

export class SettingsService {
  private userSettings: SettingsResultProps[];
  private readonly settingsName: SettingsNameEnum;
  private readonly settingsKey: SettingsKeyEnum;

  constructor(
    userSettings: SettingsResultProps[],
    settingsName: SettingsNameEnum,
    settingsKey: SettingsKeyEnum,
  ) {
    this.userSettings = userSettings;
    this.settingsName = settingsName;
    this.settingsKey = settingsKey;
  }

  public getUserSettingValue(): GetSettingValueProps | null {
    const currentSettings = this.userSettings.find(
      setting => setting.settingsName === this.settingsName,
    );

    const currentSettingsValue = currentSettings?.extraProperties.find(
      property => property.key === this.settingsKey,
    );

    if (!currentSettingsValue) return null;

    return JSON.parse(currentSettingsValue?.value);
  }

  public getExistingSetting(): SettingsResultProps | undefined {
    return this.userSettings.find(setting => setting.settingsName === this.settingsName);
  }

  public getSelectedLanguage(): LocaleEnum {
    return this.getUserSettingValue()?.language ?? LocaleEnum.EN;
  }

  public getSelectedCurrency(): CurrencyEnum {
    return this.getUserSettingValue()?.currency ?? CurrencyEnum.USD;
  }

  public getSelectedSiteTimeZone(): string {
    return this.getUserSettingValue()?.siteTimeZone ?? '';
  }

  public getSelectedDisplayTimeZone(): DisplayTimeZoneEnum {
    return this.getUserSettingValue()?.displayTimeZone ?? DisplayTimeZoneEnum.SITE;
  }

  public getSelectedTimeFormat(): TimeFormatEnum {
    return this.getUserSettingValue()?.timeFormat ?? TimeFormatEnum.H24;
  }

  public getSelectedMeasurementSystem(): SystemViewEnum {
    return this.getUserSettingValue()?.measurementSystem ?? SystemViewEnum.METRIC;
  }

  public getSelectedFilters() {
    return this.getUserSettingValue()?.filters ?? {};
  }

  public getSelectedWorkersVisibility(): boolean {
    return this.getUserSettingValue()?.visible ?? true;
  }

  public getSelectedEquipmentVisibility(): boolean {
    return this.getUserSettingValue()?.visible ?? true;
  }

  public getSelectedReadersVisibility(): boolean {
    return this.getUserSettingValue()?.visible ?? false;
  }

  public getSelectedTags(): string {
    return this.getUserSettingValue()?.tag ?? '';
  }

  public getSelectedStartTime(): string {
    return this.getUserSettingValue()?.startTime ?? '';
  }

  public getSelectedEndTime(): string {
    return this.getUserSettingValue()?.endTime ?? '';
  }

  public getSettingsKey(): SettingsKeyEnum {
    return this.settingsKey;
  }

  public getSettingsName(): SettingsNameEnum {
    return this.settingsName;
  }
}
