import { createPortal } from 'react-dom';
import { Outlet } from 'react-router-dom';

import { useBuildTimeHooks } from '@/shared/api/build-time/use-build-time.hooks.ts';
import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';

export const UpdaterLayout = () => {
  const { showPrompt, reloading, handleReload } = useBuildTimeHooks.useUpdater();

  return (
    <>
      <Outlet />

      {showPrompt &&
        createPortal(
          <>
            <div className="fixed inset-0 z-[10000] h-screen w-screen bg-black/60" />

            <div className="fixed bottom-0 left-0 z-[10001] w-screen rounded-tl-2xl rounded-tr-2xl bg-white">
              <div className="container flex flex-col gap-4 py-6 md:flex-row md:justify-between md:gap-0">
                <div className="flex flex-col gap-2 md:gap-1">
                  <Typography
                    as="h1"
                    className="text-center !text-2xl font-semibold !text-blue-600 md:text-left"
                  >
                    {translate('updates.newVersionAvailable')}
                  </Typography>

                  <Typography
                    as="p"
                    className="text-center text-sm font-medium text-slate-950 md:text-left"
                  >
                    {translate('updates.newVersionDescription')}
                  </Typography>
                </div>

                <div className="grid place-items-center">
                  <Button
                    variant="solid"
                    isLoading={reloading}
                    className="w-full md:px-12 md:py-3"
                    onClick={handleReload}
                  >
                    {translate('updates.reload')}
                  </Button>
                </div>
              </div>
            </div>
          </>,
          document.body,
        )}
    </>
  );
};
