import { useGlobalStore } from '@/shared/store/global';
import { MetaProps } from '@/shared/types/actions.types';

export const useModifyAlertsData = () => {
  const workers = useGlobalStore(store => store.workers);
  const equipment = useGlobalStore(store => store.equipment);
  const readers = useGlobalStore(store => store.readers);

  const modifiedAlertsData = <
    T extends { deviceResName: string; alertResName: string; actionUrls?: string },
  >(
    data: T[],
  ) => {
    return data?.map(item => {
      const renderDeviceResource = () => {
        if (item.deviceResName.includes('tag-')) return item.deviceResName.replace('tag-', '');
        return item.deviceResName;
      };

      const modifiedDeviceResourceName = renderDeviceResource();

      const metaAsset =
        equipment.find(iterator => iterator.assetResName === modifiedDeviceResourceName) ||
        workers.find(iterator => iterator.assetResName === modifiedDeviceResourceName) ||
        readers.find(iterator => iterator.readerResName === modifiedDeviceResourceName);

      const meta = {
        ...metaAsset,
        alertResName: item.alertResName,
        actionUrls: item.actionUrls,
      } as MetaProps;
      return { ...item, meta };
    });
  };

  return { modifiedAlertsData };
};
