import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';

type Props = {
  labelText: string | JSX.Element;
  inline?: boolean;
  className?: string;
};

export const LabelText: FC<Props> = ({ labelText, inline, className }) => {
  return (
    <Typography
      className={classnames(
        'text-sm font-medium text-slate-950',
        {
          'w-[50px] shrink-0': inline,
        },
        className,
      )}
      as="span"
    >
      {labelText}
    </Typography>
  );
};
