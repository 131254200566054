import { Euler, Vector3 } from 'three';

export const MAP_FILE_PATH = {
  FALLBACK: {
    DEFAULT: 'maps/default-map.dae',
  },
};

export const MAPVIEW_FALLBACK = {
  SIDE: {
    rotation: [1, 0, 0] as unknown as Euler,
    scale: 0.16,
    position: [-7, -3.5, 0] as unknown as Vector3,
  },
  TOP: {
    rotation: [1.555, 0, 0] as unknown as Euler,
    scale: 0.1,
    position: [-5.6, -3, 0] as unknown as Vector3,
  },
  FULL: {
    rotation: [1, 0, 0] as unknown as Euler,
    scale: 0.16,
    position: [-7, -3.5, 0] as unknown as Vector3,
  },
};

export const GEOFENCE_INITIAL = {
  OPACITY: 0.3,
  RED_ZONE_COLOR: '#EF4444',
  RED_ZONE_COLOR_PRIMARY: '#991B1B',
  GREEN_ZONE_COLOR: '#22C55E',
  GREEN_ZONE_COLOR_PRIMARY: '#166534',
  INACTIVE_COLOR: '#6B7280',
  INACTIVE_COLOR_PRIMARY: '#5b6066',
  DEFAULT_ORDER: 9999,
};

interface ClusterConfig {
  CLUSTER_GRID_SIZE: number;
  CLUSTER_ZOOM_LEVEL: number;
  CLUSTER_ZOOM_LEVEL_CUSTOM: {
    [key: string]: number;
  };
}

export const CLUSTER_CONFIG: ClusterConfig = {
  CLUSTER_GRID_SIZE: 10,
  CLUSTER_ZOOM_LEVEL: 4,
  CLUSTER_ZOOM_LEVEL_CUSTOM: {
    'site-38df7d876e454d10bc89b0b8a32fd438': 2,
  },
};
