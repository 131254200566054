import { baseQuery } from '@/shared/api/utils';

import { EmployeeListResponse, EmployeeResponse } from '@/shared/api/zildee/employee/types';

export namespace EmployeeAPINamespace {
  const client = baseQuery('ZILDEE');

  export async function getAll(siteResourceName: string): Promise<EmployeeListResponse> {
    const result = await client.get(`/employee/?site=${siteResourceName}`);
    return result.data;
  }
  export async function getOne(id: string): Promise<EmployeeResponse> {
    const result = await client.get(`/employee/${id}`);
    return result.data;
  }
}
