import { IFlagsmithFeature } from 'flagsmith/types';
import { useFeatureFlags } from '@/shared/hooks/global/useFeatureFlags.ts';

export const useAlertsFeatureFlag = () => {
  const { flags } = useFeatureFlags();

  const { show_alerts } = flags;

  const alertsImplementation = {
    ...(show_alerts as IFlagsmithFeature),
    enabled: show_alerts.value?.toString().includes(import.meta.env.VITE_ENVIRONMENT_ACTIONS_ENV),
  };

  return { alertsImplementation };
};
