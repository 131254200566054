import { ChangeEvent, FC, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { Input } from '@/shared/components/form/Input/Input';
import { classnames } from '@/shared/utils/classnames';

import { ChildElementProps } from '@/shared/components/form/Input/Input.types';
import Search from '@/assets/icons/search.svg?react';

export const SearchInput: FC<
  ChildElementProps & {
    placeholder?: string;
    onChange?: (value: string) => void;
  }
> = ({ className, disabled, onChange, ...props }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const debounced = useThrottledCallback(value => {
    setSearchValue(value);

    if (onChange) onChange(value);
  }, 100);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    debounced(event.target.value);
  };

  const handleInputClear = () => {
    setSearchValue('');

    if (onChange) onChange('');
  };

  const isCloseIconVisible = inputRef?.current?.value && searchValue !== '';

  return (
    <>
      <Input
        ref={inputRef}
        className={classnames('pl-[42px] pr-9', className)}
        disabled={disabled}
        value={searchValue}
        onChange={handleOnChange}
        aria-description="filter search"
        placeholder="sidebar.search"
        {...props}
      />

      <Search
        className="absolute left-4 top-1/2 h-3.5 w-3.5 -translate-y-1/2 transform"
        role="search"
      />

      {isCloseIconVisible && (
        <IconButton
          icon="close-sm"
          className="absolute right-4 top-1/2 -translate-y-1/2 transform"
          aria-label="Clear search input"
          onClick={handleInputClear}
        />
      )}
    </>
  );
};
