import { MAP_FILE_PATH } from '@/modules/Home/utils/MapModel.config.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useInitialTrackersMapData, useLastTrackersLocation } from '@/modules/Home/hooks/map';
import { usePopupStore } from '@/shared/store/popup';

import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { useUIStore } from '@/shared/store/ui';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

export const useMapModel = (filteredSocketMapData: SocketMapDataProps[]) => {
  const [searchParams] = useSearchParams();
  const globalStore = useGlobalStore(state => state);
  const popups = usePopupStore(state => state.popups);
  const mapFilter = useUIStore(store => store.mapFilter);
  const sidebarSearchKey = useGlobalStore(state => state.sidebarFilterValue);
  const initialCameraDistance = useUIStore(store => store.distance);
  const closeActivePopups = usePopupStore(state => state.closeActivePopups);
  const assetResourceName = searchParams.get(URL_PARAMS.ASSET_RES_NAME);

  const filteredInitialTrackersMapData = useInitialTrackersMapData(
    filteredSocketMapData,
    globalStore.workers,
    globalStore.equipment,
  );

  const lastTrackersLocation = useLastTrackersLocation(
    globalStore.modifyLastLocations,
    globalStore.equipmentInUse,
    mapFilter,
    sidebarSearchKey,
  );

  const currentMap = MAP_FILE_PATH.FALLBACK.DEFAULT;
  const currentGeofenceMap = globalStore.geofenceMapBlobUrl;

  const filteredReaders = globalStore.readers.filter(
    reader =>
      reader.readerLocation && (reader.visible || assetResourceName === reader.readerResName),
  );

  return {
    ...globalStore,
    closeActivePopups,
    currentGeofenceMap,
    currentMap,
    filteredInitialTrackersMapData,
    filteredReaders,
    lastTrackersLocation,
    popups,
    initialCameraDistance,
  };
};
