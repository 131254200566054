import { Outlet } from 'react-router-dom';

import { TEST_ID } from '@/shared/utils/constants';
import { useAuthRefresh } from '@/shared/hooks/auth/useAuthRefresh';

export const ConnectionLayout = () => {
  useAuthRefresh();

  return <Outlet data-testid={TEST_ID.OUTLET} />;
};
