/* eslint-disable no-await-in-loop */
import { baseQuery, signRequest } from '@/shared/api/utils';

import { AlertDataProps, AlertProps, MetricDataProps } from '@/shared/types/actions.types';
import { subDays } from 'date-fns';

export namespace ActionsAPINamespace {
  const client = baseQuery('ACTIONS');

  export const getAllAlerts = async (
    accountResourceName: string,
    siteResourceName: string,
    userResourceName: string,
    fetchArchived: boolean = true,
  ): Promise<[AlertProps[], AlertProps[]]> => {
    const unarchivedUrl = `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts`;
    const signedUnarchivedRequest = await signRequest('ACTIONS', unarchivedUrl, 'GET');
    const unarchivedResponse = await client.get<AlertDataProps>(signedUnarchivedRequest.url, {
      headers: signedUnarchivedRequest.headers,
    });

    const archivedAlerts: AlertProps[] = [];
    let keepProbingArchivedAlerts = true;
    let lastResourcePointer = '';

    if (fetchArchived) {
      while (keepProbingArchivedAlerts) {
        try {
          const currentTimestamp = new Date().getTime();
          const twoWeeksAgoTimestamp = subDays(new Date(), 14).getTime();
          const archivedUrl = `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts?alertStates=archived&limit=100&startTime=${twoWeeksAgoTimestamp}&endTime=${currentTimestamp}&resourcePointer=${lastResourcePointer}`;
          const signedArchivedRequest = await signRequest('ACTIONS', archivedUrl, 'GET');

          const archivedResponse = await client.get<AlertDataProps>(signedArchivedRequest.url, {
            headers: signedArchivedRequest.headers,
          });

          lastResourcePointer = archivedResponse.data.page.hasNext
            ? archivedResponse.data.page.pageEndResPointer
            : '';
          // lastResourcePointer = archivedResponse.data.results.at(-1)?.alertResName ?? '';

          // if (archivedResponse.data.results.every(alert => alert.timestamp >= twoWeeksAgoTimestamp)) {
          //   keepProbingArchivedAlerts = false;
          // }

          archivedAlerts.push(...archivedResponse.data.results);

          if (archivedResponse.data.results.length === 0) keepProbingArchivedAlerts = false;
        } catch (error) {
          console.error(error);
          keepProbingArchivedAlerts = false;
        }
      }
    }

    return [unarchivedResponse.data.results, archivedAlerts];
  };

  export const archiveAlert = async (
    accountResourceName: string,
    siteResourceName: string,
    userResourceName: string,
    alertResourceName: string,
  ): Promise<AlertDataProps> => {
    const body = { actionType: 'archive' };
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/users/${userResourceName}/alerts/${alertResourceName}/actions`;
    const signedRequest = await signRequest('ACTIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllMetrics = async (
    accountResourceName: string,
    siteResourceName: string,
  ): Promise<MetricDataProps> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/metrics`;
    const signedRequest = await signRequest('ACTIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}

// 1726083696411
// 1729168844607
