export enum SystemViewEnum {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum CurrencyEnum {
  USD = 'USD',
  JPY = 'JPY',
}

export enum DisplayTimeZoneEnum {
  SITE = 'site',
  BROWSER = 'browser',
}

export enum TimeFormatEnum {
  H12 = '12h',
  H24 = '24h',
}
