import { ChangeEvent, ForwardedRef, forwardRef, useState, useEffect } from 'react';

import { Input } from '@/shared/components/form/Input/Input';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { onlyNumbers } from '@/shared/utils/helpers';

import { CoordinateValueInputProps } from '@/shared/types/geofences.types';

export const CoordinateValueInput = forwardRef(
  (
    { className, defaultValue, onChange, error }: CoordinateValueInputProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [valueX, setValueX] = useState<string>('');
    const [valueY, setValueY] = useState<string>('');

    useEffect(() => {
      if (defaultValue) {
        setValueX(String(defaultValue.x));
        setValueY(String(defaultValue.y));
      }
    }, [defaultValue]);

    useEffect(() => {
      if (valueX && valueY) {
        onChange({ x: String(valueX), y: String(valueY) });
      }
    }, [onChange, valueX, valueY]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;

      switch (name) {
        case 'x':
          setValueX(onlyNumbers(value));
          break;

        case 'y':
          setValueY(onlyNumbers(value));
          break;

        default:
          break;
      }
    };

    return (
      <div className={classnames('flex gap-x-1', className)} ref={ref}>
        <div className="relative">
          <Typography className="absolute left-4 translate-y-[9px] text-sm text-slate-500">
            X:
          </Typography>

          <Input
            name="x"
            type="number"
            className="w-full pl-8 text-sm"
            min={0}
            max={270}
            value={String(valueX)}
            onChange={handleOnChange}
            error={error}
            isErrorLabelHidden
          />
        </div>

        <div className="relative">
          <Typography className="absolute left-4 translate-y-[9px] text-sm text-slate-500">
            Y:
          </Typography>

          <Input
            name="y"
            type="number"
            className="w-full pl-8 text-sm"
            min={0}
            max={130}
            value={String(valueY)}
            onChange={handleOnChange}
            error={error}
            isErrorLabelHidden
          />
        </div>
      </div>
    );
  },
);
