import { classnames } from '@/shared/utils/classnames.ts';
import { FC } from 'react';

import { TransitionChild } from '@/shared/components/animation/TransitionChild';

import { WithClassName } from '@/shared/types/common.types.ts';

export const Overlay: FC<WithClassName> = ({ className }) => {
  return (
    <TransitionChild to="overlay">
      <div className={classnames('fixed inset-0', className)} />
    </TransitionChild>
  );
};
