import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MODE, URL_PARAMS } from '@/shared/utils/constants';
import { getCurrentDay } from '@/shared/utils/date-fns';
import { useCurrentRoute } from '@/shared/hooks/global/useCurrentRoute.ts';

export const useExecuteMapParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoginPage, isHomePage } = useCurrentRoute();

  useEffect(() => {
    if (isLoginPage || !isHomePage) return;

    const mode = searchParams.get(URL_PARAMS.MODE);
    const day = searchParams.get(URL_PARAMS.DAY);
    const date = searchParams.get(URL_PARAMS.DATE);

    if (mode === MODE.HISTORICAL) {
      setSearchParams(searchParams => {
        if (!mode) searchParams.set(URL_PARAMS.MODE, MODE.REALTIME);
        if (!day) searchParams.set(URL_PARAMS.DAY, getCurrentDay(new Date()));
        if (!date) searchParams.set(URL_PARAMS.DATE, '');

        return searchParams;
      });
    }
  }, [isHomePage, isLoginPage, searchParams, setSearchParams]);
};
