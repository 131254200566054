import toast from 'react-hot-toast';

import { ModifiedToastOptions, PromiseMessages } from '@/shared/types/notify-service.types';

const DEFAULT_ARIA_PROPS = {
  role: 'status',
  'aria-live': 'polite',
  'aria-details': 'info',
} as const;

class NotifyService {
  success(message: JSX.Element | string, options?: ModifiedToastOptions): void {
    toast.success(message, {
      ariaProps: {
        ...DEFAULT_ARIA_PROPS,
        'aria-details': 'success',
      },
      ...options,
    });
  }

  error(message: JSX.Element | string, options?: ModifiedToastOptions): void {
    toast.error(message, {
      ariaProps: {
        ...DEFAULT_ARIA_PROPS,
        'aria-details': 'error',
      },
      ...options,
    });
  }

  info(message: JSX.Element | string, options?: ModifiedToastOptions): void {
    toast(message, {
      ariaProps: {
        ...DEFAULT_ARIA_PROPS,
      },
      ...options,
    });
  }

  loading(message: JSX.Element | string, options?: ModifiedToastOptions): void {
    toast.loading(message, {
      ariaProps: {
        ...DEFAULT_ARIA_PROPS,
        'aria-details': 'loading',
      },
      ...options,
    });
  }

  async promise(
    promise: Promise<unknown>,
    messages: PromiseMessages,
    options?: ModifiedToastOptions,
  ): Promise<void> {
    await toast.promise(promise, messages, {
      ariaProps: {
        ...DEFAULT_ARIA_PROPS,
        'aria-details': 'request in progress',
      },
      ...options,
    });
  }
}

export const notifyService = new NotifyService();
