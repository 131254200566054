import { createQueryKeys } from '@lukemorales/query-key-factory';

export const actionsKeys = createQueryKeys('actions', {
  alerts: (accountResourceName: string, siteResourceName: string, userResourceName: string) => [
    'alerts',
    accountResourceName,
    siteResourceName,
    userResourceName,
  ],
  metrics: (accountResourceName: string, siteResourceName: string) => [
    'metrics',
    accountResourceName,
    siteResourceName,
  ],
});
