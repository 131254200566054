import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { useGlobalStore } from '@/shared/store/global';
import { QUERY_KEY } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useUIStore } from '@/shared/store/ui';

export const useSetAssetsCategories = () => {
  const site = useUIStore(state => state.site);
  const updateAssetsCategories = useGlobalStore(state => state.updateAssetsCategories);

  const { user } = useFetchUserData();

  const { data: assetsCategories } = useQuery({
    queryKey: [QUERY_KEY.ASSETS_CATEGORIES, user?.accountResourceName, site],

    queryFn: async () => {
      const result = await AssetsAPINamespace.getAllCategoryTress(
        user?.accountResourceName as string,
        site,
      );

      const data = result?.map(item => {
        const modifiedItemPaths = item.categoryPathList.map(pathItem => {
          return {
            ...pathItem,
            extended: false,
          };
        });

        return {
          ...item,
          categoryPathList: modifiedItemPaths,
          extended: true,
        };
      });

      updateAssetsCategories(data);

      return data;
    },

    enabled: !!user?.accountResourceName && !!site,
  });

  return { assetsCategories };
};
