import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { RolesEnum } from '@/shared/types/enums.ts';
import { MixedRules } from '@/shared/types/rules.types.ts';
import { UserProps } from '@/shared/types/user.types.ts';
import { rules } from '@/shared/utils/rules.ts';
import { LS_KEYS } from '@/shared/utils/constants.ts';

export const isActionAllowed = (action: MixedRules) => {
  const userStorageData =
    WebAPIStorageNamespace.SessionService.getSessionItem(LS_KEYS.USER_DATA) ??
    WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USER_DATA);

  const user: UserProps = JSON.parse(userStorageData as string);

  const rule = rules.find(rule => rule.name === action);

  return !!rule?.roles.includes(user?.role ?? RolesEnum.WORKER);
};
