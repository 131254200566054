export const LENGTHS = {
  MIN: {
    TEXT: 1,
    PASSWORD: 8,
    OTP: 6,
    PHONE: 10,
  },
  MAX: {
    TEXT: 20,
    SECONDARY_TEXT: 30,
    PASSWORD: 50,
    OTP: 6,
    COLOR: 7,
    PHONE: 20,
    PRICE: 9,
  },
};

export const REGEX = {
  ACCOUNT_NAME: /^[A-Z][a-z]+(-[A-Z][a-z]+)?$/,
  PASSWORD: {
    DIGIT: /\d/g,
    SPECIAL_CHAR: /[!#$%&*,@^]/g,
    UPPER_CASE: /[A-Z]/g,
    WITHOUT_SPACE: /^\S+$/,
  },
  USERNAME: /^[,A-Z_a-z-]+$/,
  OTP: /^\d+$/,
  POINTS: /^-?\d+(\.\d+)?$/,
  HEX: /^#/,
  TRIM: /^\s+$/g,
  EMAIL: /^[^\s@]{1,64}@[^\s@]{1,255}\.[^\s@]{2,63}$/u,
  PHONE: /^\+\d+$/,
};

export const MESSAGES = {
  PASSWORD: {
    DIGIT: 'messages.passwordDigit',
    NOT_MATCH: 'messages.passwordNotMatch',
    SPECIAL_CHAR: 'messages.passwordSpecialChar',
    UPPER_CASE: 'messages.passwordUpperCase',
    WITHOUT_SPACE: 'messages.passwordWithoutSpace',
    OLD_NEW_MATCH: 'messages.passwordOldNewMatch',
    MIN_LENGTH: 'messages.passwordMinLength',
    MAX_LENGTH: 'messages.passwordMaxLength',
  },
  USERNAME: 'messages.username',
  OTP: {
    DEFAULT: 'messages.otpDefault',
    MIN_LENGTH: 'messages.otpMinLength',
    MAX_LENGTH: 'messages.otpMaxLength',
  },
  POINTS: {
    DEFAULT: 'messages.pointsDefault',
    VALIDATION: 'messages.pointsValidation',
  },
  EMPTY: 'messages.empty',
  EMAIL: 'messages.email',
  INVALID_EMAIL: 'messages.invalidEmail',
  EMAIL_PHONE_REQUIRED: 'messages.emailPhoneRequiredError',
  TEXT: {
    MIN_LENGTH: 'messages.textMinLength',
    MAX_LENGTH: 'messages.textMaxLength',
    SECONDARY_MAX_LENGTH: `Field must contain at most ${LENGTHS.MAX.SECONDARY_TEXT} character(s)`,
  },
  GEOFENCE: {
    END_DATE: 'messages.geoFenceEndDate',
  },
  PHONE_NUMBER: 'messages.phone',
};
