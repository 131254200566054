import ArrowSm from '@/assets/icons/arrow-sm.svg?react';
import Equipment from '@/assets/icons/equipment-sm.svg?react';
import EquipmentPin from '@/assets/icons/equipment-pin.svg?react';
import HistoricalEquipmentPin from '@/assets/icons/historical-equipment-pin.svg?react';
import TrackerEquipmentPin from '@/assets/icons/tracker-equipment-pin.svg?react';
import Readers from '@/assets/icons/readers.svg?react';
import SelectedEquipmentPin from '@/assets/icons/selected-equipment-pin.svg?react';
import SelectedWorkerPin from '@/assets/icons/selected-worker-pin.svg?react';
import TrackerWorkerPin from '@/assets/icons/tracker-worker-pin.svg?react';
import Worker from '@/assets/icons/worker.svg?react';
import WorkerPin from '@/assets/icons/worker-pin.svg?react';
import HistoricalWorkerPin from '@/assets/icons/historical-worker-pin.svg?react';
import Zone from '@/assets/icons/zone.svg?react';
import ReaderPin from '@/assets/icons/reader-pin.svg?react';
import GeofenceFirstPoint from '@/assets/icons/geofence-first-point.svg?react';
import GeofenceSecondPoint from '@/assets/icons/geofence-second-point.svg?react';
import Calender from '@/assets/icons/calendar.svg?react';
import List from '@/assets/icons/list.svg?react';
import listSmall from '@/assets/icons/listSmall.svg?react';
import { WithClassName } from '@/shared/types/common.types';

export const customIcons = {
  arrowSm: ArrowSm,
  equipment: Equipment,
  equipmentPin: EquipmentPin,
  historicalEquipmentPin: HistoricalEquipmentPin,
  trackerEquipmentPin: TrackerEquipmentPin,
  readers: Readers,
  selectedEquipmentPin: SelectedEquipmentPin,
  selectedWorkerPin: SelectedWorkerPin,
  trackerWorkerPin: TrackerWorkerPin,
  worker: Worker,
  workerPin: WorkerPin,
  historicalWorkerPin: HistoricalWorkerPin,
  zone: Zone,
  readerPin: ReaderPin,
  geofenceFirstPoint: GeofenceFirstPoint,
  geofenceSecondPoint: GeofenceSecondPoint,
  calender: Calender,
  list: List,
  listSmall: listSmall,
};

export type CustomIconType = keyof typeof customIcons;

export type CustomIconProps = WithClassName & {
  type: CustomIconType;
  color?: string;
  isActive?: boolean;
};
