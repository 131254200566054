import { Vector3 } from 'three';
import { GeofenceCategoryEnum } from '@/shared/types/geofences.types.ts';

export enum ZoneShapeType {
  RECTANGLE = 'Rectangle',
  CUSTOM = 'Custom',
}

export interface ZoneDrawData {
  points: Vector3[];
  done: boolean;
}

export interface ZoneDrawClickTarget {
  type: 'none' | 'control-point' | 'shape';
  ctrlIdx?: number;
  dragOffsets?: Vector3[];
}

export interface NewZoneFormArgs {
  name: string;
  type: GeofenceCategoryEnum;
  points: Vector3[];
  floor: string;
  minZ: number;
  maxZ: number;
}

export type Vector3Array = [number, number, number];
