import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { useUIStore } from '@/shared/store/ui';

import { SidebarParameters } from '@/shared/types/parameters.types.ts';

export const useGeofenceParams = () => {
  const queryClient = useQueryClient();

  const [searchParameters, setSearchParameters] = useSearchParams();

  const updateSearchParameters = () => {
    setSearchParameters(searchParameters => {
      searchParameters.set(URL_PARAMS.SIDEBAR, SidebarParameters.EDIT);
      return searchParameters;
    });
  };

  const uiStore = useUIStore(uiStore => uiStore);

  return {
    ...uiStore,
    searchParameters,
    queryClient,
    updateSearchParameters,
    setSearchParameters,
  };
};
