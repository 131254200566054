import { useGlobalStore } from '@/shared/store/global';
import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

export const useSetTags = () => {
  const site = useUIStore(store => store.site);
  const updateAssetsTags = useGlobalStore(store => store.updateAssetsTags);

  const { user } = useFetchUserData();

  const {
    isLoading,
    isError,
    data: tags,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEY.ASSETS_TAGS, user?.accountResourceName, site],

    queryFn: async () => {
      const data = await AssetsAPINamespace.getAllTags(user?.accountResourceName as string, site);
      updateAssetsTags(data.results);

      return data.results;
    },

    enabled: !!user?.accountResourceName && !!site,
  });

  return { isLoading, isError, tags, refetch };
};
