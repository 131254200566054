import { Dispatch, FC, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Button } from '@/shared/components/buttons/Button';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { newRelicService } from '@/shared/services/newrelic.service';
import { translate } from '@/i18n';
import { useClearSiteData } from '@/modules/Home/hooks/common';
import { useClearStateData } from '@/shared/hooks/global/useClearStateData.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

import { GeofenceCategoryEnum } from '@/shared/types/geofences.types';
import { DropdownType } from '@/shared/components/ui/UserMenu/UserMenu.types.ts';
import { RoutesEnum } from '@/shared/types/enums.ts';
import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import SelectCheck from '@/assets/icons/select-check.svg?react';

type Props = {
  setMenuType?: Dispatch<SetStateAction<DropdownType>>;
};

export const SiteSelect: FC<Props> = ({ setMenuType }) => {
  const navigate = useNavigate();
  const activeSite = useUIStore(state => state.site);
  const sitesList = useUIStore(state => state.sitesList);

  const setSite = useUIStore(state => state.setSite);
  const setCurrentGeofence = useGlobalStore(state => state.setCurrentGeofence);

  const { clearSiteData } = useClearSiteData();
  const { clearAllStateData } = useClearStateData();

  const isMobile = useMedia('(max-width: 767px)');

  const handleSelectSite = (siteResourceName: string) => {
    const site = sitesList.find(site => site.siteResName === siteResourceName);

    if (site) {
      clearSiteData();
      clearAllStateData();

      setSite(siteResourceName);
      setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);

      mixpanelService.logChangeSite(site.siteName, siteResourceName);
      newRelicService.SetSiteId(siteResourceName);
      navigate(RoutesEnum.HOME);
    }
  };

  return (
    <div className="flex flex-col px-2">
      {!isMobile && (
        <Typography className="px-3 py-2 text-[12px] font-medium uppercase text-slate-500">
          {translate('navbar.selectSite')}
        </Typography>
      )}

      {isMobile && (
        <Button
          variant="custom"
          onClick={() => setMenuType && setMenuType(DropdownType.MOBILE)}
          aria-label="cancel-language-select"
          className={classnames(
            'inline-flex w-full items-center gap-3 rounded-md  text-sm font-medium transition-none duration-300',
            isMobile ? 'mb-4 text-white' : 'px-3 py-2',
          )}
        >
          <ChevronRight className="rotate-180" />
          <Typography>{translate('global.back')}</Typography>
        </Button>
      )}

      <div className="flex h-full flex-col overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-slate-500 md:max-h-[342px]">
        {sitesList.map(({ siteResName, siteName }) => {
          const isChosen = siteResName === activeSite;

          return (
            <SiteSelectItem
              key={siteResName}
              onClick={() => handleSelectSite(siteResName)}
              aria-label={isChosen ? `${siteResName}-option-active` : `${siteResName}-option`}
              className={classnames(
                isChosen && !isMobile && 'pointer-events-none text-blue-600',
                isMobile && 'py-2 text-base text-white',
              )}
            >
              <Typography className="inline-block overflow-x-hidden text-ellipsis whitespace-nowrap md:max-w-48">
                {siteName}
              </Typography>

              {isChosen && <SelectCheck />}
            </SiteSelectItem>
          );
        })}
      </div>
    </div>
  );
};
