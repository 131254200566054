import { DetailedHTMLProps, FC, HtmlHTMLAttributes } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithChildren, WithClassName } from '@/shared/types/common.types';

type ICardProps = DetailedHTMLProps<HtmlHTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  WithChildren &
  WithClassName & { disabled?: boolean };

export const Card: FC<ICardProps> = ({ children, disabled, className, ...props }) => {
  return (
    <div
      className={classnames(
        'rounded-lg border border-slate-200 bg-white p-2 shadow-sm',
        { 'pointer-events-none opacity-50': disabled },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
