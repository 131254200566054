import { NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types';
import { RoutesEnum } from '@/shared/types/enums';

export const links: NavLinkProps[] = [
  {
    name: 'navbar.map',
    path: RoutesEnum.HOME,
    icon: 'location',
    disabled: false,
  },
  {
    name: 'global.reservations',
    path: RoutesEnum.RESERVATIONS,
    icon: 'requests',
    disabled: false,
  },
  {
    name: 'global.equipment',
    path: RoutesEnum.EQUIPMENT,
    icon: 'equipment',
    disabled: false,
  },
  {
    name: 'navbar.subContractors',
    path: RoutesEnum.PEOPLE,
    icon: 'subcontractors',
    disabled: false,
  },
  {
    name: 'navbar.reports',
    path: RoutesEnum.REPORTS,
    icon: 'reports',
    disabled: false,
  },
  {
    name: 'navbar.actions',
    path: RoutesEnum.ACTIONS,
    icon: 'actions',
    disabled: false,
  },
];
