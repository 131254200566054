import { baseQuery, signRequest } from '@/shared/api/utils';

import {
  AllSettingsRequest,
  AllSettingsResponse,
  CreateSettingsRequest,
  EntityTypeEnum,
  SettingsResultProps,
  UpdateSettingsRequest,
} from '@/shared/api/settings/settings.types.ts';

export namespace SettingsAPINamespace {
  const client = baseQuery('USER');

  export const getAll = async ({
    accountResName,
    userResName,
    entityType = EntityTypeEnum.User,
  }: AllSettingsRequest): Promise<AllSettingsResponse> => {
    const url = `/accounts/${accountResName}/settings?entityType=${entityType}&entityResName=${userResName}&limit=100&forward=true`;

    const signedRequest = await signRequest('USER', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const createOne = async ({
    accountResName,
    userResName,
    entityType = EntityTypeEnum.User,
    settingsName,
    extraProperties,
  }: CreateSettingsRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/settings`;

    const body = {
      entityType,
      entityResName: userResName,
      settingsName,
      extraProperties,
    };

    const signedRequest = await signRequest('USER', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOne = async ({
    accountResName,
    userResName,
    entityType = EntityTypeEnum.User,
    settingsResName,
    settingsName,
    extraProperties,
  }: UpdateSettingsRequest): Promise<SettingsResultProps> => {
    const url = `/accounts/${accountResName}/settings`;

    const body = {
      entityType,
      entityResName: userResName,
      settingsResName,
      settingsName,
      extraProperties,
    };

    const signedRequest = await signRequest('USER', url, 'PUT', body);

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
