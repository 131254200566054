import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { LS_KEYS } from '@/shared/utils/constants';

import {
  CurrencyEnum,
  DisplayTimeZoneEnum,
  SystemViewEnum,
  TimeFormatEnum,
} from '@/shared/types/system.types';
import { LocaleEnum } from '@/shared/types/i18n.types';
import { UiStoreProps } from '@/shared/store/ui/ui.types';
import { SiteResultProps } from '@/shared/api/sites/sites.types.ts';

export const useUIStore = create<UiStoreProps>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (set, get) => ({
        sitesList: [],
        setSitesList: (sitesList: SiteResultProps[]) => {
          set({ sitesList });
        },

        site: '',
        setSite: (site: string) => {
          set({ site });
        },

        siteTimeZone: '',
        setSiteTimeZone: value => {
          set({ siteTimeZone: value });
        },

        displayTimeZone: DisplayTimeZoneEnum.SITE,
        setDisplayTimeZone: value => {
          set({ displayTimeZone: value });
        },

        timeFormat: TimeFormatEnum.H24,
        setTimeFormat: value => {
          set({ timeFormat: value });
        },

        currentLanguage: LocaleEnum.EN,
        setCurrentLanguage: (location: LocaleEnum) => {
          set({ currentLanguage: location });
        },

        currency: CurrencyEnum.USD,
        setCurrency: (currency: CurrencyEnum) => {
          set({ currency });
        },

        systemView: SystemViewEnum.METRIC,
        setSystemView: (view: SystemViewEnum) => {
          set({ systemView: view });
        },

        mapFilter: {
          searchKey: '',
          firm: [],
          group: [],
          tag: [],
          workerRole: [],
          equipmentType: [],
          equipmentSubType: [],
          equipmentReservedBy: [],
          zoneType: [],
          zoneFloor: [],
          zoneItem: [],
          assetItem: [],
          resName: [],
        },
        setMapFilter: filter => {
          const mapFilter = get().mapFilter;
          set({ mapFilter: { ...mapFilter, ...filter } });
        },

        floor: null,
        setFloor: floor => {
          set({ floor });
        },

        floorsList: [],
        setFloorsList: floorsList => {
          set({ floorsList });
        },

        distance: 5,
        setDistance: distance => {
          set({ distance });
        },

        isCellCopyTipActive: true,
        setIsCellCopyTipActive: value => {
          set({ isCellCopyTipActive: value });
        },

        siteCalendars: [],
        setSiteCalendars: siteCalendars => {
          set({ siteCalendars });
        },

        sitePolicies: [],
        setSitePolicies: sitePolicies => {
          set({ sitePolicies });
        },

        siteShifts: [],
        setSiteShifts: siteShifts => {
          set({ siteShifts });
        },

        userSettings: [],
        setUserSettings: settings => {
          set({ userSettings: settings });
        },

        equipmentVisibility: true,
        setEquipmentVisibility: value => {
          set({ equipmentVisibility: value });
        },

        workerVisibility: true,
        setWorkerVisibility: value => {
          set({ workerVisibility: value });
        },

        readerVisibility: false,
        setReaderVisibility: value => {
          set({ readerVisibility: value });
        },

        openedViewPanel: [],
        setOpenedViewPanel: (openedViewPanel: string[]) => {
          set({ openedViewPanel });
        },

        tagsId: [],
        setTagId: (tagId: string) => {
          set(state =>
            state.tagsId.includes(tagId)
              ? { tagsId: state.tagsId.filter(id => id !== tagId) }
              : { tagsId: [...state.tagsId, tagId] },
          );
        },
        setTagIds: (tagsId: string[]) => {
          set({ tagsId });
        },
        clearTagsId: () => {
          set({ tagsId: [] });
        },

        startTime: '',
        setStartTime: value => {
          set({ startTime: value });
        },

        endTime: '',
        setEndTime: value => {
          set({ endTime: value });
        },

        isCanvasPopupsOpen: false,
        setIsCanvasPopupsOpen: (value: boolean) => {
          set({ isCanvasPopupsOpen: value });
        },
        isShowInactive: false,
        setIsShowInactive: (isShowInactive: boolean) => {
          set({ isShowInactive });
        },
      }),
      {
        name: LS_KEYS.UI_STORE,
      },
    ),
    {
      name: 'UI Store',
    },
  ),
);
