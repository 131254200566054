import { FC } from 'react';

import { Card } from '@/shared/components/ui/Card';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import { AnchorCardProps } from '@/modules/Home/types/AnchorCard.types.ts';
import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import Organization from '@/assets/icons/organization.svg?react';

const NO_DATA = 'N/A';

export const AnchorCard: FC<AnchorCardProps> = ({
  body: { assetName, tagName, device_name, categoryPath, categoryTreeName, company },
  togglePopup,
  className,
}) => {
  const isEquipment = categoryTreeName === AssetTypeEnum.Equipment;

  return (
    <Card className={classnames(className)}>
      <IconButton icon="close" className="absolute right-2 top-2" onClick={togglePopup} />

      <div className="grid gap-y-1 whitespace-nowrap text-sm">
        <Typography className="font-bold text-slate-950">
          {assetName ?? tagName ?? device_name ?? NO_DATA}
        </Typography>

        {isEquipment && categoryPath && (
          <Typography className="font-semibold text-slate-800 first-letter:capitalize">
            {categoryPath}
          </Typography>
        )}

        {company && (
          <Typography className="inline-flex items-center gap-x-2 text-slate-500">
            <Organization className="h-5 w-5 shrink-0" />
            {company}
          </Typography>
        )}
      </div>
    </Card>
  );
};
