import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { QUERY_KEY } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useGlobalStore } from '@/shared/store/global';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams';
import { useUIStore } from '@/shared/store/ui';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { UserProps } from '@/shared/types/user.types';
import { useAssetStatus } from './useAssetStatus';

export const useSetEquipment = () => {
  const site = useUIStore(state => state.site);
  const setEquipment = useGlobalStore(state => state.setEquipment);
  const setEquipmentInUse = useGlobalStore(state => state.setEquipmentInUse);
  const assetsCategories = useGlobalStore(state => state.assetsCategories);
  const equipmentVisibility = useUIStore(state => state.equipmentVisibility);

  const { user } = useFetchUserData();
  const { isHistoricalMode } = useLocalSearchParams();

  const currentEquipmentResourceName = assetsCategories?.find(category =>
    category.categoryTreeName.includes(AssetTypeEnum.Equipment),
  );

  const { isShowInactive } = useAssetStatus();

  const { data: equipments } = useQuery({
    queryKey: [
      QUERY_KEY.EQUIPMENTS_GET,
      user?.accountResourceName,
      site,
      isHistoricalMode,
      equipmentVisibility,
      currentEquipmentResourceName,
      currentEquipmentResourceName?.categoryTreeResName,
      user,
      isShowInactive,
    ],

    queryFn: async () => {
      if (!currentEquipmentResourceName) return [];

      const { results } = await AssetsAPINamespace.getAll(
        user?.accountResourceName as string,
        site,
        currentEquipmentResourceName?.categoryTreeResName,
      );

      const assetsWithDetails = await AssetsAPINamespace.getWithDetails(
        results,
        user as UserProps,
        site,
        equipmentVisibility,
      );

      const assetsInUse = assetsWithDetails?.filter(asset => {
        const isTagExists = asset.extraProperties?.find(property => property.key === 'tag');
        return !!isTagExists;
      });

      const assetsToShow = assetsWithDetails.filter(asset => {
        const show =
          asset.assetStatus === 'active' || (asset.assetStatus === 'inactive' && isShowInactive);
        return !!show;
      });

      setEquipmentInUse(assetsInUse);
      setEquipment(assetsToShow);

      return results;
    },

    enabled: !!user?.accountResourceName && !!site && !!assetsCategories?.length,
  });

  return { equipments };
};
