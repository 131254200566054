import { links } from '@/shared/components/navigation/Navbar/Navbar.constants.tsx';
import { useAlertsFeatureFlag } from '@/shared/feature-flags/useAlertsFeatureFlag.ts';
import { useReservationsControlFlag } from '@/shared/feature-flags/useReservationsControlFlag.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';

import { NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types.tsx';
import { RolesEnum, RoutesEnum } from '@/shared/types/enums.ts';

export const useNavbarLinks = () => {
  const { reservationControlFlag } = useReservationsControlFlag();
  const { alertsImplementation } = useAlertsFeatureFlag();
  const { renderItemVisibility } = useRole();
  const hideVisibility = renderItemVisibility([RolesEnum.KOTO]);

  const shouldRenderLink = (link: NavLinkProps) => {
    if (link.path === RoutesEnum.RESERVATIONS && !reservationControlFlag.enabled) {
      return false;
    }

    return !(link.path === RoutesEnum.ACTIONS && !alertsImplementation.enabled);
  };

  const filteredLinks = links
    .filter(link => !(hideVisibility && link.path === RoutesEnum.RESERVATIONS))
    .map(link =>
      hideVisibility && link.path === RoutesEnum.EQUIPMENT ? { ...link, disabled: true } : link,
    )
    .filter(shouldRenderLink);

  return { filteredLinks };
};
