import * as PolygonUtils from '@/shared/utils/process-polygon-points';
import { GeofencesAPINamespace } from '@/shared/api/geofences';
import { SystemViewNamespace } from '@/shared/services/system-view.service';
import { checkOnSimilar } from '@/shared/utils/check-on-similar';

import * as Types from '@/shared/types/geofences.types';
import { SystemViewEnum } from '@/shared/types/system.types';

class GeofencesService {
  private geofenceOptions: {
    accountResName: string;
    siteResName: string;
    floorResName: string;
  };

  constructor() {
    this.geofenceOptions = {
      accountResName: '',
      floorResName: '',
      siteResName: '',
    };
  }

  public async getGeofences(
    params: Types.GetGeofencesServiceProps,
  ): Promise<Types.GeofencesProps[]> {
    const { accountResName, siteResName, floorResName } = this.geofenceOptions;
    const { setGeofences, setCurrentGeofenceId } = params;

    const data = await GeofencesAPINamespace.getAll({
      accountResName,
      siteResName,
      floorResName,
    });

    const result = checkOnSimilar(data?.results ?? [], 'geofenceResName');

    const transformedResult = SystemViewNamespace.GeofencesNamespace.transformSystemView(
      SystemViewEnum.METRIC,
      result,
    ) as Types.GeofencesProps[];

    const filteredResult = transformedResult?.filter((geofence: Types.GeofencesProps) => {
      return geofence.status !== Types.GeofenceStatusEnum.DELETED;
    });

    const mappedPolygonPoints = filteredResult.map(geofence => {
      return {
        ...geofence,
        id: geofence.geofenceResName,
        visible: false,
        polygon: {
          ...geofence.polygon,
          points: PolygonUtils.mapPolygonPoints(geofence?.polygon?.points as Types.Point[]),
        },
      };
    });

    setGeofences((mappedPolygonPoints as Types.GeofencesProps[]) ?? [], false);
    setCurrentGeofenceId('');

    return data?.results ?? [];
  }

  public async createGeofence(params: Types.CreateGeofencesServiceProps) {
    const { points, systemView, geofenceCategory, geofenceName, maxHeight } = params;

    const modifiedPoints = PolygonUtils.processPolygonPoints(points, systemView);

    const requestData = {
      geofenceCategory,
      geofenceName,
      polygon: {
        points: modifiedPoints,
        maxHeight: Math.round(maxHeight * 100 + Number.EPSILON),
      },
    };

    return await GeofencesAPINamespace.createOne({
      accountResName: this.geofenceOptions.accountResName,
      siteResName: this.geofenceOptions.siteResName,
      floorResName: this.geofenceOptions.floorResName,
      ...requestData,
    });
  }

  public async updateGeofence(params: Types.UpdateGeofencesServiceProps) {
    const {
      geofenceResName,
      points,
      geofenceCategory,
      geofenceName,
      maxHeight,
      systemView,
      status,
    } = params;

    if (geofenceResName) {
      const modifiedPoints = PolygonUtils.processPolygonPoints(points, systemView);

      const updatedData = {
        geofenceResName,
        geofenceCategory,
        geofenceName,
        status,
        polygon: {
          points: modifiedPoints,
          maxHeight: Math.round(maxHeight * 100 + Number.EPSILON),
        },
      };

      return await GeofencesAPINamespace.updateOne({
        accountResName: this.geofenceOptions.accountResName,
        siteResName: this.geofenceOptions.siteResName,
        floorResName: this.geofenceOptions.floorResName,
        ...updatedData,
      });
    }
  }

  public async updateGeofenceStatus(params: Types.GeofencesProps) {
    const { geofenceResName, geofenceCategory, geofenceName, status } = params;

    if (geofenceResName) {
      const updatedData = {
        geofenceResName,
        geofenceCategory,
        geofenceName,
        status:
          status === Types.GeofenceStatusEnum.ACTIVE
            ? Types.GeofenceStatusEnum.INACTIVE
            : Types.GeofenceStatusEnum.ACTIVE,
      };

      return await GeofencesAPINamespace.updateOne({
        accountResName: this.geofenceOptions.accountResName,
        siteResName: this.geofenceOptions.siteResName,
        floorResName: this.geofenceOptions.floorResName,
        ...updatedData,
      });
    }
  }

  public async deleteGeofence(params: Types.GeofencesProps) {
    const { geofenceResName, geofenceCategory, geofenceName } = params;

    if (geofenceResName) {
      const updatedData = {
        geofenceResName,
        geofenceCategory,
        geofenceName,
        status: Types.GeofenceStatusEnum.DELETED,
      };

      return await GeofencesAPINamespace.updateOne({
        accountResName: this.geofenceOptions.accountResName,
        siteResName: this.geofenceOptions.siteResName,
        floorResName: this.geofenceOptions.floorResName,
        ...updatedData,
      });
    }
  }

  get options() {
    return this.geofenceOptions;
  }

  set options(options) {
    this.geofenceOptions = options;
  }
}

export const geofencesService = new GeofencesService();
