import { queryOptions, useQuery } from '@tanstack/react-query';

import { FloorsAPINamespace } from '@/shared/api/floors/floors.requests.ts';
import { QUERY_SCHEMA } from '@/shared/schemas/query-schemas.ts';
import { checkOnSimilar } from '@/shared/utils/check-on-similar.ts';
import { floorsKeys } from '@/shared/api/floors/floors.keys.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

export namespace useFloorsHooks {
  export const getAll = () => {
    const { user } = useFetchUserData();

    const site = useUIStore(store => store.site);
    const floor = useUIStore(store => store.floor);
    const setFloor = useUIStore(store => store.setFloor);
    const setFloorsList = useUIStore(store => store.setFloorsList);

    const floorsQueryOptions = queryOptions({
      ...floorsKeys.all(user?.accountResourceName as string, site, floor),

      queryFn: async () => {
        const data = await FloorsAPINamespace.getAll({
          accountResourceName: user?.accountResourceName as string,
          siteResourceName: site,
        });

        const mappedResult = data.map(({ floorName, floorResName, ...rest }) => ({
          ...rest,
          id: floorResName,
          name: floorName,
        }));

        const result = checkOnSimilar(mappedResult, 'id').sort((a, b) =>
          a.floorIndex > b.floorIndex ? 1 : -1,
        );

        setFloorsList(result);
        if (!floor) setFloor(result[0] ?? null);

        return data;
      },

      enabled: !!user?.accountResourceName && !!site,

      staleTime: QUERY_SCHEMA.STALE_TIME.DAY,
    });

    return useQuery(floorsQueryOptions);
  };
}
