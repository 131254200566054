import { translate } from '@/i18n';

import { GeofenceCategoryEnum, GeofencesProps } from '@/shared/types/geofences.types';
import { MODE } from '@/shared/utils/constants.ts';
import { SelectDataEnum } from '@/modules/Home/components/common/MapPageHeader/MapPageHeader.types';
import { SelectDataProps } from '@/shared/components/form/Select/Select.types';

export const INITIAL_START_DATE = new Date();
export const INITIAL_END_DATE = new Date(INITIAL_START_DATE.getTime() + 5 * 60000); // time now + 5 minutes

export const getCurrentGeofences = (data: GeofencesProps[], categoryType: GeofenceCategoryEnum) => {
  if (categoryType === GeofenceCategoryEnum.INACTIVE) {
    return data.filter(({ status }) => status === 'inactive');
  }

  if (categoryType === GeofenceCategoryEnum.RED_ZONE) {
    return data.filter(
      ({ status, geofenceCategory }) =>
        status === 'active' && geofenceCategory === GeofenceCategoryEnum.RED_ZONE,
    );
  }

  if (categoryType === GeofenceCategoryEnum.WORK_ZONE) {
    return data.filter(
      ({ status, geofenceCategory }) =>
        status === 'active' && geofenceCategory === GeofenceCategoryEnum.WORK_ZONE,
    );
  }

  return data;
};

export const modes = [
  {
    id: 'realtime',
    name: MODE.REALTIME,
  },
  {
    id: 'historical',
    name: MODE.HISTORICAL,
  },
];

export const geofenceCategory = [
  {
    id: GeofenceCategoryEnum.WORK_ZONE,
    name: translate('sidebar.workZone'),
    color: 'bg-green-500',
  },
  {
    id: GeofenceCategoryEnum.RED_ZONE,
    name: translate('sidebar.redZones'),
    color: 'bg-red-500',
  },
];

export const mapViewVariants = [
  { id: 'full', name: translate('map.full') },
  { id: 'top', name: translate('map.top') },
  { id: 'side', name: translate('map.side') },
];

export const selectData: SelectDataProps[] = [
  {
    id: SelectDataEnum.LAST_HOUR,
    name: translate('calendar.last1Hour'),
  },
  {
    id: SelectDataEnum.LAST_2_HOURS,
    name: translate('calendar.last2Hours'),
  },
  {
    id: SelectDataEnum.LAST_4_HOURS,
    name: translate('calendar.last4Hours'),
  },
  {
    id: SelectDataEnum.TODAY,
    name: translate('calendar.today'),
  },
  {
    id: SelectDataEnum.YESTERDAY,
    name: translate('calendar.yesterday'),
  },
];
