import { create } from 'zustand';

import { PopupTypeEnum } from '@/shared/types/enums';
import { ModalProps } from '@/shared/store/modal/modal.types';

export const useModalStore = create<ModalProps>((set, _) => ({
  isOpen: false,
  isDeleting: false,
  isSubmitting: false,
  type: PopupTypeEnum.DEFAULT,
  payload: null,

  setIsOpen: (type: PopupTypeEnum, payload?: unknown) => set({ isOpen: true, type, payload }),

  setIsClose: () => set({ isOpen: false, type: PopupTypeEnum.DEFAULT, payload: null }),

  setIsDeleting: (status: boolean) => set({ isDeleting: status }),

  setIsSubmitting: (status: boolean) => set({ isSubmitting: status }),
}));
