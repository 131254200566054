import { useUIStore } from '@/shared/store/ui';

import { LocaleEnum } from '@/shared/types/i18n.types.ts';

export const useSetCurrentLanguage = () => {
  const setCurrentLanguage = useUIStore(state => state.setCurrentLanguage);

  const handleSetLanguage = (lang: string) => {
    setCurrentLanguage(lang as LocaleEnum);
  };

  return { handleSetLanguage };
};
