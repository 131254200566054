import { create } from 'zustand';
import { ActionData, PopupStore } from './popup.types';
import { generateId } from '@/shared/utils/helpers';
import { ReactNode } from 'react';

const initialData = {
  isOpen: false,
  currentTarget: null,
  children: null,
  backdrop: false,
  position: 'right',
  closeOnSelect: false,
  customData: null,
  disableClickOutside: false,
  clientAxis: { clientX: 0, clientY: 0 },
  popover: { width: 0, height: 0 },
};

const renderPopupData = (payload: ReactNode, meta: Partial<ActionData>) => {
  const currentTarget =
    meta?.event?.currentTarget instanceof HTMLElement
      ? meta?.event?.currentTarget.getBoundingClientRect()
      : null;

  return {
    ...initialData,
    id: meta.id ?? generateId(),
    isOpen: true,
    children: payload,
    popover: meta.popover ?? initialData.popover,
    position: meta?.position ?? initialData.position,
    backdrop: meta?.backdrop ?? initialData.backdrop,
    closeOnSelect: meta?.closeOnSelect ?? initialData.closeOnSelect,
    customData: meta?.customData ?? initialData.customData,
    disableClickOutside: meta?.disableClickOutside ?? initialData.disableClickOutside,
    ...(currentTarget && {
      currentTarget: meta?.event?.currentTarget ?? initialData.currentTarget,
    }),
    ...(!currentTarget && {
      clientAxis: {
        clientX: meta?.event?.clientX,
        clientY: meta?.event?.clientY,
      },
    }),
  };
};

export const usePopupStore = create<PopupStore>((set, get) => ({
  // POPUPS LIST
  popups: [],

  // SET POPUP
  setPopup: (payload, meta) => {
    const incomingPopup = renderPopupData(payload, meta);

    set(state => ({
      popups: [...state.popups.filter(popup => popup.id !== meta.id), incomingPopup],
    }));
  },

  // CLOSE CURRENT POPUP BY ID
  closePopup: id =>
    set(state => ({
      popups: state.popups.filter(popup => popup.id !== id),
    })),

  // CLOSE CURRENT POPUP BY ID
  closeActivePopups: () =>
    set({
      popups: [],
    }),

  // SET POPUP SIZE BY ID
  setPopoverSize: (id, width, height) => {
    const modifiedPopup = get().popups.map(popup => {
      if (popup.id === id) {
        return {
          ...popup,
          popover: {
            width,
            height,
          },
        };
      }
      return popup;
    });

    set({
      popups: modifiedPopup,
    });
  },
}));
