import { useModalStore } from '@/shared/store/modal';

import { PopupTypeEnum } from '@/shared/types/enums.ts';

export const useModalHandlerTypes = () => {
  const isOpen = useModalStore(state => state.isOpen);
  const setIsOpen = useModalStore(state => state.setIsOpen);
  const setIsClose = useModalStore(state => state.setIsClose);
  const isDeleting = useModalStore(state => state.isDeleting);

  const filterClickHandler = () => {
    setIsOpen(PopupTypeEnum.MAP_FILTER);
  };

  const geofenceDeleteHandler = (args: { geofenceId: string; geofenceName: string }) => {
    setIsOpen(PopupTypeEnum.CONFIRM_DELETE, {
      geofenceId: args.geofenceId,
      geofenceName: args.geofenceName,
    });
  };

  const equipmentDeleteHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.EQUIPMENT_DELETE, {
      data,
    });
  };

  const geofenceSaveHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.CONFIRM_SAVE, {
      reservation: data,
      geofenceId: data,
    });
  };

  const reservationDeleteHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.RESERVATIONS_CONFIRM_DELETE, {
      reservation: data,
    });
  };

  const reservationSubmitHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.RESERVATIONS_CONFIRM_SUBMIT, {
      reservation: data,
    });
  };
  const reservationApproveHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.RESERVATIONS_CONFIRM_APPROVE, {
      reservation: data,
    });
  };
  const reservationRejectHandler = <T>(data: T) => {
    setIsOpen(PopupTypeEnum.RESERVATIONS_CONFIRM_REJECT, {
      reservation: data,
    });
  };

  return {
    isOpen,
    setIsOpen,
    setIsClose,
    equipmentDeleteHandler,
    filterClickHandler,
    geofenceDeleteHandler,
    geofenceSaveHandler,
    reservationDeleteHandler,
    reservationSubmitHandler,
    reservationApproveHandler,
    reservationRejectHandler,
    isDeleting,
  };
};
