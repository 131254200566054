import { ForwardedRef, forwardRef } from 'react';
import { classnames } from '@/shared/utils/classnames.ts';
import { Button } from '@/shared/components/buttons/Button';
import { translate } from '@/i18n';
import { iconsButtonMap } from '@/shared/components/buttons/IconButton/IconButton.types.tsx';
import { ButtonsGroupProps } from '@/shared/components/buttons/ButtonsGroup/ButtonsGroup.types.ts';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAMS } from '@/shared/utils/constants.ts';

export type Item = {
  id: string;
  name: string;
  icon?: keyof typeof iconsButtonMap;
};

export const ButtonsGroup = forwardRef(
  <T extends Item>(
    { className, items, disabled, ...props }: ButtonsGroupProps<T>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [searchParameters, setSearchParameters] = useSearchParams();

    const currentExtraGroup = searchParameters.get(URL_PARAMS.EXTRA_GROUP);

    return (
      <div
        ref={ref}
        className={classnames(
          'inline-flex items-center',
          {
            disabled: 'pointer-events-none opacity-50',
          },
          className,
        )}
        {...props}
      >
        {items.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === items.length - 1;
          const isSelected = item.id === currentExtraGroup || '';

          const Icon = item.icon ? iconsButtonMap[item.icon] : null;

          return (
            <Button
              key={item.id}
              variant="custom"
              className={classnames(
                'inline-flex h-[38px] items-center gap-x-2 border border-transparent bg-slate-800 px-4 py-3 text-sm font-semibold leading-5 tracking-[0.07px] text-white',
                { 'rounded-[8px_0px_0px_8px]': isFirst },
                { 'rounded-[0px_8px_8px_0px]': isLast },
                { 'border-slate-200 bg-white text-slate-800': !isSelected },
              )}
              onClick={() =>
                setSearchParameters(searchParameters => {
                  searchParameters.set(URL_PARAMS.EXTRA_GROUP, item.id);
                  return searchParameters;
                })
              }
            >
              {Icon && <Icon />}

              {translate(item.name)}
            </Button>
          );
        })}
      </div>
    );
  },
);

ButtonsGroup.displayName = 'ButtonsGroup';
