import { useEffect } from 'react';

import { LS_KEYS } from '@/shared/utils/constants';
import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';

export const useUpdateCognitoPool = () => {
  useEffect(() => {
    const keys = Object.keys(localStorage);
    const keyIsExist = keys.some(key => key.includes(LS_KEYS.COGNITO));

    if (!keyIsExist) return;

    keys.forEach(key => {
      if (key.includes(LS_KEYS.COGNITO)) {
        WebAPIStorageNamespace.LocalStorageService.removeLocalItem(key);
      }
    });
  }, []);
};
