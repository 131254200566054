import { FC } from 'react';

import { Button } from '@/shared/components/buttons/Button';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { Tabs } from '@/shared/components/navigation/Tabs';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';

import { GeofencesProps } from '@/shared/types/geofences.types';
import Edit from '@/assets/icons/edit.svg?react';
import { RolesWrapper } from '@/shared/wrappers/Roles';

interface ZoneProps {
  geofence: GeofencesProps;
  onEdit: () => void;
}

export const Zone: FC<ZoneProps> = ({ geofence, onEdit }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-0.5">
        <div className="flex justify-between gap-x-1">
          <Typography as="h3" className="text-left text-sm font-semibold">
            {geofence.geofenceName ?? 'Unknown zone'}
          </Typography>
        </div>

        <div className="flex items-center gap-x-1 truncate text-slate-500">
          <CustomIcon type="zone" className="h-5 w-5" />

          <Typography className="truncate text-ellipsis text-sm">
            {geofence.floorResName ? '1 floor' : '0 floors'}
          </Typography>
        </div>

        <RolesWrapper action="create_edit_red_zones">
          <Button
            className="mt-2 inline-flex h-[38px] gap-2 border-slate-200 text-sm text-slate-800"
            variant="outline"
            onClick={onEdit}
          >
            <Edit className="!h-[14px] !w-[14px]" />
            <Typography as="span">{translate('global.edit')}</Typography>
          </Button>
        </RolesWrapper>
      </div>

      <Tabs.Group>
        <Tabs.List className="grid grid-cols-2">
          <Tabs.CanvasTab className="flex items-center justify-center gap-2 normal-case">
            <CustomIcon type="equipment" className="h-5 w-5" />

            <Typography as="span">{translate('global.equipment')}</Typography>

            <Typography
              as="span"
              className="flex h-[18px] w-[20px] items-center gap-x-1 rounded-full bg-blue-100 px-1.5 py-0.5 text-[10px] text-blue-600"
            >
              0
            </Typography>
          </Tabs.CanvasTab>

          <Tabs.CanvasTab className="flex items-center justify-center gap-2 normal-case">
            <CustomIcon type="worker" className="h-5 w-5" />

            <Typography as="span">{translate('global.workers')}</Typography>

            <Typography
              as="span"
              className="flex h-[18px] w-[20px] items-center gap-x-1 rounded-full bg-blue-100 px-1.5 py-0.5 text-[10px] text-blue-600"
            >
              0
            </Typography>
          </Tabs.CanvasTab>
        </Tabs.List>

        <Tabs.TabPanels className="p-0">
          <Tabs.TabPanel className="grid gap-y-1">
            <></>
          </Tabs.TabPanel>

          <Tabs.TabPanel className="grid gap-y-1">
            <></>
          </Tabs.TabPanel>
        </Tabs.TabPanels>
      </Tabs.Group>
    </div>
  );
};
