import { useUIStore } from '@/shared/store/ui';
import { usePopupStore } from '@/shared/store/popup';
import { ThreeEvent } from '@react-three/fiber';

export const useClickOutsideMap = () => {
  const isCanvasPopupsOpen = useUIStore(state => state.isCanvasPopupsOpen);
  const setIsCanvasPopupsOpen = useUIStore(state => state.setIsCanvasPopupsOpen);
  const closeActivePopups = usePopupStore(store => store.closeActivePopups);

  const handleCanvasClick = (event: MouseEvent | ThreeEvent<PointerEvent>) => {
    const targetElement = event.target as HTMLElement;
    const isHasParent = targetElement.closest?.('button');

    if (!isHasParent) {
      setIsCanvasPopupsOpen(false);
      closeActivePopups();
    }
  };

  return {
    isCanvasPopupsOpen,
    setIsCanvasPopupsOpen,
    handleCanvasClick,
  };
};
