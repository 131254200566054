import { FC } from 'react';

import { useGeofencesHooks } from '@/shared/api/geofences/useGeofencesHooks.ts';
import { useMapsHooks } from '@/shared/api/maps/useMapsHooks.ts';

export const GeofenceHooks: FC = () => {
  useMapsHooks.getAll();
  useGeofencesHooks.getAll();

  return null;
};
