import { FC, useState } from 'react';
import { Button } from '@/shared/components/buttons/Button';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/shared/store/global';

import { MFAFormVerifyProps } from '@/modules/Auth/types/types';
import { StatusEnum } from '@/shared/types/enums';
import { CodeInput } from '@/shared/components/form/CodeInput';

export const MFAVerifyForm: FC<MFAFormVerifyProps> = props => {
  const { handleSubmit, handleCancel } = props;
  const [code, setCode] = useState<string>('');

  const status = useGlobalStore(state => state.status);
  const isLoading = status === StatusEnum.LOADING;

  return (
    <>
      <SiteLogo className="mx-auto" color="blue" />

      <div className="grid gap-y-1 text-center">
        <Typography className="text-xl font-semibold text-slate-950" as="strong">
          {translate('auth.mfaSetupTitle')}
        </Typography>

        <Typography className="text-sm font-medium text-slate-600">
          {translate('auth.mfaVerifySubtitle')}
        </Typography>
      </div>

      <div className="grid gap-y-3.5">
        <div className="mb-40 flex justify-center">
          <CodeInput onChange={setCode} />
        </div>

        <div className="inline-flex items-center gap-x-3">
          <Button
            variant="outline"
            disabled={isLoading}
            withIcon
            aria-label="mfa-setup-cancel"
            onClick={handleCancel}
            className="w-[50%]"
          >
            {translate('global.back')}
          </Button>

          <Button
            variant="solid"
            type="submit"
            disabled={code.length !== 6}
            withIcon
            isLoading={isLoading}
            aria-label="mfa-setup-continue"
            className="w-[50%]"
            onClick={() => handleSubmit(code)}
          >
            {translate('global.submit')}
          </Button>
        </div>
      </div>
    </>
  );
};
