import { useGlobalStore } from '@/shared/store/global';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { AssetsAPINamespace } from '@/shared/api/assets';

export function useSetZpsUsers() {
  const setZPSUsers = useGlobalStore(store => store.setZPSUsers);

  const { user } = useFetchUserData();

  return useQuery({
    queryKey: [QUERY_KEY.ZPS_USERS, user?.accountResourceName],

    queryFn: async () => {
      const users = await AssetsAPINamespace.getZPSUsers(user?.accountResourceName as string);
      setZPSUsers(users ?? []);
      return users;
    },

    enabled: !!user?.accountResourceName,
  });
}
