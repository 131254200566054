import { FC } from 'react';

import { Button } from '@/shared/components/buttons/Button';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useReleaseNotes } from '@/shared/hooks/prismic/useReleaseNotes.ts';

import { PopupTypeEnum } from '@/shared/types/enums.ts';

type Props = {
  variant: 'default' | 'guest';
};

export const Footer: FC<Props> = ({ variant }) => {
  const { document, lastVersion } = useReleaseNotes();
  const { setIsOpen } = useModalHandlerTypes();

  return (
    <footer
      className="flex max-h-[34px] shrink-0 items-center justify-between border-t border-slate-200 bg-white px-5 py-2 text-xs font-medium text-slate-600"
      role="contentinfo"
    >
      <Typography>
        ZaiNar © {new Date().getFullYear()}. {translate('global.allRightsReserved')}
      </Typography>

      {variant === 'default' && document && (
        <Typography>
          {translate('settings.version')} {lastVersion}
        </Typography>
      )}

      {variant === 'guest' && (
        <Button
          variant="custom"
          className="text-blue-600 underline transition-colors duration-300 hover:text-blue-400 focus:text-blue-400"
          aria-label="view-terms-action"
          onClick={() => {
            setIsOpen(PopupTypeEnum.TERMS_OF_USE);
          }}
        >
          Terms of Use
        </Button>
      )}
    </footer>
  );
};
