import { queryOptions, useQuery } from '@tanstack/react-query';

import { ActionsAPINamespace } from '@/shared/api/actions/actions.requests.ts';
import { actionsKeys } from '@/shared/api/actions/actions.keys.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

export namespace useActionsHook {
  export const getActions = () => {
    const { user } = useFetchUserData();

    const setAlertsData = useAlertsStore(state => state.setAlertsData);
    const setArchivedAlertsData = useAlertsStore(state => state.setArchivedAlertsData);
    const siteResName = useUIStore(state => state.site);

    const actionsQueryOptions = queryOptions({
      ...actionsKeys.alerts(
        user?.accountResourceName as string,
        siteResName,
        user?.userResourceName as string,
      ),

      queryFn: async () => {
        const [unarchivedAlertsResponse, archivedAlertsResponse] =
          await ActionsAPINamespace.getAllAlerts(
            user?.accountResourceName as string,
            siteResName,
            user?.userResourceName as string,
          );

        setAlertsData(unarchivedAlertsResponse ?? []);
        setArchivedAlertsData(archivedAlertsResponse ?? []);

        return unarchivedAlertsResponse;
      },

      enabled: !!user?.accountResourceName && !!siteResName && !!user?.userResourceName,
    });

    return useQuery(actionsQueryOptions);
  };

  export const getActionsMetrics = () => {
    const { user } = useFetchUserData();

    const setMetricsData = useAlertsStore(state => state.setMetricsData);
    const site = useUIStore(state => state.site);

    const actionsMetricsQueryOptions = queryOptions({
      ...actionsKeys.metrics(user?.accountResourceName as string, site),

      queryFn: async () => {
        try {
          const data = await ActionsAPINamespace.getAllMetrics(
            user?.accountResourceName as string,
            site,
          );
          setMetricsData(data?.metrics ?? []);
          return data?.metrics ?? [];
        } catch {
          setMetricsData([]);
        }
      },

      enabled: !!user?.accountResourceName && !!site,
    });

    return useQuery(actionsMetricsQueryOptions);
  };
}
