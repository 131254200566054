import { languages } from '@/i18n/data';
import { systems } from '@/shared/components/ui/UserMenu/SystemSelect/SystemSelect.constants';

import { LocaleEnum } from '@/shared/types/i18n.types';
import { SystemViewEnum } from '@/shared/types/system.types';

export const handleRenderCurrentLanguage = (currentLanguage: LocaleEnum) => {
  return languages.find(({ id }) => id === currentLanguage);
};
export const handleRenderCurrentSystem = (currentSystem: SystemViewEnum) => {
  return systems.find(({ id }) => id === currentSystem);
};
