import { generateId } from '@/shared/utils/helpers';
import { MISSING_DATA } from '@/shared/utils/constants';

export const equipmentHistoryList = Array.from({ length: 100 }).map(_ => {
  return {
    id: generateId(),
    date: MISSING_DATA,
    startTime: MISSING_DATA,
    endTime: MISSING_DATA,
    firm: MISSING_DATA,
    reservation: MISSING_DATA,
  };
});
