import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { QUERY_KEY } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

export const useSetAssetsDetails = () => {
  const { user } = useFetchUserData();

  const site = useUIStore(store => store.site);
  const updateAssets = useGlobalStore(store => store.updateAssets);
  const assets = useGlobalStore(store => store.assets);

  useQuery({
    queryKey: [user?.accountResourceName, QUERY_KEY.ASSETS_DETAILS, site, assets],

    queryFn: async () => {
      const assetsWithDetails = await Promise.all(
        assets.map(async asset => {
          try {
            const details = await AssetsAPINamespace.getOne({
              accountResName: user?.accountResourceName as string,
              siteResName: site,
              assetResName: asset.assetResName,
            });
            return {
              ...asset,
              categoryPathResName: details.categoryPathResName,
              categoryTreeResName: details.categoryTreeResName,
              extraProperties: details.extraProperties,
            };
          } catch {
            return asset;
          }
        }),
      );

      updateAssets(assetsWithDetails);
      return [assets];
    },

    enabled: !!user?.accountResourceName && !!site && assets.length > 0,
  });
};
