import { createQueryKeys } from '@lukemorales/query-key-factory';

export const floorsKeys = createQueryKeys('floors', {
  all: (accountResourceName: string, siteResourceName: string, floor) => [
    'floors',
    accountResourceName,
    siteResourceName,
    floor,
  ],
});
