import { useNavigate, useSearchParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import * as schemas from '@/shared/schemas/validate-schemas';
import { AssetsAPINamespace } from '@/shared/api/assets';
import { QUERY_KEY, URL_PARAMS } from '@/shared/utils/constants';
import { notifyService } from '@/shared/services/notify.service';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { useUIStore } from '@/shared/store/ui';

import { RoutesEnum } from '@/shared/types/enums';
import { AssetsExtraProperties, AssetsUpdateRequest } from '@/shared/api/assets/assets.types';
import { ExtraPropertyKeys } from '../../Subcontractor/types';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';

export const useEditEquipment = () => {
  const [searchParameters] = useSearchParams();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const site = useUIStore(store => store.site);
  const tags = useGlobalStore(store => store.siteTagsList);
  const content = useModalStore(store => store.payload);
  const setIsClose = useModalStore(store => store.setIsClose);

  const assetResourceName = searchParameters.get(URL_PARAMS.EQUIPMENT_ID);

  const { filteredAssetsCategories, currentCategory, currentCategoryPath, renderInitialData } =
    useAssetsCategories(content);

  const { user } = useFetchUserData();
  const params = useForm<FieldValues>({
    mode: 'onChange',
    resolver: zodResolver(schemas.equipmentFormSchema),
    defaultValues: {
      ...(content.assetName && { title: content.assetName }),
      ...(content.assetStatus === 'active' && { active: true }),
      categoryType: content.categoryTreeResName ?? filteredAssetsCategories[0].categoryTreeResName,
      ...(currentCategory &&
        currentCategoryPath && {
          categorySubType:
            currentCategoryPath.categoryPathResName ??
            currentCategory?.categoryPathList[0].categoryPathResName,
        }),
      ...renderInitialData(),
    },
  });

  const { mutate, ...states } = useMutation({
    mutationKey: [QUERY_KEY.EQUIPMENT_UPDATE, user?.accountResourceName, site],

    mutationFn: async (data: FieldValues) => {
      const { title, categoryType, categorySubType, active, deleteTags = false, ...rest } = data;

      const renderExtraProperties = (): AssetsExtraProperties[] => {
        const result = Object.entries(rest).map(([key, value]) => {
          return {
            key,
            value,
          };
        });

        const filteredResult = result.filter(item => item.key !== 'tag');

        return deleteTags ? filteredResult : result;
      };

      const requestBody: AssetsUpdateRequest = {
        accountResName: user?.accountResourceName ?? '',
        siteResName: site,
        assetResName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
        assetName: title,
        assetStatus: active ? 'active' : 'inactive',
        categoryTreeResName: categoryType,
        ...(categorySubType && { categoryPathResName: categorySubType }),
        extraProperties: [
          ...renderExtraProperties(),
          { key: ExtraPropertyKeys.rentalCompany, value: rest.rentalCompany },
        ],
      };

      await AssetsAPINamespace.updateOne(requestBody);

      if (rest?.tag) {
        const currentTag = tags.find(tag => tag.tagResName === rest?.tag);

        await AssetsAPINamespace.updateAssetAttachedTags({
          accountResourceName: user?.accountResourceName ?? '',
          siteResourceName: site,
          assetResourceName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
          ...(deleteTags
            ? { tags: [] }
            : {
                tags: [
                  {
                    tagName: currentTag?.tagName ?? '-',
                    tagResName: currentTag?.tagResName ?? '-',
                  },
                ],
              }),
        });
      }

      return {
        assetResName: requestBody.assetResName,
        assetName: requestBody.assetName + '',
        orgResName: requestBody.orgResName + '',
      };
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ASSETS_TAGS],
      });

      navigate(RoutesEnum.EQUIPMENT);
      setIsClose();
      notifyService.success(ERROR_MESSAGES.EQUIPMENTS_UPDATE_SUCCESS);
    },

    onError: error => {
      console.error(error);
      notifyService.error(ERROR_MESSAGES.EQUIPMENTS_UPDATE_FAILED);
    },
  });

  const { mutate: deleteMutate, ...deleteStates } = useMutation({
    mutationKey: [QUERY_KEY.EQUIPMENT_DELETE, user?.accountResourceName, site],

    mutationFn: async () => {
      const requestBody = {
        accountResName: user?.accountResourceName ?? '',
        siteResName: site,
        assetResName: content?.tableUpdate ? content?.assetResName : assetResourceName ?? '',
        ...(params.getValues('title') && {
          assetName: params.getValues('title'),
        }),
        assetStatus: 'deleted',
        ...(params.getValues('categoryType') && {
          categoryTreeResName: params.getValues('categoryType'),
        }),
        ...(params.getValues('categorySubType') && {
          categoryPathResName: params.getValues('categorySubType'),
        }),
      };
      await AssetsAPINamespace.updateOne(requestBody);
      return {
        assetResName: requestBody.assetResName,
        assetName: requestBody.assetName + '',
        orgResName: requestBody.orgResName + '',
      };
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.EQUIPMENTS_GET],
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ASSETS_TAGS],
      });

      navigate(RoutesEnum.EQUIPMENT);
      setIsClose();
      notifyService.success(ERROR_MESSAGES.EQUIPMENTS_DEACTIVATE_SUCCESS);
    },

    onError: error => {
      console.error(error);
      notifyService.error(ERROR_MESSAGES.EQUIPMENTS_DEACTIVATE_FAILED);
    },
  });

  return {
    params,
    mutate,
    states,
    deleteMutate,
    deleteStates,
    content,
  };
};
