import { useModalStore } from '@/shared/store/modal';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect } from 'react';

export function useEditGroup() {
  const content = useModalStore(state => state.payload);

  const params = useForm<FieldValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    params.reset({
      ...(content.categoryTreeName && { title: content.categoryTreeName }),
    });
  }, [content, params]);

  return { content, params };
}
