import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
  AssetBody,
  AssetCategory,
  AssetOrganization,
  AssetsTagsBody,
} from '@/shared/types/assets.types';
import { GeofenceCategoryEnum, GeofencesProps, Point } from '@/shared/types/geofences.types';
import { GlobalProps } from '@/shared/store/global/global.types';
import { LocationHistory } from '@/shared/types/locations.types';
import { ReadersProps } from '@/shared/types/readers.types';
import { StatusEnum } from '@/shared/types/enums';
import { Tag } from '@/shared/types/tags.types';
import { ReservationsListResponse } from '@/shared/api/reservation/types';
import { SlicedZPSUser, ZPSUser } from '@/shared/types/user.types';
import { SiteMap } from '@/shared/api/maps/maps.types.ts';

export const useGlobalStore = create<GlobalProps>()(
  devtools(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (set, get) => ({
      sidebarFilterValue: '',
      setSidebarFilterValue: (value: string) => {
        set({
          sidebarFilterValue: value,
        });
      },

      viewableCoordinates: [],
      setViewableCoordinates: (value: Array<number>) => {
        set({
          viewableCoordinates: value,
        });
      },

      currentGeofencePoints: [],
      currentGeofenceId: '',
      currentGeofenceType: GeofenceCategoryEnum.INACTIVE,
      setCurrentGeofence: (coordinates: Point[], type: GeofenceCategoryEnum) => {
        set({
          currentGeofencePoints: coordinates,
          currentGeofenceType: type,
        });
      },
      setCurrentGeofenceId: (id: string) => {
        set({
          currentGeofenceId: id,
        });
      },

      assets: [],
      assetsCategories: [],
      assetsOrganizations: [],
      assetsGroups: [],
      assetsTags: [],
      isAssetsLoading: true,
      setAssets: (
        assets: AssetBody[],
        assetsCategories: AssetCategory[],
        assetsOrganizations: AssetOrganization[],
        assetsGroups: unknown[],
        assetsTags,
        isLoading = false,
      ) => {
        set({
          assets,
          assetsCategories,
          assetsOrganizations,
          assetsGroups,
          assetsTags,
          isAssetsLoading: isLoading,
        });
      },
      updateAssets: (assets: AssetBody[]) => {
        set({ assets });
      },
      updateAssetsOrganizations: (assetsOrganizations: AssetOrganization[]) => {
        set({ assetsOrganizations });
      },
      updateAssetsTags: (assetsTags: AssetsTagsBody[]) => {
        set({ assetsTags });
      },
      updateAssetsCategories: (assetsCategories: AssetCategory[]) => {
        set({ assetsCategories });
      },

      zpsUsers: [],
      setZPSUsers: (zpsUsers: ZPSUser[]) => {
        set({ zpsUsers });
      },
      updateZPSUsers: (zpsUsers: SlicedZPSUser[] | ZPSUser[]) => {
        set({ zpsUsers: zpsUsers as unknown as ZPSUser[] });
      },

      zpsUser: {} as ZPSUser,
      setZPSUser: (zpsUser: ZPSUser) => {
        set({ zpsUser });
      },

      geofences: [],
      isGeofencesLoading: true,
      setGeofences: (geofences: GeofencesProps[], isLoading = false) => {
        geofences.sort((g1, g2) => g1.geofenceName.localeCompare(g2.geofenceName));
        const currentGeofenceResourceName = get().currentGeofenceId;

        const modifiedGeofences = geofences.map(geofence => {
          return {
            ...geofence,
            visible: geofence.geofenceResName === currentGeofenceResourceName,
          };
        });

        set({
          geofences: modifiedGeofences,
          isGeofencesLoading: isLoading,
        });
      },

      visibleGeofenceIds: [],
      setGeofenceVisible: (id, visible, reset) => {
        if (!id) return;
        const visibleGeofenceIds: string[] = [];
        if (!reset) visibleGeofenceIds.push(...get().visibleGeofenceIds);

        if (visible && !visibleGeofenceIds.includes(id)) visibleGeofenceIds.push(id);
        else if (!visible) visibleGeofenceIds.splice(visibleGeofenceIds.indexOf(id), 1);
        set({ visibleGeofenceIds });
      },
      toggleGeofenceVisible: id => {
        if (!id) return;
        const { visibleGeofenceIds, setGeofenceVisible } = get();
        setGeofenceVisible(id, !visibleGeofenceIds.includes(id));
      },

      equipment: [],
      equipmentInUse: [],
      isEquipmentLoading: true,
      isEquipmentInUseLoading: true,
      setEquipment: (equipment: AssetBody[], isLoading = false) => {
        equipment.sort((eq1, eq2) => eq1.assetName.localeCompare(eq2.assetName));
        set({ equipment, isEquipmentLoading: isLoading });
      },
      setEquipmentInUse: (equipment: AssetBody[], isLoading = false) => {
        equipment.sort((eq1, eq2) => eq1.assetName.localeCompare(eq2.assetName));
        set({ equipmentInUse: equipment, isEquipmentInUseLoading: isLoading });
      },

      reservation: [],
      isReservationLoading: true,
      setReservation: (reservation: ReservationsListResponse, isLoading = false) => {
        set({ reservation, isReservationLoading: isLoading });
      },

      company: [],
      companies: [],
      isCompanyLoading: true,
      setCompanies: (companies: AssetOrganization[], isLoading = false) => {
        set({ companies, isCompanyLoading: isLoading });
      },

      locationsHistory: [],
      isLocationsHistoryLoading: true,
      setLocationsHistory: (locationsHistory: LocationHistory[], isLoading = false) => {
        set(state => ({
          locationsHistory: [...state.locationsHistory, ...locationsHistory],
          isLocationsHistoryLoading: isLoading,
        }));
      },
      clearLocationsHistory: () => {
        set({
          locationsHistory: [],
        });
      },

      lastLocations: [],
      modifyLastLocations: [],
      serModifyLastLocations: (lastLocations: LocationHistory[]) => {
        set({ modifyLastLocations: lastLocations });
      },
      setLastLocations: (lastLocations: LocationHistory[]) => {
        set({ lastLocations });
      },
      clearLastLocations: () => {
        set({ lastLocations: [] });
      },

      workers: [],
      workersInUse: [],
      batchedWorkers: [],
      isWorkersLoading: true,
      setWorkers: (workers: AssetBody[], isLoading = false) => {
        workers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ workers, isWorkersLoading: isLoading });
      },
      setWorkersInUse: (workers: AssetBody[]) => {
        workers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ workersInUse: workers });
      },
      setBatchedWorkers: (batchedWorkers: AssetBody[]) => {
        batchedWorkers.sort((w1, w2) => w1.assetName.localeCompare(w2.assetName));
        set({ batchedWorkers });
      },

      readers: [],
      isReadersLoading: true,
      setReaders: (readers: ReadersProps[], isLoading = false) => {
        readers.sort((r1, r2) => r1.readerName.localeCompare(r2.readerName));
        set({ readers, isReadersLoading: isLoading });
      },

      executionId: '',
      setExecutionId: (executionId: string) => {
        set({ executionId });
      },

      webSocketUrl: '',
      setWebSocketUrl: (webSocketUrl: string) => {
        set({ webSocketUrl });
      },

      siteTagsList: [],
      setSiteTagsList: (value: Tag[]) => {
        set({ siteTagsList: value });
      },

      status: StatusEnum.IDLE,
      setStatus: (status: StatusEnum) => set({ status }),

      locatedAnchor: [],
      setLocatedAnchor: (locatedAnchor: string) => {
        set(state =>
          state.locatedAnchor.includes(locatedAnchor)
            ? { locatedAnchor: state.locatedAnchor.filter(id => id !== locatedAnchor) }
            : { locatedAnchor: [...state.locatedAnchor, locatedAnchor] },
        );
      },
      clearLocatedAnchor: () => {
        set({ locatedAnchor: [] });
      },

      geofenceMapBlobUrl: null,
      isGeofenceMapBlobUrlLoading: true,
      setGeofenceMapBlobUrlLoading: loading => {
        set({ isGeofenceMapBlobUrlLoading: loading });
      },
      setGeoFenceMapBlobUrl: value => {
        set({ geofenceMapBlobUrl: value, isGeofenceMapBlobUrlLoading: false });
      },
      geofenceMapBlobUrlList: {},
      setGeoFenceMapBlobUrlList: value => {
        set({ geofenceMapBlobUrlList: value });
      },

      siteMapList: [],
      setSiteMapList: (value: SiteMap[]) => {
        set({ siteMapList: value });
      },

      mapPreview: null,
      setMapPreview: value => {
        set({ mapPreview: value });
      },

      siteMapConfig: null,
      setSiteMapConfig: value => {
        set({ siteMapConfig: value });
      },

      searchFieldValue: '',
      setSearchFieldValue: value => {
        set({ searchFieldValue: value });
      },
      currentCognitoUser: null,
      setCurrentCognitoUser: user => {
        set({ currentCognitoUser: user });
      },
    }),
    {
      name: 'Global Store',
    },
  ),
);
