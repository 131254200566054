import { ForwardedRef, forwardRef } from 'react';
import { classnames } from '@/shared/utils/classnames';

import { DefaultInputProps, WithChildren, WithClassName } from '@/shared/types/common.types';

type InputProps = DefaultInputProps &
  WithChildren &
  WithClassName & {
    error?: string;
  };

export const Checkbox = forwardRef(
  ({ children, className, error, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <label
        className={classnames(
          'inline-flex w-full cursor-pointer items-center gap-x-2.5 py-[0.3125rem] transition-all',
          className,
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          className="checkboxAnimation defaultFocusStyle h-4 w-4 shrink-0 rounded border border-slate-200 text-blue-600"
          {...props}
        />
        {children}
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';
