import { queryOptions, useQuery } from '@tanstack/react-query';

import { MapsAPINamespace } from '@/shared/api/maps/maps.requests.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { destructZipFromBuffer } from '@/shared/utils/zip.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

export namespace useMapsHooks {
  export const getAll = () => {
    const siteResourceName = useUIStore(store => store.site);
    const floor = useUIStore(store => store.floor);
    const {
      setGeoFenceMapBlobUrl,
      geofenceMapBlobUrlList,
      setGeoFenceMapBlobUrlList,
      setSiteMapList,
      mapPreview,
      setSiteMapConfig,
      setGeofenceMapBlobUrlLoading,
    } = useGlobalStore();

    const { user } = useFetchUserData();

    const mapsQueryOptions = queryOptions({
      queryKey: [QUERY_KEY.MAPS, user?.accountResourceName, siteResourceName, mapPreview, floor],

      queryFn: async () => {
        setGeofenceMapBlobUrlLoading(true);

        const results = (
          await MapsAPINamespace.getAll({
            accountResourceName: user?.accountResourceName ?? '',
            siteResourceName,
          })
        ).filter(map => map.mapName);

        setSiteMapList(results);

        const preview = results.find(map => map.mapResName == mapPreview);

        const activeMap = preview ?? results.find(map => map.mapName === floor?.name);

        const preloaded = geofenceMapBlobUrlList[activeMap?.mapResName ?? ''];

        if (preloaded) {
          setGeoFenceMapBlobUrl(preloaded);
          return preloaded;
        }

        if (activeMap) {
          // Get map from bucket
          const signedUrl = await MapsAPINamespace.getSignedURL({
            accountResourceName: user?.accountResourceName ?? '',
            siteResourceName,
            mapResourceName: activeMap?.mapResName,
          });

          // Get zip file
          const zipFileBuffer = await MapsAPINamespace.getZipFile({
            mapSignedUrl: signedUrl,
          });

          // Destruct zip, return blobURL
          const result = await destructZipFromBuffer(zipFileBuffer);
          setGeoFenceMapBlobUrl(result?.updatedDaeUrl ?? null);
          setGeoFenceMapBlobUrlList({
            ...geofenceMapBlobUrlList,
            [activeMap.mapResName]: result?.updatedDaeUrl ?? null,
          });
          setSiteMapConfig(result?.siteMapConfig ?? null);
        } else {
          setGeoFenceMapBlobUrl(null);
          setSiteMapConfig(null);
        }

        return results;
      },

      enabled: !!user?.accountResourceName,
    });

    return useQuery(mapsQueryOptions);
  };
}
