import { FC } from 'react';

import { Button } from '@/shared/components/buttons/Button';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';

import Close from '@/assets/icons/close.svg?react';

type Props = {
  percentage: number;
};

export const ProgressBar: FC<Props> = ({ percentage }) => {
  const { setIsClose } = useModalHandlerTypes();

  return (
    <div className="fixed left-1/2 top-0 z-[9999] h-1 w-full -translate-x-1/2">
      <div //NOSONAR
        className="flex h-1 w-full overflow-hidden bg-slate-200"
        role="progressbar"
        aria-valuenow={Number(percentage.toFixed(0)) || 0}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className="z-[9999] flex flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-500 text-center text-xs text-white transition-all duration-300"
          style={{ width: `${percentage.toFixed(0)}%` }}
        />
      </div>

      <div className="absolute -bottom-20 right-4 z-50 md:right-10 xl:-bottom-24 xl:right-12">
        <Button
          className="rounded-full border-slate-200 p-4 text-slate-800 hover:bg-slate-100"
          variant="outline"
          onClick={setIsClose}
        >
          <Close />
        </Button>
      </div>
    </div>
  );
};
