import { forwardRef } from 'react';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';

import { WithClassName } from '@/shared/types/common.types';

type Props = WithClassName & {
  onClick: () => void;
};

export const SingOutMenuItem = forwardRef<HTMLButtonElement, Props>(
  ({ className, onClick }, ref) => {
    return (
      <SiteSelectItem
        ref={ref}
        className={classnames(className)}
        aria-label="sign-out-action"
        onClick={onClick}
      >
        {translate('navbar.signOut')}
      </SiteSelectItem>
    );
  },
);

SingOutMenuItem.displayName = 'SingOutMenuItem';
