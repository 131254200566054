import { SubcontractorsFormBody } from '@/shared/components/ui/Modal/Subcontractor/FormBody.tsx';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useInviteForm } from '../hooks/useInviteForm';

export function EditSubcontractorsWorker() {
  const { props, personForm, personFormHandler } = useInviteForm();

  props.type = 'workerEdit';

  return (
    <>
      <Typography className="mb-4 text-xl font-bold tracking-[0.1px]">
        {translate('global.editWorker')}
      </Typography>

      <SubcontractorsFormBody {...props} params={personForm} handler={personFormHandler} />
    </>
  );
}
