import { FC, Fragment } from 'react';
import { Transition } from '@headlessui/react';

import { WithChildren } from '@/shared/types/common.types.ts';

type Props = WithChildren & {
  to: 'overlay' | 'content';
};

export const TransitionChild: FC<Props> = ({ children, to }) => {
  switch (to) {
    case 'overlay':
      return (
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {children}
        </Transition.Child>
      );

    case 'content': {
      return (
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {children}
        </Transition.Child>
      );
    }

    default:
      return <></>;
  }
};
