export enum RoutesEnum {
  ACTIONS = '/actions',
  EQUIPMENT = '/equipment',
  HOME = '/',
  LICENSES = '/licenses',
  LOGIN = '/login',
  RELEASES = '/releases',
  REPORTS = '/reports',
  RESERVATIONS = '/reservations',
  RESTORE_PASSWORD = '/restore-password',
  SETTINGS = '/settings',
  SITE_SELECT = '/site-select',
  PEOPLE = '/people',
  TERMS = '/terms',
  MFA = '/mfa',
  MOBILE_AUTH = '/mobile-auth',
}

export enum NotifyEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}

export enum RolesEnum {
  MANAGER = 'manager',
  ENGINEER = 'engineer',
  KOTO = 'koto',
  SUBCONTRACTOR = 'subcontractor',
  WORKER = 'worker',
}

export enum StatusEnum {
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

export enum PopupTypeEnum {
  ALERTS = 'alerts',
  DEFAULT = 'default',
  EQUIPMENT = 'equipment',
  MAP_FILTER = 'map_filter',
  SUBCONTRACTORS_INVITE = 'subcontractors_invite',
  SUBCONTRACTORS_FIRM_EDIT = 'subcontractors_firm_edit',
  SUBCONTRACTORS_WORKER_EDIT = 'subcontractors_worker_edit',
  GROUPS_CREATE = 'groups_create',
  GROUPS_EDIT = 'groups_edit',
  GROUPS_DELETE = 'groups_delete',
  EQUIPMENT_CREATE = 'equipment_create',
  EQUIPMENT_EDIT = 'equipment_edit',
  EQUIPMENT_DELETE = 'equipment_delete',
  EQUIPMENT_RESERVE = 'equipment_reserve',
  MAP = 'map',
  PEOPLES = 'subcontractors',
  REPORTS = 'reports',
  REQUESTS = 'requests',
  SETTINGS = 'settings',
  ZONE = 'zone',
  CONFIRM_DELETE = 'confirm_delete',
  CONFIRM_SAVE = 'confirm_save',
  RESERVATIONS = 'reservations',
  RESERVATIONS_CONFIRM_DELETE = 'reservations_confirm_delete',
  RESERVATIONS_CONFIRM_SUBMIT = 'reservations_confirm_submit',
  RESERVATIONS_CONFIRM_APPROVE = 'reservations_confirm_approve',
  RESERVATIONS_CONFIRM_REJECT = 'reservations_confirm_reject',
  CONFIRM_DELETE_WORKER = 'confirm_delete_worker',
  CONFIRM_DELETE_FIRM = 'confirm_delete_firm',
  TERMS_OF_USE = 'terms_of_use',
}

export enum SidebarViewEnum {
  VIEW = 'view',
  EDIT = 'edit',
  ADD_ZONE = 'add_zone',
  EDIT_ZONE = 'edit_zone',
}
