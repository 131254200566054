import { FC } from 'react';

import { useGetZpsUsers } from '@/shared/hooks/users/useGetZpsUser.ts';
import { useSetAssets } from '@/shared/hooks/assets/useSetAssets';
import { useSetAssetsCategories } from '@/shared/hooks/assets/useSetAssetsCategories';
import { useSetAssetsDetails } from '@/shared/hooks/assets/useSetAssetsDetails';
import { useSetCompanies } from '@/shared/hooks/assets/useSetCompanies';
import { useSetEquipment } from '@/shared/hooks/assets/useSetEquipment';
import { useSetTags } from '@/shared/hooks/assets/useSetTags.ts';
import { useSetWorkers } from '@/shared/hooks/assets/useSetWorkers';
import { useSetZpsUsers } from '@/shared/hooks/users/useSetZpsUsers.ts';
import { useSitesHooks } from '@/shared/api/sites/useSitesHooks.ts';

export const AssetHooks: FC = () => {
  useGetZpsUsers();
  useSetCompanies();
  useSetZpsUsers();

  useSetAssetsCategories();
  useSetTags();
  useSitesHooks.getAllTags();
  useSetEquipment();
  useSetWorkers();
  useSetAssetsDetails();
  useSetAssets();

  return null;
};
