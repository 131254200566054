import { queryOptions, useQuery } from '@tanstack/react-query';

import { AnalyticsAPINamespace } from '@/shared/api/analytics/analytics.requests.ts';
import { useGeofencesHooks } from '@/shared/api/geofences/useGeofencesHooks.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { QUERY_SCHEMA } from '@/shared/schemas/query-schemas.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

import { AnalyticsDefaultProps, TripEdge } from '@/shared/api/analytics/analytics.types.ts';

export namespace useAnalyticsHooks {
  export const getGeofenceSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'startTime' | 'endTime'>>,
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();

    const { startTime, endTime } = data;

    const geofencesQueryOptions = queryOptions({
      queryKey: [
        QUERY_KEY.ANALYTICS_DWELL_TIMES,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
      ],

      queryFn: async () => {
        return await AnalyticsAPINamespace.getGeofenceSummaries({
          accountResName: user?.accountResourceName as string,
          siteResName: site,
          startTime,
          endTime,
        });
      },

      enabled: !!user?.accountResourceName,

      staleTime: QUERY_SCHEMA.STALE_TIME.DAY,
    });

    return useQuery(geofencesQueryOptions);
  };

  export const getAssetUtilizationSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'startTime' | 'endTime'>> &
      Partial<Pick<AnalyticsDefaultProps, 'categoryTreeResourceName'>> & {
        dateParameter: string | null;
      },
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();
    const { data: geofences = [] } = useGeofencesHooks.getAll();

    const { startTime, endTime, categoryTreeResourceName, dateParameter } = data;

    const geofencesQueryOptions = queryOptions({
      queryKey: [
        QUERY_KEY.ANALYTICS_TRIPS,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
        categoryTreeResourceName,
      ],

      queryFn: async () => {
        const modifiedGeofences = geofences.map(geofence => geofence.geofenceResName);

        return await AnalyticsAPINamespace.getAssetUtilizationSummaries({
          accountResName: user?.accountResourceName as string,
          siteResName: site,
          startTime,
          endTime,
          categoryTreeResourceName,
          geofenceResourceNameList: modifiedGeofences || [],
        });
      },

      enabled: !!user?.accountResourceName && !!dateParameter,

      staleTime: QUERY_SCHEMA.STALE_TIME.DAY,
    });

    return useQuery(geofencesQueryOptions);
  };

  export const getGeofenceTripsSummaries = (
    data: Required<Pick<AnalyticsDefaultProps, 'startTime' | 'endTime'>> & TripEdge,
  ) => {
    const site = useUIStore(store => store.site);

    const { user } = useFetchUserData();

    const { startTime, endTime, startZone, endZone, asset } = data;

    const geofencesQueryOptions = queryOptions({
      queryKey: [
        QUERY_KEY.ANALYTICS_TRIPS,
        user?.accountResourceName,
        site,
        startTime,
        endTime,
        startZone,
        endZone,
        asset,
      ],

      queryFn: async () => {
        return await AnalyticsAPINamespace.getGeofenceTripsSummaries({
          accountResName: user?.accountResourceName as string,
          siteResName: site,
          startTime,
          endTime,
          startGeofenceResourceName: startZone,
          endGeofenceResourceName: endZone,
          assetResourceNameList: [asset],
        });
      },

      enabled: !!user?.accountResourceName && !!asset && !!startZone && !!endZone,

      staleTime: QUERY_SCHEMA.STALE_TIME.DAY,
    });

    return useQuery(geofencesQueryOptions);
  };
}
