import { create } from 'zustand';

import { ActionsStoreProps } from '@/shared/store/actions/actions.types';
import { AlertProps } from '@/shared/types/actions.types.ts';

export const useAlertsStore = create<ActionsStoreProps>((set, get) => ({
  alertsData: [],
  archivedAlertsData: [],
  isAlertsDataLoading: true,
  setAlertsData: (newAlertsData, isLoading) => {
    set({
      alertsData: newAlertsData,
      isAlertsDataLoading: isLoading || false,
    });
  },
  setArchivedAlertsData: (newAlertsData: AlertProps[]) => {
    set({ archivedAlertsData: newAlertsData });
  },
  moveAlertToArchive: (alertResourceName: string) => {
    const { alertsData, archivedAlertsData } = get();
    const alert = alertsData.find(alert => alert.alertResName === alertResourceName);

    if (alert) {
      const index = alertsData.indexOf(alert);
      const alertsBuffer = [...alertsData];
      const archivedAlertsBuffer = [...archivedAlertsData];

      archivedAlertsBuffer.push(...alertsBuffer.splice(index, 1));

      set({
        alertsData: alertsBuffer,
        archivedAlertsData: archivedAlertsBuffer,
      });
    }
  },
  addAlertData: newAlertData => {
    const alertsData = get().alertsData;
    const existing = alertsData.find(alert => alert.alertResName === newAlertData.alertResName);
    if (!existing) set({ alertsData: [...alertsData, newAlertData] });
  },
  wsAlertAvailable: false,
  setWsAlertAvailable: available => {
    set({ wsAlertAvailable: available });
  },
  metricsData: [],
  isMetricsDataLoading: false,
  setMetricsData: (newMetricsData, isLoading) => {
    set({
      metricsData: newMetricsData,
      isMetricsDataLoading: isLoading || false,
    });
  },
}));
