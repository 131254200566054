import { translate } from '@/i18n';

import { NotificationCategoryData } from '@/modules/Settings/types/types.ts';
import { SelectDataProps } from '@/shared/components/form/Select/Select.types.tsx';

export const initialState: NotificationCategoryData[] = [
  {
    title: translate('global.system'),
    enabled: true,
    subCategories: [
      { title: translate('alerts.newTagOnline'), enabled: true, value: 'high' },
      { title: translate('alerts.newReaderOnline'), enabled: true, value: 'medium' },
      { title: translate('alerts.tagLeftSite'), enabled: true, value: 'low' },
      { title: translate('alerts.readerOffline'), enabled: true, value: 'high' },
      { title: translate('alerts.tagOffline'), enabled: true, value: 'medium' },
      { title: translate('alerts.batteryCritical'), enabled: true, value: 'low' },
      { title: translate('alerts.batteryLow'), enabled: true, value: 'high' },
    ],
  },
  {
    title: translate('actions.safety'),
    enabled: false,
    subCategories: [],
  },
  {
    title: translate('global.equipment'),
    enabled: false,
    subCategories: [],
  },
  {
    title: translate('global.geofences'),
    enabled: false,
    subCategories: [],
  },
];

export const selectData: SelectDataProps[] = [
  { id: 'high', name: translate('alert.severity.high'), color: 'bg-red-600' },
  { id: 'medium', name: translate('alert.severity.medium'), color: 'bg-yellow-600' },
  { id: 'low', name: translate('alert.severity.low'), color: 'bg-slate-600' },
];
