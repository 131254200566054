import { useSinglePrismicDocument } from '@prismicio/react';

import { MISSING_DATA } from '@/shared/utils/constants.ts';
import { PrismicTypeEnum } from '@/shared/prismic-client/prismic.ts';

import { PrismicReleaseProps, ReleaseProps } from '@/shared/types/release-notes.types.ts';

export const useReleaseNotes = () => {
  const [document] = useSinglePrismicDocument(PrismicTypeEnum.RELEASES);

  const data: PrismicReleaseProps[] = document?.data?.releases ?? [];

  const releases: ReleaseProps[] = data
    ?.map((release: PrismicReleaseProps) => ({
      ...release,
      features: release.features.map(feature => feature.text),
      fixes: release.fixes.map(fix => fix.text),
    }))
    .reverse();

  const lastVersion = releases?.at(0)?.release_version ?? MISSING_DATA;

  return {
    document,
    lastVersion,
    releases,
  };
};
