import { WithChildren, WithClassName } from '@/shared/types/common.types';

import Equipment from '@/assets/icons/equipment-sm.svg?react';
import Square from '@/assets/icons/square.svg?react';
import Readers from '@/assets/icons/readers.svg?react';
import Worker from '@/assets/icons/worker.svg?react';
import Zone from '@/assets/icons/zone.svg?react';

export type AccordionProps = WithChildren &
  WithClassName & {
    open?: boolean;
    addEventListener?: () => void;
  };

export type AccordionItemProps = Pick<AccordionProps, 'children' | 'className'> & {
  iconClassName?: string;
  handleClick?: () => void;
};

export type SummaryTitleProps = WithClassName & {
  icon: keyof typeof accordionIcons;
  count: number | string | JSX.Element;
  showing: number | string | JSX.Element;
  withShowing?: boolean;
  withCount?: boolean;
};

export const accordionIcons = {
  equipment: Equipment,
  readers: Readers,
  worker: Worker,
  zone: Zone,
  'red-zone': Square,
  'work-zone': Square,
  inactive: Square,
};

export enum SummaryTitleEnum {
  EQUIPMENT = 'equipment',
  READERS = 'readers',
  WORKER = 'worker',
  ZONE = 'zone',
  RED_ZONE = 'red-zone',
  WORK_ZONE = 'work-zone',
  INACTIVE = 'inactive',
}
