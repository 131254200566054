import { findCompanyNameForAssetData, findTagValue } from '@/shared/utils/assets.ts';
import { isEmptyOrIncludes } from '@/shared/utils/is-empty-or-includes.ts';

import { AssetBody, AssetCategory, AssetOrganization } from '@/shared/types/assets.types.ts';
import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { FloorProps } from '@/shared/api/floors/floors.types.ts';
import { MapFilterType } from '@/shared/types/filters.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

export class FilteredSocketDataService {
  private readonly socketMapData: SocketMapDataProps[];
  private readonly assets: AssetBody[];
  private readonly companies: AssetOrganization[];
  private readonly assetCategories: AssetCategory[];
  private readonly floorData: FloorProps;
  private readonly searchKey: string;
  private readonly filters: MapFilterType;

  constructor(
    socketMapData: SocketMapDataProps[],
    assets: AssetBody[],
    assetCategories: AssetCategory[],
    companies: AssetOrganization[],
    floorData: FloorProps,
    filters: MapFilterType,
    searchKey: string,
  ) {
    this.socketMapData = socketMapData;
    this.assets = assets;
    this.assetCategories = assetCategories;
    this.companies = companies;
    this.floorData = floorData;
    this.filters = filters;
    this.searchKey = searchKey;
  }

  public getFilteredSocketMapData(): SocketMapDataProps[] {
    const filteredData = this.filterSocketMapData(this.assets);
    return this.mapSocketMapData(filteredData, this.assets);
  }

  private filterSocketMapData(combinedAssets: AssetBody[]): SocketMapDataProps[] {
    return this.socketMapData.filter(socketItem => {
      const socketAsset = combinedAssets.find(
        item => item.visible && findTagValue(item) === socketItem.tagResName,
      );
      if (!socketAsset) return false;

      const socketAssetCategory = this.assetCategories.find(
        category => category.categoryTreeResName === socketAsset?.categoryTreeResName,
      );
      const socketAssetCompany = this.companies.find(
        company => company.orgResName === findTagValue(socketAsset, 'firm'),
      );
      const socketAssetIsWorker = socketAssetCategory?.categoryTreeName === AssetTypeEnum.Worker;

      const socketAssetIsVisible = (() => {
        if (socketAssetIsWorker) {
          const { firm, workerRole, group, resName } = this.filters;

          if (
            this.searchKey.length === 0 &&
            firm.length === 0 &&
            workerRole.length === 0 &&
            group.length === 0 &&
            resName.length === 0
          ) {
            return true;
          }

          const _firm = socketAssetCompany?.orgName ?? '';
          const _role = findTagValue(socketAsset, 'role') ?? '';
          const _group = findTagValue(socketAsset, 'group') ?? '';

          return (
            isEmptyOrIncludes(this.searchKey, socketAsset.assetName) &&
            isEmptyOrIncludes(firm, _firm) &&
            isEmptyOrIncludes(workerRole, _role) &&
            isEmptyOrIncludes(group, _group) &&
            isEmptyOrIncludes(resName, socketAsset.assetResName)
          );
        } else {
          const { firm, equipmentType, equipmentSubType, equipmentReservedBy, group, resName } =
            this.filters;

          if (
            this.searchKey.length === 0 &&
            firm.length === 0 &&
            equipmentType.length === 0 &&
            equipmentSubType.length === 0 &&
            equipmentReservedBy.length === 0 &&
            group.length === 0 &&
            resName.length === 0
          ) {
            return true;
          }

          const _firm = socketAsset?.companyName ?? '';
          const _categoryName = socketAssetCategory?.categoryTreeName ?? '';
          const subCategoryName =
            socketAssetCategory?.categoryPathList.find(
              sub => sub.categoryPathResName === socketAsset.categoryPathResName,
            )?.categoryPath ?? '';
          const _reservedBy = findTagValue(socketAsset, 'reservedBy') ?? '';
          const _group = findTagValue(socketAsset, 'group') ?? '';

          return (
            isEmptyOrIncludes(this.searchKey, socketAsset.assetName) &&
            isEmptyOrIncludes(firm, _firm) &&
            isEmptyOrIncludes(equipmentType, _categoryName) &&
            isEmptyOrIncludes(equipmentSubType, subCategoryName) &&
            isEmptyOrIncludes(equipmentReservedBy, _reservedBy) &&
            isEmptyOrIncludes(group, _group) &&
            isEmptyOrIncludes(resName, socketAsset.assetResName)
          );
        }
      })();

      const socketAssetIsWithinZRange = this.isWithinZRange(Number(socketItem.z));

      return socketAssetIsVisible && socketAssetIsWithinZRange;
    });
  }

  private mapSocketMapData(
    filteredData: SocketMapDataProps[],
    assets: AssetBody[],
  ): SocketMapDataProps[] {
    return filteredData.map(item => {
      const asset = assets.find(asset => findTagValue(asset) === item.tagResName);
      const company = findCompanyNameForAssetData(
        asset as AssetBody,
        ['company', 'firm'],
        this.companies,
      );

      if (!asset) {
        return {
          ...item,
          company: 'N/A',
        };
      }

      return {
        ...item,
        device_name: asset?.assetName ?? 'N/A',
        company: company ?? 'N/A',
      };
    });
  }

  private isWithinZRange(z: number): boolean {
    const zMin = Math.min(this.floorData.readerPlacementLevel, this.floorData.ceilingHeight) / 100;
    const zMax = Math.max(this.floorData.readerPlacementLevel, this.floorData.ceilingHeight) / 100;

    return z >= zMin && z <= zMax;
  }
}
