import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { new_relic_dev, new_relic_int, new_relic_prd } from '@/newrelic.config';
import flagsmith from 'flagsmith';

class NewRelicService {
  private browserAgent: BrowserAgent | undefined;
  private userId: string = '';
  private accountId: string = '';
  private siteId: string = '';
  private sub: string = '';
  private iss: string = '';
  private role: string = '';

  public init() {
    const newRelicFlag = flagsmith.getAllFlags()?.new_relic;

    if (
      !this.browserAgent &&
      newRelicFlag?.enabled &&
      newRelicFlag?.value?.toString().includes(import.meta.env.VITE_ENVIRONMENT_NEW_RELIC_ENV)
    ) {
      switch (import.meta.env.VITE_ENVIRONMENT_NEW_RELIC_OPTION) {
        case 'new_relic_dev':
          this.browserAgent = new BrowserAgent({ ...new_relic_dev, features: [Metrics] });
          break;
        case 'new_relic_int':
          console.log(import.meta.env.VITE_ENVIRONMENT_NEW_RELIC_OPTION);
          this.browserAgent = new BrowserAgent({ ...new_relic_int, features: [Metrics] });
          break;
        case 'new_relic_prd':
          this.browserAgent = new BrowserAgent({ ...new_relic_prd, features: [Metrics] });
          break;
        default:
        //nothing
      }
      this.browserAgent?.run();
    }
  }

  public loginUser(
    userId: string,
    accountId: string,
    siteId: string,
    sub: string,
    iss: string,
    role: string,
  ) {
    this.userId = userId;
    this.accountId = accountId;
    this.siteId = siteId;
    this.sub = sub;
    this.iss = iss;
    this.role = role;

    this.browserAgent?.setUserId(`zrn:${this.userId}`);
    this.browserAgent?.setCustomAttribute('ZnrAccountId', this.accountId);
    this.browserAgent?.setCustomAttribute('ZnrSiteId', this.siteId);
    this.browserAgent?.setCustomAttribute('ZnrSub', this.sub);
    this.browserAgent?.setCustomAttribute('ZnrIss', this.iss?.split('/').pop() ?? null);
    this.browserAgent?.setCustomAttribute('ZnrRole', this.role);
  }

  public SetSiteId(siteId: string) {
    this.siteId = siteId;

    this.browserAgent?.setCustomAttribute('ZnrAccountId', this.accountId);
    this.browserAgent?.setCustomAttribute('ZnrSiteId', this.siteId);
  }

  public logoutUser() {
    this.browserAgent?.setUserId(null);
    this.browserAgent?.setCustomAttribute('ZnrAccountId', null);
    this.browserAgent?.setCustomAttribute('ZnrSiteId', null);
    this.browserAgent?.setCustomAttribute('ZnrSub', null);
    this.browserAgent?.setCustomAttribute('ZnrIss', null);
    this.browserAgent?.setCustomAttribute('ZnrRole', null);
  }
}

export const newRelicService = new NewRelicService();
