import { FloorProps } from '@/shared/api/floors/floors.types.ts';
import { useMemo } from 'react';

import { FilteredSocketDataService } from '@/shared/services/filtered-socket-data.service.ts';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';

export const useFilteredSocketData = () => {
  const socketMapData = useSocketMapDataStore(state => state.socketMapData);
  const equipments = useGlobalStore(state => state.equipmentInUse);
  const workers = useGlobalStore(state => state.workersInUse);
  const companies = useGlobalStore(state => state.companies);
  const sidebarSearchKey = useGlobalStore(state => state.sidebarFilterValue);
  const assetsCategories = useGlobalStore(store => store.assetsCategories);
  const mapFilter = useUIStore(store => store.mapFilter);
  const floor = useUIStore(store => store.floor);

  const filteredSocketMapData = useMemo(() => {
    const combinedAssets = [...equipments, ...workers];
    const floorData = { ...floor } as FloorProps;

    const filteredSocketDataService = new FilteredSocketDataService(
      socketMapData,
      combinedAssets,
      assetsCategories,
      companies,
      floorData,
      mapFilter,
      sidebarSearchKey,
    );

    return filteredSocketDataService.getFilteredSocketMapData();
  }, [
    workers,
    equipments,
    floor,
    socketMapData,
    assetsCategories,
    companies,
    mapFilter,
    sidebarSearchKey,
  ]);

  return { filteredSocketMapData };
};
