import { queryOptions, useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets/assets.requests.ts';
import { QUERY_SCHEMA } from '@/shared/schemas/query-schemas.ts';
import { assetsKeys } from '@/shared/api/assets/assets.keys.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

import { AssetsFullDetailsParams } from '@/shared/api/assets/assets.types.ts';
import { QUERY_KEY } from '@/shared/utils/constants.ts';

export namespace useAssetHooks {
  export const getAllWithDetails = (params?: AssetsFullDetailsParams) => {
    const { user } = useFetchUserData();

    const site = useUIStore(store => store.site);

    const assetsQueryOptions = queryOptions({
      ...assetsKeys.allWithDetails,

      queryFn: async () => {
        return await AssetsAPINamespace.getAllWithDetails({
          accountResName: user?.accountResourceName as string,
          siteResName: site,
          params,
        });
      },

      enabled: !!user?.accountResourceName && !!site,

      staleTime: QUERY_SCHEMA.STALE_TIME['10_MINUTES'],
    });

    return useQuery(assetsQueryOptions);
  };

  export const useGetAssetsCategories = () => {
    const site = useUIStore(state => state.site);

    const { user } = useFetchUserData();

    const assetsCategoriesQueryOptions = queryOptions({
      queryKey: [QUERY_KEY.ASSETS_CATEGORIES, user?.accountResourceName, site],

      queryFn: async () => {
        const result = await AssetsAPINamespace.getAllCategoryTress(
          user?.accountResourceName as string,
          site,
        );

        return result?.map(item => {
          const modifiedItemPaths = item.categoryPathList.map(pathItem => {
            return {
              ...pathItem,
              extended: false,
            };
          });

          return {
            ...item,
            categoryPathList: modifiedItemPaths,
            extended: true,
          };
        });
      },

      enabled: !!user?.accountResourceName && !!site,
    });

    return useQuery(assetsCategoriesQueryOptions);
  };
}
