import { FC } from 'react';

import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { classnames } from '@/shared/utils/classnames.ts';
import { translate } from '@/i18n';
import { useUIStore } from '@/shared/store/ui';

import { WithClassName } from '@/shared/types/common.types.ts';
import ChevronRight from '@/assets/icons/chevron-right.svg?react';

type Props = WithClassName & {
  onClick: () => void;
};

export const LanguageMenuItem: FC<Props> = ({ onClick, className }) => {
  const currentLanguage = useUIStore(state => state.currentLanguage);

  return (
    <SiteSelectItem
      className={classnames(
        'inline-flex w-full items-center justify-between rounded-md text-sm font-medium',
        className,
      )}
      aria-label="language-menu-action"
      onClick={onClick}
    >
      {translate('global.language')} ({currentLanguage})
      <ChevronRight />
    </SiteSelectItem>
  );
};
