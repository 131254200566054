import { FC, useEffect } from 'react';
import { useThree } from '@react-three/fiber';

import { useUIStore } from '@/shared/store/ui';

export const CameraDistance: FC = () => {
  const setCurrentDistance = useUIStore(state => state.setDistance);
  const { camera } = useThree();

  useEffect(() => {
    setCurrentDistance(camera.position.z);
  }, [camera.position.z, setCurrentDistance]);

  return null;
};
