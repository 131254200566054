import { FC, memo } from 'react';
import { Canvas } from '@react-three/fiber';
import { Grid } from '@react-three/drei';

import ColladaModel from '@/modules/Home/components/map/ColladaModel/ColladaModel';
import { Anchors } from '@/modules/Home/components/map/Anchors';
import { CameraDistance } from '@/modules/Home/components/map/CameraDistance';
import { Clusters } from '@/modules/Home/components/map/Clusters';
import { CustomOrbitControls } from '@/modules/Home/components/map/CustomOrbitControls';
import { Fade } from '@/shared/components/animation/Fade';
import { Geofences } from '@/modules/Home/components/map/Geofences';
import { HistoricalTrackers } from '@/modules/Home/components/map/HistoricalTrackers';
import { InitialTrackers } from '@/modules/Home/components/map/InitialTrackers';
import { Loader } from '@/shared/components/ui/Loader';
import { Readers } from '@/modules/Home/components/map/Readers';
import { translate } from '@/i18n';
import {
  useClickOutsideMap,
  useClusters,
  useLocationHistory,
  useMapGrid,
  useMapModel,
} from '@/modules/Home/hooks/map';
import { useFilteredSocketData } from '@/shared/hooks/ws/useFilteredSocketData';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams.ts';
import { useMapModelState } from '@/modules/Home/hooks/common';
import { useShowLastTrackersPositionFeatureFlag } from '@/shared/feature-flags/useShowLastTrackersPositionFeatureFlag.ts';
import { useUIStore } from '@/shared/store/ui';

import { MapModelProps } from '@/modules/Home/types/MapModel.types';
import { TrackersProps } from '@/modules/Home/types/InitialTrackers.types';

const OFFSET_Y = 4;

const MapModel: FC<MapModelProps> = ({
  isGridVisible,
  mapCoordinates,
  mapKey,
  mapView,
  isThumbnail,
  onUpdateMapCoords,
  thumbnailMapCoords,
}) => {
  const { filteredSocketMapData } = useFilteredSocketData();
  const { mappedLocationsHistory } = useLocationHistory();
  const { isHistoricalMode } = useLocalSearchParams();

  const {
    closeActivePopups,
    currentGeofenceMap,
    currentMap,
    geofences,
    filteredInitialTrackersMapData,
    filteredReaders,
    lastTrackersLocation,
    popups,
    initialCameraDistance,
  } = useMapModel(filteredSocketMapData);

  const { showLastTrackersPosition } = useShowLastTrackersPositionFeatureFlag();

  const modifiedLastTrackers = showLastTrackersPosition.enabled
    ? lastTrackersLocation.map((kItem: TrackersProps) => {
        const { assetCategory, ...rest } = {
          ...kItem.anchor,
          x: Number(kItem.location.x) / 100,
          y: Number(kItem.location.y) / 100,
          z: Number(kItem.location.z) / 100,
          tagName: kItem.tagName,
          tagResName: kItem.tagResName,
          visible: kItem.visible,
        };
        return { ...rest };
      })
    : [];

  const finalTrackers = [...filteredSocketMapData, ...modifiedLastTrackers];

  const { filteredClusters } = useClusters(finalTrackers);
  const { gridConfig } = useMapGrid({
    mapView,
    isGridVisible,
  });

  const floor = useUIStore(state => state.floor);

  const { isMapLoading, isLocationsHistoryLoading } = useMapModelState();
  const { handleCanvasClick } = useClickOutsideMap();

  if (isMapLoading || isLocationsHistoryLoading || !floor) {
    return (
      !isThumbnail && (
        <div className="flex h-full content-center items-center">
          <Loader appearance="section" loaderText={translate('confirm.loading')} />
        </div>
      )
    );
  }

  const renderedMapBody = () => {
    // Locations History
    if (isHistoricalMode || isThumbnail) {
      return <HistoricalTrackers locationsHistory={mappedLocationsHistory} mapKey={mapKey} />;
    }

    return (
      <>
        {/* Last Trackers Position */}
        {showLastTrackersPosition.enabled && (
          <InitialTrackers
            offset={OFFSET_Y}
            trackers={lastTrackersLocation}
            filteredClusters={filteredClusters}
            showLastLocation={false}
          />
        )}

        {/* Initial Trackers */}
        <InitialTrackers
          offset={OFFSET_Y}
          trackers={filteredInitialTrackersMapData}
          showLastLocation={true}
        />

        {/* Anchors */}
        <Anchors
          anchors={filteredSocketMapData}
          filteredClusters={filteredClusters}
          offset={OFFSET_Y}
        />

        {/*Clusters*/}
        <Clusters clusters={filteredClusters} />

        {/*Geofences*/}
        <Geofences geofences={geofences} />

        {/*Readers*/}
        <Readers readers={filteredReaders} />
      </>
    );
  };

  return (
    <Fade key={`${mapKey}-fade`} triggerOnce className="relative z-10 h-full">
      <Canvas
        camera={{
          aspect: window.innerWidth / window.innerHeight,
          position: [0, 0, Math.max(initialCameraDistance, 1)],
        }}
      >
        <directionalLight
          position={[0, 0, 3]} //NOSONAR
          intensity={2} //NOSONAR
          color="white"
        />
        <ambientLight
          intensity={1} //NOSONAR
        />

        <group
          rotation={mapCoordinates?.rotation} //NOSONAR
          scale={mapCoordinates?.scale}
          position={mapCoordinates?.position} //NOSONAR
          onClick={closeActivePopups}
          onPointerUp={handleCanvasClick}
          onPointerMissed={handleCanvasClick} //NOSONAR
        >
          {/*Map Initialisation*/}
          <ColladaModel
            key={`${mapKey}-colladamodel`}
            mapKey={mapKey}
            map={`${currentGeofenceMap ?? currentMap}#${mapKey}`}
          />

          {renderedMapBody()}
        </group>

        {/*CustomOrbitControls*/}
        <CustomOrbitControls
          popups={popups}
          mapView={mapView}
          onUpdateMapCoords={onUpdateMapCoords}
          thumbnailMapCoords={thumbnailMapCoords}
        />

        {/*Camera Distance*/}
        <CameraDistance />

        {/*Map Grid*/}
        <Grid {...gridConfig} />
      </Canvas>
    </Fade>
  );
};

export default memo(MapModel);
