import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import { LS_KEYS, QUERY_KEY } from '@/shared/utils/constants.ts';
import { useUIStore } from '@/shared/store/ui';
import { UserProps } from '@/shared/types/user.types';
import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';
import { RolesEnum } from '@/shared/types/enums';

export const useAssetStatus = () => {
  const isShowInactive = useUIStore(store => store.isShowInactive);
  const setIsShowInActive = useUIStore(store => store.setIsShowInactive);
  const userStorageData =
    WebAPIStorageNamespace.SessionService.getSessionItem(LS_KEYS.USER_DATA) ??
    WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USER_DATA);
  const user: UserProps = JSON.parse(userStorageData as string);

  const isCompaniesLoading = useIsFetching({
    queryKey: [QUERY_KEY.COMPANIES],
  });
  const isAssetsCategoriesLoading = useIsFetching({
    queryKey: [QUERY_KEY.ASSETS_CATEGORIES],
  });
  const isAssetsTagsLoading = useIsFetching({
    queryKey: [QUERY_KEY.ASSETS_TAGS],
  });
  const isSiteTagsLoading = useIsFetching({
    queryKey: [QUERY_KEY.SITE_TAGS],
  });
  const isEquipmentsLoading = useIsFetching({
    queryKey: [QUERY_KEY.EQUIPMENTS_GET],
  });
  const isWorkersLoading = useIsFetching({
    queryKey: [QUERY_KEY.WORKERS],
  });
  const isAssetsDetailsLoading = useIsFetching({
    queryKey: [QUERY_KEY.ASSETS_DETAILS],
  });
  const isAssetsLoading = useIsFetching({
    queryKey: [QUERY_KEY.ASSETS],
  });

  const isMutatingAssetsStatus = useIsMutating({
    mutationKey: [QUERY_KEY.TOGGLE_ASSET_STATUS],
  });

  const isMutatingFirmStatus = useIsMutating({
    mutationKey: [QUERY_KEY.TOGGLE_FIRM_STATUS],
  });

  const isFetchingEquipments = useIsFetching({
    queryKey: [QUERY_KEY.EQUIPMENTS_GET],
  });

  const allowToggleEqupment = user?.role === RolesEnum.MANAGER || user?.role === RolesEnum.ENGINEER;

  const isAllAssetsLoading =
    !!isCompaniesLoading ||
    !!isAssetsCategoriesLoading ||
    !!isAssetsTagsLoading ||
    !!isSiteTagsLoading ||
    !!isEquipmentsLoading ||
    !!isWorkersLoading ||
    !!isAssetsDetailsLoading ||
    !!isAssetsLoading;

  const isLoading =
    isAllAssetsLoading ||
    !!isMutatingAssetsStatus ||
    !!isFetchingEquipments ||
    !!isMutatingFirmStatus;

  const toggleShowInactive = () => {
    setIsShowInActive(!isShowInactive);
  };

  return {
    isAllAssetsLoading,
    isLoading,
    isShowInactive,
    toggleShowInactive,
    allowToggleEquipment: allowToggleEqupment,
  };
};
