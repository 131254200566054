import { create } from 'zustand';

import { usePopupStore } from '@/shared/store/popup';

import { LocationHistory } from '@/shared/types/locations.types';
import {
  SocketConnectionStatusProps,
  SocketMapDataProps,
  SocketMapDataStoreProps,
} from '@/shared/store/socket-map-data/socket-map-data.types';

export const useSocketMapDataStore = create<SocketMapDataStoreProps>((set, get) => ({
  socketMapData: [],
  isSocketMapDataLoading: true,
  setSocketMapData: (newSocketData: SocketMapDataProps, isLoading = false) => {
    const { popups } = usePopupStore.getState();

    const isPopupExists = popups.find(popup => popup.id === newSocketData.tagResName);

    if (!isPopupExists) {
      const filteredSocketMapData = get().socketMapData.filter(
        socketData => socketData.tagResName !== newSocketData.tagResName,
      );

      set({
        socketMapData: [...filteredSocketMapData, newSocketData],
        isSocketMapDataLoading: isLoading,
      });
    }
  },

  historySocketMapData: [],
  setHistorySocketMapData: () => {
    set(state => ({
      historySocketMapData: state.socketMapData,
    }));
  },

  initialTrackersMapData: [],
  setInitialTrackersMapData: (data: LocationHistory[]) => {
    set({
      initialTrackersMapData: data,
    });
  },

  connectionStatus: 'UNINSTANTIATED',
  setConnectionStatus: (status: SocketConnectionStatusProps) => {
    set({
      connectionStatus: status,
    });
  },
}));
