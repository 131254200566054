import { NavigateFunction } from 'react-router-dom';
import * as AWS from 'aws-sdk/global';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { ERROR_MESSAGES } from '@/shared/utils/error-messages';
import { LS_KEYS } from '@/shared/utils/constants';
import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';
import { notifyService } from '@/shared/services/notify.service';
import { userStorageService } from '@/shared/services/user-storage.service';

import * as CognitoTypes from '@/shared/types/cognito-user-pool.types';
import { AuthStorage } from '@/shared/types/auth.types';
import { RefreshTokenProps } from '@/shared/types/tokens.types';
import { RoutesEnum, StatusEnum } from '@/shared/types/enums';
import { UserProps } from '@/shared/types/user.types';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { newRelicService } from '@/shared/services/newrelic.service';

export class CognitoUserPoolService {
  status: StatusEnum = StatusEnum.IDLE;
  private authenticationDetails: AuthenticationDetails | null;
  private userPools: CognitoTypes.UserPoolProps;

  constructor() {
    this.authenticationDetails = null;

    this.userPools = {
      clientId: WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.CLIENT_ID) ?? '',
      awsRegion: WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.REGION) ?? '',
      userPoolId:
        WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.USER_POOL_ID) ?? '',
      identityPoolId:
        WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.IDENTITY_POOL_ID) ?? '',
      accountResName:
        WebAPIStorageNamespace.LocalStorageService.getLocalItem(LS_KEYS.ACCOUNT_RES_NAME)?.trim() ??
        '',
    };
  }

  set userPoolOptions(options: CognitoTypes.UserPoolProps) {
    this.userPools = options;
  }

  public success(
    session: CognitoUserSession,
    accountName: string,
    username: string,
    rememberMe: boolean,
    setStatus: (status: StatusEnum) => void,
    navigate: NavigateFunction,
    termsOfUse: 'done' | 'idle',
  ) {
    const payload = this.getUserPayload(session);

    this.initRegion();

    const credentials = this.cognitoIdentityCredentials(session);

    if (session) {
      credentials.refresh(error => {
        if (error) {
          console.error(error);
          notifyService.error(ERROR_MESSAGES.LOGIN_FAILED);

          this.status = StatusEnum.ERROR;
          setStatus(this.status);
          return;
        }

        const userData = {
          ...payload,
          accountResourceName: payload.accountResourceName?.trim(),
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        };

        userStorageService.setRememberMe(
          rememberMe as boolean,
          userData,
          accountName?.toLowerCase() as string,
        );

        if (payload.userResourceName) {
          mixpanelService.identify(payload.userResourceName.trim(), {
            Username: username,
            'Acct Res Name': payload.accountResourceName,
            'User Res Name': payload.userResourceName,
            $name: [payload.firstName, payload.lastName].filter(name => !!name).join(' '),
            $email: payload.email,
            Role: payload.role,
          });

          mixpanelService.logLogin();

          newRelicService.init();
          newRelicService.loginUser(
            payload.userResourceName?.trim() ?? '',
            payload.accountResourceName?.trim() ?? '',
            'undefined',
            payload.sub ?? '',
            payload.iss ?? '',
            payload.role ?? '',
          );
        }
        this.status = StatusEnum.SUCCESS;
        setStatus(this.status);
        if (termsOfUse === 'idle') navigate(RoutesEnum.TERMS);
        else navigate(RoutesEnum.SITE_SELECT);
      });
    }
  }

  public async login(
    { accountName, username, password, rememberMe }: CognitoTypes.UserCredentialsProps,
    setStatus: (status: StatusEnum) => void,
    navigate: NavigateFunction,
    termsOfUse: 'done' | 'idle',
    cognitoUser: CognitoUser,
  ): Promise<StatusEnum> {
    this.status = StatusEnum.LOADING;
    const successProps = {
      accountName,
      username,
      rememberMe,
      termsOfUse,
      password,
    };
    cognitoUser.authenticateUser(this.authenticationDetails as AuthenticationDetails, {
      onSuccess: async session => {
        const secretCode = await this.getMFASetUpKey(cognitoUser);
        if (secretCode) {
          // MFA is optional. Redirect to MFA setup page
          const totpURI = `otpauth://totp/${username}?secret=${secretCode}&issuer=Zainar`;
          this.status = StatusEnum.SUCCESS;
          setStatus(this.status);
          navigate(RoutesEnum.MFA, {
            state: { totpURI, secretCode, ...successProps, isMFAEnabled: false },
          });
        } else {
          // MFA not required. Proceed to login
          this.success(
            session,
            accountName ?? '',
            username,
            rememberMe ?? false,
            setStatus,
            navigate,
            termsOfUse,
          );
        }
      },
      onFailure: error => {
        console.error(error);
        notifyService.error(ERROR_MESSAGES.LOGIN_FAILED);

        this.status = StatusEnum.ERROR;
        setStatus(this.status);
      },
      mfaSetup: async () => {
        // MFA Setup required. Redirect to MFA setup page
        const secretCode = await this.getMFASetUpKey(cognitoUser);
        const totpURI = `otpauth://totp/${username}?secret=${secretCode}&issuer=Zainar`;
        this.status = StatusEnum.SUCCESS;
        setStatus(this.status);
        navigate(RoutesEnum.MFA, {
          state: { totpURI, secretCode, ...successProps, isMFAEnabled: false },
        });
      },
      totpRequired: () => {
        // TOTP required. Redirect to  Code verification page
        this.status = StatusEnum.SUCCESS;
        setStatus(this.status);
        navigate(RoutesEnum.MFA, { state: { ...successProps, isMFAEnabled: true } });
      },
    });

    return this.status;
  }

  public async updateUserAttributes(
    attributeList: CognitoTypes.CognitoUserAttributesProps,
    username: string,
  ): Promise<void> {
    const sessionData = WebAPIStorageNamespace.SessionService.getSessionItemParsed(
      LS_KEYS.USER_DATA,
    );
    const localData = WebAPIStorageNamespace.LocalStorageService.getLocalItemParsed(
      LS_KEYS.USER_DATA,
    );
    const cognitoUser = this.getCognitoUser(username);

    await new Promise((resolve, reject) => {
      cognitoUser.getSession((err: Error) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.updateAttributes(attributeList, (error, result) => {
            if (error) return reject(error);
            resolve(result);
          });
        }
      });
    });

    const cognitoUserSession = cognitoUser.getSignInUserSession();

    if (cognitoUserSession) {
      const payload = this.getUserPayload(cognitoUserSession);
      const isObjectNotEmpty = <T extends NonNullable<unknown>>(data: T) =>
        Object.keys(data).length > 0;

      if (sessionData && isObjectNotEmpty(sessionData)) {
        WebAPIStorageNamespace.SessionService.setSessionItem(LS_KEYS.USER_DATA, payload);
      } else if (localData && isObjectNotEmpty(localData)) {
        WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.USER_DATA, payload);
      }
    }
  }

  public async verifyAttribute(verificationCode: string, username: string): Promise<void> {
    const cognitoUser = this.getCognitoUser(username);

    await new Promise((resolve, reject) => {
      cognitoUser.getSession((err: Error) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.verifyAttribute('email', verificationCode, {
            onSuccess: result => {
              notifyService.success(ERROR_MESSAGES.UPDATE_USER_SUCCESS);
              resolve(result);
            },

            onFailure: error => {
              notifyService.error(ERROR_MESSAGES.UPDATE_USER_FAILED);
              reject(error);
            },
          });
        }
      });
    });
  }

  public async changePassword(oldPassword: string, newPassword: string, username: string) {
    const cognitoUser = this.getCognitoUser(username);

    await new Promise((resolve, reject) => {
      const changePasswordProvider = (err: Error | undefined, result: 'SUCCESS' | undefined) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      };

      if (cognitoUser) {
        cognitoUser.getSession((err: Error) => {
          if (err) {
            reject(err);
          } else {
            cognitoUser.changePassword(oldPassword, newPassword, changePasswordProvider);
          }
        });
      } else {
        reject(new Error('User is not authenticated'));
      }
    });
  }

  public async signOut() {
    const cognitoUser = await this.getCurrentUser();

    cognitoUser.signOut();
  }

  public refreshTokens(
    props: RefreshTokenProps,
    authStorage: AuthStorage,
    handleClearGlobalStore: () => void,
  ) {
    const { refreshToken, username } = props;

    const RefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });

    const cUser = this.getCognitoUser(username);

    cUser.refreshSession(RefreshToken, (error: Error, session: CognitoUserSession) => {
      if (error) {
        handleClearGlobalStore();
        return;
      }

      const payload = this.getUserPayload(session);

      this.initRegion();

      const credentials = this.cognitoIdentityCredentials(session);

      if (session) {
        credentials.refresh(() => {
          const userData = {
            ...payload,
            accountResourceName: payload.accountResourceName?.trim(),
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
          };

          console.log('[Refreshed tokens]');

          switch (authStorage) {
            case AuthStorage.LOCAL_STORAGE:
              WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.USER_DATA, userData);
              break;

            case AuthStorage.SESSION_STORAGE:
              WebAPIStorageNamespace.SessionService.setSessionItem(LS_KEYS.USER_DATA, userData);
              break;
          }
        });
      }
    });
  }

  public getCognitoUser(username: string): CognitoUser {
    const userData = {
      Username: username,
      Pool: this.userPool(),
      Storage: window.sessionStorage,
    };

    return new CognitoUser(userData);
  }

  private userPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: this.userPools.userPoolId.trim(),
      ClientId: this.userPools.clientId.trim(),
      Storage: window.localStorage,
      AdvancedSecurityDataCollectionFlag: true,
    });
  }

  private init({ username, password }: CognitoTypes.UserCredentialsProps): CognitoUser {
    const authenticationData = {
      Username: username.toLowerCase(),
      Password: password,
    };
    this.authenticationDetails = new AuthenticationDetails(authenticationData);

    return this.getCognitoUser(username.toLowerCase());
  }

  private async getCurrentUser(): Promise<CognitoUser> {
    return new Promise((resolve, reject) => {
      const userPool = this.userPool();
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((error: Error) => {
          if (error) return reject(error);

          resolve(cognitoUser);
        });
      }
    });
  }

  public initCognitoUser({
    username,
    password,
    rememberMe,
  }: {
    username: string;
    password: string;
    rememberMe: boolean;
  }) {
    return this.init({ username, password, rememberMe });
  }

  private cognitoIdentityCredentials(session: CognitoUserSession): AWS.CognitoIdentityCredentials {
    return new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.userPools.identityPoolId,
      Logins: {
        [`cognito-idp.${this.userPools.awsRegion}.amazonaws.com/${this.userPools.userPoolId}`]:
          session.getIdToken().getJwtToken(),
      },
    });
  }

  private initRegion() {
    AWS.config.region = this.userPools.awsRegion;
  }

  private getUserPayload(session: CognitoUserSession): UserProps {
    const tokens = this.getTokens(session);
    const sessionData = session.getIdToken().payload;
    const accessData = session.getAccessToken().payload;

    return {
      accessToken: tokens.accessToken,
      accountResourceName:
        sessionData['custom:accountResourceName'] ??
        sessionData['custom:accountResName'] ??
        this.userPools.accountResName,
      auth_time: session.getAccessToken().getIssuedAt(),
      avatar: '',
      customerResourceName: sessionData['custom:customerResourceName'],
      defSiteResourceName: sessionData['custom:defSiteResourceName'],
      email: sessionData.email,
      exp: session.getAccessToken().getExpiration(),
      firstName: sessionData.given_name,
      idToken: tokens.idToken,
      lastName: sessionData.family_name,
      refreshToken: tokens.refreshToken,
      role: sessionData['cognito:groups'][0],
      userResourceName: sessionData['custom:userResName'],
      username: accessData.username,
      sub: sessionData.sub,
      iss: sessionData.iss,
    };
  }

  private getTokens(session: CognitoUserSession): CognitoTypes.CognitoUserTokensProps {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
    };
  }

  //  MFA SetupKey;
  public async getMFASetUpKey(cognitoUser: CognitoUser): Promise<string | void> {
    return new Promise(resolve => {
      cognitoUser.associateSoftwareToken({
        associateSecretCode: secretCode => {
          resolve(secretCode);
        },
        onFailure: () => {
          resolve();
        },
      });
    });
  }

  // Verify MFA Code
  public async verifyMFATotpCode(
    code: string,
    accountName: string,
    username: string,
    rememberMe: boolean,
    setStatus: (status: StatusEnum) => void,
    navigate: NavigateFunction,
    termsOfUse: 'done' | 'idle',
    cognitoUser: CognitoUser,
    isMFAEnabled: boolean,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isMFAEnabled) {
        // Verify returning user's TOTP code
        cognitoUser.sendMFACode(
          code,
          {
            onSuccess: session => {
              this.success(
                session,
                accountName ?? '',
                username,
                rememberMe ?? false,
                setStatus,
                navigate,
                termsOfUse,
              );
            },
            onFailure: err => {
              notifyService.error(ERROR_MESSAGES.MFA_VERIFICATION_FAILED);
              this.status = StatusEnum.ERROR;
              setStatus(this.status);
              reject(err);
            },
          },
          'SOFTWARE_TOKEN_MFA',
        );

        return resolve();
      }
      //MFA verification to complete setup
      cognitoUser.verifySoftwareToken(code, 'SOFTWARE_TOKEN_MFA', {
        onSuccess: session => {
          // You can now enable MFA for the user
          cognitoUser.setUserMfaPreference(null, { PreferredMfa: true, Enabled: true }, err => {
            if (err) {
              console.error(err);
              notifyService.error(ERROR_MESSAGES.LOGIN_FAILED);
              this.status = StatusEnum.ERROR;
              setStatus(this.status);
            } else {
              this.success(
                session,
                accountName ?? '',
                username,
                rememberMe ?? false,
                setStatus,
                navigate,
                termsOfUse,
              );
            }
            resolve();
          });
        },
        onFailure: err => {
          notifyService.error(ERROR_MESSAGES.MFA_VERIFICATION_FAILED);
          this.status = StatusEnum.ERROR;
          setStatus(this.status);
          reject(err);
        },
      });
    });
  }
}

export const cognitoUserPoolService = new CognitoUserPoolService();
