import { SubcontractorsFormBody } from '@/shared/components/ui/Modal/Subcontractor/FormBody.tsx';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useInviteForm } from '../hooks/useInviteForm';

export function EditSubcontractorsFirm() {
  const { props, firmForm, firmFormHandler } = useInviteForm();

  props.type = 'firmEdit';

  return (
    <>
      <Typography className="mb-4 text-xl font-bold tracking-[0.1px]">
        {translate('global.editFirm')}
      </Typography>

      <SubcontractorsFormBody {...props} params={firmForm} handler={firmFormHandler} />
    </>
  );
}
