import { FC } from 'react';

import { LabelText } from '@/shared/components/form/LabelText';
import { classnames } from '@/shared/utils/classnames';

import { WithChildren } from '@/shared/types/common.types';

type Props = WithChildren & {
  className?: string;
  labelText?: string | JSX.Element;
  inline?: boolean;
};

export const Label: FC<Props> = ({ children, className, labelText, inline = false }) => {
  return (
    <label
      className={classnames('relative w-full', className, {
        'inline-flex items-center gap-x-3': inline,
      })}
    >
      {labelText && <LabelText inline={inline} labelText={labelText} />}

      {children}
    </label>
  );
};
