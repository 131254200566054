import { Avatar } from '@/shared/components/ui/UserMenu/Avatar';
import { Badge } from '@/shared/components/ui/Badge';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useUIStore } from '@/shared/store/ui';

import { UserProps } from '@/shared/types/user.types';
import { userStorageService } from '@/shared/services/user-storage.service';

export const User = ({ user }: { user: UserProps | null }) => {
  const site = useUIStore(state => state.site);
  const sitesList = useUIStore(state => state.sitesList);
  const localStorageAccountName = userStorageService.getAccountName();
  const accountName = localStorageAccountName?.split('-')[0] ?? '';

  const currentSite = sitesList.find(item => item.siteResName === site);

  if (!user) return null;

  return (
    <>
      <Avatar src={null} size="md" aria-label="user-personal-avatar" />

      <div className="flex h-full flex-col items-start justify-between md:hidden xl:flex">
        <div className="flex items-center gap-x-1">
          <Typography
            className="truncate text-sm font-medium text-white first-letter:capitalize md:max-w-28"
            aria-label="user-personal-information"
          >
            {user?.firstName} {user?.lastName}
          </Typography>

          <Badge variant="solid" className="px-2 capitalize" aria-label="user-personal-role">
            {translate(`roles.${user?.role}`)}
          </Badge>
        </div>

        <Typography
          className="truncate text-xs font-medium text-white/50 md:max-w-36"
          aria-label="selected-site-name"
        >
          {accountName.charAt(0).toUpperCase() + accountName.slice(1)}
        </Typography>

        <Typography
          className="text-xs font-medium text-white/50"
          as="span"
          aria-label="selected-floor-name"
        >
          {currentSite?.siteName}
        </Typography>
      </div>
    </>
  );
};
