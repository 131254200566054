import { LocaleEnum } from '@/shared/types/i18n.types';

export const languages = [
  { id: LocaleEnum.EN, title: 'English', name: 'English - US' },
  {
    id: LocaleEnum.JPN,
    title: '日本語',
    name: 'Japanese - 日本語',
  },
];
