import { FC } from 'react';

import { WithChildren, WithClassName } from '@/shared/types/common.types.ts';
import { classnames } from '@/shared/utils/classnames.ts';

export const Main: FC<WithChildren & WithClassName> = ({ children, className }) => {
  return (
    <main className={classnames('flex-1 overflow-auto', className)} role="main">
      {children}
    </main>
  );
};
