import { FC } from 'react';

import { Badge } from '@/shared/components/ui/Badge';
import { TEST_ID } from '@/shared/utils/constants';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';

import {
  accordionIcons,
  AccordionItemProps,
  AccordionProps,
  SummaryTitleEnum,
  SummaryTitleProps,
} from '@/shared/components/ui/Accordion/Accordion.types';
import ChevronIcon from '@/assets/icons/chevron.svg?react';

const AccordionComponent: FC<AccordionProps> = ({ children, open = false, className }) => {
  return (
    <details className={classnames('relative w-full bg-inherit', className)} open={open}>
      {children}
    </details>
  );
};

const AccordionSummary: FC<AccordionItemProps> = ({
  children,
  className,
  iconClassName,
  handleClick,
}) => {
  const commonStyles = 'pointer-events-none absolute left-0 top-2 h-5 w-5 bg-slate-50';

  return (
    <>
      <summary //NOSONAR
        onClick={handleClick}
        onKeyUp={() => {}}
        className={classnames(
          'flex cursor-pointer items-center gap-x-2 py-2 pl-7 text-sm font-semibold text-black',
          className,
        )}
      >
        <ChevronIcon className={classnames(commonStyles, iconClassName)} />
        {children}
      </summary>

      <ChevronIcon className={classnames(`${commonStyles} rotate-180`, iconClassName)} />
    </>
  );
};

const AccordionDetails: FC<AccordionItemProps> = ({ children, className }) => {
  return <div className={classnames(className)}>{children}</div>;
};

const AccordionSummaryTitle: FC<SummaryTitleProps> = ({
  className,
  icon,
  count,
  showing,
  withShowing = true,
  withCount = true,
}) => {
  const Icon = accordionIcons[icon];

  return (
    <span className={classnames('flex w-full items-center justify-between', className)}>
      <span className="flex items-center gap-x-1">
        <Icon
          className={classnames('h-5 w-5', {
            'text-red-500': icon === SummaryTitleEnum.RED_ZONE,
            'text-emerald-500': icon === SummaryTitleEnum.WORK_ZONE,
            'text-slate-400': icon === SummaryTitleEnum.INACTIVE,
          })}
          data-testid={`${icon}-${TEST_ID.ACCORDION_TITLE_ICON}`}
        />

        <Typography className="text-sm font-semibold capitalize" as="span">
          {icon === SummaryTitleEnum.EQUIPMENT && translate('global.equipment')}

          {icon === SummaryTitleEnum.WORKER && translate('global.workers')}

          {icon === SummaryTitleEnum.ZONE && translate('global.geofences')}

          {icon === SummaryTitleEnum.READERS && translate('global.readers')}

          {icon === SummaryTitleEnum.RED_ZONE && translate('sidebar.redZones')}

          {icon === SummaryTitleEnum.WORK_ZONE && translate('sidebar.workZone')}

          {icon === SummaryTitleEnum.INACTIVE && translate('sidebar.inactive')}
        </Typography>

        {withCount && (
          <Badge variant="solid" className="counterAnimation">
            {count}
          </Badge>
        )}
      </span>

      {withShowing && (
        <span className="flex items-center gap-x-1">
          <Typography className="text-sm font-normal text-slate-600" as="span">
            {translate('global.showing')}:
          </Typography>

          <Badge variant="outline">{showing}</Badge>
        </span>
      )}
    </span>
  );
};

export const Accordion = Object.assign(AccordionComponent, {
  Summary: AccordionSummary,
  Details: AccordionDetails,
  SummaryTitle: AccordionSummaryTitle,
});
