import PinInput from 'react-pin-input';

type CodeInputProps = {
  onChange: (value: string) => void;
};

export const CodeInput = ({ onChange }: CodeInputProps) => {
  return (
    <PinInput
      length={6}
      initialValue=""
      onChange={onChange}
      type="numeric"
      inputMode="number"
      style={{ padding: '10px' }}
      inputStyle={{
        borderColor: '#e2e8f0',
        borderWidth: '1px',
        borderRadius: '8px',
        fontSize: '20px',
        width: '50px',
        height: '50px',
        margin: '5px',
      }}
      inputFocusStyle={{ borderColor: '#3b82f6' }}
      focus={true}
      autoSelect={true}
      onComplete={onChange}
    />
  );
};
