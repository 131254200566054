import { MISSING_DATA } from '@/shared/utils/constants';
import { generateId } from '@/shared/utils/helpers';

import { SubcontractorWorkersDatasource } from '@/shared/types/subcontractors.types';

const missingDataGenerator = (): SubcontractorWorkersDatasource => ({
  id: generateId(),
  name: MISSING_DATA,
  timeOnLocation: MISSING_DATA,
  equipment: MISSING_DATA,
  zone: MISSING_DATA,
});

export const subcontractorWorkersList: SubcontractorWorkersDatasource[] = [
  ...Array.from({ length: 10 }).map(missingDataGenerator),
  {
    id: 'asset-test-subcontractor',
    name: 'Filter User',
    timeOnLocation: new Date().toUTCString(),
    equipment: 'Filter Equipments',
    zone: 'Filter Zone',
  },
  ...Array.from({ length: 89 }).map(missingDataGenerator),
];
