import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useEffect } from 'react';
import Intercom, { boot, onHide, shutdown, show } from '@intercom/messenger-js-sdk';
import { useIntercomFeatureFlag } from '@/shared/feature-flags/useIntercomFeatureFlag';

export function useIntercomInitialize() {
  const { user } = useFetchUserData();
  const { intercomImplementation } = useIntercomFeatureFlag();

  const credential = {
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: user?.userResourceName,
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    custom_attributes: { role: user?.role },
  };

  useEffect(() => {
    if (user?.firstName && intercomImplementation?.enabled) {
      Intercom(credential);
    }
  }, [user, intercomImplementation]);

  const handleBootIntercom = () => {
    boot(credential);
    show();
    onHide(() => {
      shutdown();
    });
  };

  return { handleBootIntercom, intercomImplementation };
}
