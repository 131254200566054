import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { ActionsAPINamespace } from '@/shared/api/actions/actions.requests';
import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { translate } from '@/i18n';
import { useAlertsStore } from '@/shared/store/actions';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { useGlobalStore } from '@/shared/store/global';
import { RoutesEnum } from '@/shared/types/enums.ts';
import { AxiosError } from 'axios';

interface UseActionActionsProps {
  actionResName: string;
  assetResName?: string;
}

export const useActionCardActions = ({ actionResName, assetResName }: UseActionActionsProps) => {
  const [archiving, setArchiving] = useState(false);

  const navigate = useNavigate();

  const assets = useGlobalStore(store => store.assets);
  const siteResourceName = useUIStore(store => store.site);
  const moveAlertDataToArchive = useAlertsStore(store => store.moveAlertToArchive);
  const setMapFilter = useUIStore(store => store.setMapFilter);

  const archiveMutation = useMutation({
    mutationKey: [QUERY_KEY.ARCHIVE_ACTION, actionResName],
    mutationFn: async () => {
      return ActionsAPINamespace.archiveAlert(
        user!.accountResourceName!,
        siteResourceName,
        user!.userResourceName!,
        actionResName,
      );
    },
    onMutate: () => {
      setArchiving(true);
    },
    onSuccess: async () => {
      notifyService.success(translate('messages.alertArchiveSuccess'));
      moveAlertDataToArchive(actionResName);
    },
    onError: error => {
      console.error(error);

      if (error instanceof AxiosError && error.response?.status === 422) {
        notifyService.success(translate('messages.alertArchiveSuccess'));
        moveAlertDataToArchive(actionResName);
      } else {
        notifyService.error(translate('errors.archiveAlertFailed'));
      }
    },
    onSettled: () => {
      setArchiving(false);
    },
  });

  const { user } = useFetchUserData();

  const openDetailsPage = () => {
    const asset = assets.find(asset => asset.assetResName === assetResName);

    if (asset) {
      setMapFilter({ resName: [asset?.assetResName] });
      navigate(RoutesEnum.HOME);
    } else {
      notifyService.error(translate('errors.noResults'));
    }
  };

  return {
    archiving,
    archiveAction: archiveMutation.mutate,
    openDetailsPage,
  };
};
