import { Tab } from '@headlessui/react';

import { CustomHorizontalTab } from '@/shared/components/ui/CustomHorizontalTabs/CustomHorizontalTabs.tsx';
import { SubcontractorsFormBody } from '@/shared/components/ui/Modal/Subcontractor/FormBody.tsx';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useInviteForm } from '@/shared/components/ui/Modal/Subcontractor/hooks/useInviteForm.ts';

export function InviteSubcontractor() {
  const {
    props: params,
    personForm,
    firmForm,
    firmFormHandler,
    personFormHandler,
  } = useInviteForm();

  const tabs = [{ name: 'global.firm' }, { name: 'global.person' }];

  const renderTitle = () => {
    switch (params.type) {
      case 'invite':
        return translate('global.invite');
      case 'firmEdit':
        return translate('global.editFirm');
      case 'workerEdit':
        return translate('global.editWorker');
    }
  };

  return (
    <div className="space-y-4">
      <Typography as="strong" className="text-xl font-bold">
        {renderTitle()}
      </Typography>

      <CustomHorizontalTab tabs={tabs} setTabMode={value => params.setMode(value)}>
        <Tab.Panel>
          <SubcontractorsFormBody {...params} params={firmForm} handler={firmFormHandler} />
        </Tab.Panel>

        <Tab.Panel>
          <SubcontractorsFormBody {...params} params={personForm} handler={personFormHandler} />
        </Tab.Panel>
      </CustomHorizontalTab>
    </div>
  );
}
