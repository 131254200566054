import { useSearchParams } from 'react-router-dom';

import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

export const useClearSiteData = () => {
  const [, setSearchParameters] = useSearchParams();
  const setFloor = useUIStore(state => state.setFloor);
  const setFloorsList = useUIStore(state => state.setFloorsList);
  const setWorkers = useGlobalStore(store => store.setWorkers);
  const setWorkersInUse = useGlobalStore(store => store.setWorkersInUse);
  const setEquipment = useGlobalStore(store => store.setEquipment);
  const setEquipmentInUse = useGlobalStore(store => store.setEquipmentInUse);
  const setGeofences = useGlobalStore(state => state.setGeofences);

  const clearSiteData = () => {
    setFloor(null);
    setFloorsList([]);
    setGeofences([]);
    setWorkers([]);
    setWorkersInUse([]);
    setEquipment([]);
    setEquipmentInUse([]);
    setSearchParameters(new URLSearchParams());
  };

  return { clearSiteData };
};
