import { baseQuery, signRequest } from '@/shared/api/utils';

import { AssetsFullDetailsRequest } from '@/shared/api/assets/assets.types.ts';
import { AssetCategory, AssetsResponse } from '@/shared/types/assets.types';

export namespace AssetsAPINamespace {
  const client = baseQuery('ASSETS');

  export const getAllWithDetails = async ({
    accountResName,
    siteResName,
    params = {},
  }: AssetsFullDetailsRequest): Promise<AssetsResponse> => {
    let url = `/accounts/${accountResName}/sites/${siteResName}/assets/full-details`;

    if (Object.keys(params).length > 0) {
      const queryParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            queryParams.append(key, String(item));
          });
        } else if (value !== undefined) {
          queryParams.append(key, String(value));
        }
      });

      url += `?${queryParams.toString()}`;
    }

    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAllCategoryTress = async (
    accountResName: string,
    siteResName: string,
  ): Promise<AssetCategory[]> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/category-trees`;
    const signedRequest = await signRequest('ASSETS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
