import { baseQuery, signRequest } from '@/shared/api/utils';

import { ReadersGetPayload } from '@/shared/api/readers/readers.types.ts';
import { MapDataRequest, MapSignedURLRequest, SiteMap } from '@/shared/api/maps/maps.types.ts';

export namespace MapsAPINamespace {
  const client = baseQuery('MAPS');

  export const getAll = async ({
    accountResourceName,
    siteResourceName,
  }: ReadersGetPayload): Promise<SiteMap[]> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/maps`;

    const signedRequest = await signRequest('MAPS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data.results;
  };

  export const getBucketObject = async ({
    accountResourceName,
    siteResourceName,
    mapResourceName = 'map-66089d76fe384415ad1cf1f492585b56',
  }: MapSignedURLRequest): Promise<Response> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/maps/${mapResourceName}/data/download/s3-object?mapFormat=dae&deployment=alpha`;

    const signedRequest = await signRequest('MAPS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getSignedURL = async ({
    accountResourceName,
    siteResourceName,
    mapResourceName,
  }: MapSignedURLRequest): Promise<string> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/maps/${mapResourceName}/data/download/pre-signed-url?mapFormat=zip&deployment=alpha`;

    const signedRequest = await signRequest('MAPS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  const MAPS_CACHE_KEY = 'MAPS';
  export const getZipFile = async ({ mapSignedUrl }: MapDataRequest): Promise<ArrayBuffer> => {
    // Because the URL signature changes every request we need to manually
    // manage caching the response and sending the 'If-Modified-Since' header
    const requestWithoutSignature = new Request(mapSignedUrl.split('?')[0]);
    const cache = await window.caches.open(MAPS_CACHE_KEY);
    const cachedResponse = await cache.match(requestWithoutSignature);
    const lastModified = cachedResponse?.headers.get('last-modified') ?? '';
    const response = await fetch(mapSignedUrl, {
      headers: {
        'If-Modified-Since': lastModified,
      },
    });
    if (cachedResponse && response.status === 304) {
      return await cachedResponse.arrayBuffer();
    }
    await cache.put(requestWithoutSignature, response.clone());
    return await response.arrayBuffer();
  };
}
