import { FC } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { Badge } from '@/shared/components/ui/Badge';
import { Loader } from '@/shared/components/ui/Loader';
import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';
import { useActionsCounter } from '@/shared/hooks/actions/useActionsCounter.ts';
import { useClearStateData } from '@/shared/hooks/global/useClearStateData.ts';

import { icons, NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types';

export const NavLink: FC<NavLinkProps> = ({
  name,
  path,
  icon,
  withCounter,
  hasNewEvent,
  disabled,
  onClick,
}) => {
  const { setSidebarFilterValue, clearLocationHistory } = useClearStateData();
  const { counts, isActionsDataLoading } = useActionsCounter();

  const Icon = icons[icon];

  return (
    <Link
      className={({ isActive }) =>
        classnames(
          'group relative inline-flex w-full items-center gap-x-1 rounded-lg px-3 py-2 text-base font-normal tracking-wider text-white transition-colors duration-300 hover:bg-blue-500 focus:border-none md:flex-col md:px-1 md:py-0 md:text-[12px] md:text-sm md:focus:bg-blue-500 lg:flex-row lg:px-3 lg:py-2 lg:text-sm lg:font-medium xl:px-2',
          isActive ? 'pointer-events-none bg-blue-500' : 'bg-transparent',
          disabled && 'pointer-events-none select-none opacity-50',
          hasNewEvent && 'animate-pulse duration-1000',
        )
      }
      to={path}
      aria-label={`navbar-${name}-action`.toLowerCase()}
      data-testid="nav-link"
      onClick={() => {
        setSidebarFilterValue('');
        clearLocationHistory();
        onClick && onClick();
      }}
    >
      <Icon className="navLinkAnimation size-6" aria-hidden="true" />

      {translate(name)}

      {withCounter && (
        <Badge
          className="counterAnimation ml-auto inline-flex min-h-4 min-w-6 items-center justify-center md:absolute md:-right-4 md:-top-1.5 md:ml-0"
          variant="solid"
          color="red"
        >
          {isActionsDataLoading ? <Loader appearance="ping" /> : counts.forBadge}
        </Badge>
      )}
    </Link>
  );
};
