import { useIsFetching } from '@tanstack/react-query';

import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { useGlobalStore } from '@/shared/store/global';

export const useMapModelState = () => {
  const isGeofenceMapLoading = useGlobalStore(store => store.isGeofenceMapBlobUrlLoading);

  const isGeofencesLoading = useIsFetching({
    queryKey: [QUERY_KEY.GEOFENCES_GET],
  });

  const isLocationsHistoryLoading = useIsFetching({
    queryKey: [QUERY_KEY.LOCATION_HISTORY],
  });

  const isEquipmentsLoading = useIsFetching({
    queryKey: [QUERY_KEY.EQUIPMENTS_GET],
  });

  const isWorkersLoading = useIsFetching({
    queryKey: [QUERY_KEY.ZPS_USERS],
  });

  const isMapLoading = useIsFetching({
    queryKey: [QUERY_KEY.MAPS],
  });

  return {
    isLocationsHistoryLoading: isLocationsHistoryLoading !== 0,
    isAssetsOrGeofencesLoading:
      isWorkersLoading !== 0 || isEquipmentsLoading !== 0 || isGeofencesLoading !== 0,
    isMapLoading: isMapLoading !== 0 || isGeofenceMapLoading,
  };
};
