import { ChangeEvent, FC, useRef } from 'react';
import { useClickAway, useMedia, useToggle } from 'react-use';

import { Button } from '@/shared/components/buttons/Button';
import { Checkbox } from '@/shared/components/form/Checkbox';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';

import { MapFilterPropEnum } from '@/shared/types/filters.types';
import ChevronUpDownIcon from '@/assets/icons/select-arrow.svg?react';
import { useGlobalStore } from '@/shared/store/global';
import { useMapFilter } from '@/shared/components/ui/Modal/MapFilter/hooks/useMapFilter.ts';
import { RolesEnum } from '@/shared/types/enums.ts';
import { GeofenceCategoryEnum } from '@/shared/types/geofences.types.ts';

type FilterSelectProps = {
  name: MapFilterPropEnum;
  className?: string;
  asSelect?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  assetsWithTags?: boolean;
  longText?: boolean;
};

export const FilterSelect: FC<FilterSelectProps> = ({
  name,
  value,
  className,
  onChange,
  asSelect,
  assetsWithTags,
  longText = true,
}: FilterSelectProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const geofences = useGlobalStore(state => state.geofences);
  const assets = useGlobalStore(state => state.assets);

  const { filterOptions } = useMapFilter();
  const [isOpen, setIsOpen] = useToggle(false);

  const options = filterOptions[name];

  const renderSortedOptions = () => {
    if (name === MapFilterPropEnum.ZONE_ITEM) {
      const sortedGeofences = geofences
        .filter(geofence => (options as string[]).includes(geofence.geofenceResName))
        .sort((a, b) => a.geofenceName.localeCompare(b.geofenceName));

      return sortedGeofences.map(geofence => geofence.geofenceResName ?? '-');
    } else if (name === MapFilterPropEnum.ASSET_ITEM) {
      const filteredAssets = assets.filter(asset => {
        const currentTagProperty = asset.extraProperties?.find(property => property.key === 'tag');

        return !!currentTagProperty;
      });

      const result = assetsWithTags ? filteredAssets : assets;

      const sortedAssets = result
        .filter(asset => (options as string[]).includes(asset.assetResName))
        .sort((a, b) => a.assetName.localeCompare(b.assetName));

      return sortedAssets.map(asset => asset.assetResName ?? '-');
    } else return options;
  };

  useClickAway(dropdownRef, () => setIsOpen(false));
  const isDeepMobile = useMedia('(max-width: 475px)');

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      const newValues = [...value, name];
      onChange(newValues);
    } else {
      const newValues = value.filter(item => item !== name);
      onChange(newValues);
    }
  };

  const renderButtonText = () => {
    if (value.length > 0) {
      if (name === MapFilterPropEnum.ZONE_ITEM) {
        const modifiedGeofences = value.map(
          item => geofences.find(geofence => geofence.geofenceResName === item)?.geofenceName,
        );
        const checkedModifiedGeofences = modifiedGeofences.filter(item => !!item);

        return checkedModifiedGeofences.join(', ');
      } else if (name === MapFilterPropEnum.ASSET_ITEM) {
        const modifiedAssets = value.map(
          item => assets.find(asset => asset.assetResName === item)?.assetName,
        );
        const checkedModifiedAssets = modifiedAssets.filter(item => !!item);

        return checkedModifiedAssets.join(', ');
      }

      return value.join(', ');
    } else if (value.length <= 0) {
      if (name === MapFilterPropEnum.ZONE_ITEM) return translate('filters.zone');

      if (name === MapFilterPropEnum.ASSET_ITEM) return translate('global.asset');
    } else if (asSelect) return translate('auth.select');

    const translateKey = `filters.${name.split('.').reverse()[0]}`;
    return translate(translateKey);
  };

  const renderOptionLabel = (
    option: string | RolesEnum | GeofenceCategoryEnum,
    name: MapFilterPropEnum,
  ) => {
    if (name === MapFilterPropEnum.ZONE_ITEM) {
      const matchedGeofenceItem = geofences.find(geofence => geofence.geofenceResName === option);
      return matchedGeofenceItem?.geofenceName;
    }

    if (name === MapFilterPropEnum.ASSET_ITEM) {
      const matchedAssetItem = assets.find(asset => asset.assetResName === option);
      return matchedAssetItem?.assetName;
    }

    return option;
  };

  const renderCheckbox = (
    option: string | RolesEnum | GeofenceCategoryEnum,
    name: MapFilterPropEnum,
  ) => (
    <Checkbox
      key={option}
      onChange={event => handleCheckboxChange(event, option)}
      checked={value.includes(option)}
    >
      <Typography
        className={classnames('flex-1 text-left text-sm font-medium first-letter:capitalize', {
          truncate: !longText,
          'break-anywhere': longText,
        })}
      >
        {renderOptionLabel(option, name)}
      </Typography>
    </Checkbox>
  );

  return (
    <div
      className={classnames('relative', {
        'w-full': isDeepMobile,
      })}
    >
      <Button
        className={classnames(
          'disabled:hover-none inline-flex w-full items-center justify-between rounded-lg border border-slate-200 px-4 py-2 pr-2.5 text-left text-sm font-normal text-slate-500 transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50',
          isOpen && 'pointer-events-none',
          isDeepMobile && 'w-full min-w-full',
          className,
        )}
        variant="custom"
        onClick={setIsOpen}
        disabled={options.length === 0}
      >
        <Typography
          className={classnames('', {
            truncate: !longText,
            'break-anywhere': longText,
          })}
        >
          {renderButtonText()}
        </Typography>

        <ChevronUpDownIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
      </Button>

      {isOpen && (
        <div
          className="filterSectionAnimation absolute left-0 z-50 mt-2 grid max-h-80 w-full origin-top-left overflow-y-auto rounded-lg border border-slate-200 bg-white p-3 shadow-lg"
          ref={dropdownRef}
        >
          {renderSortedOptions()?.map(option => renderCheckbox(option, name))}
        </div>
      )}
    </div>
  );
};
