import { useGlobalStore } from '@/shared/store/global';

import { AssetBody } from '@/shared/types/assets.types.ts';

export const useAssetsCategories = (equipment?: AssetBody) => {
  const assetsCategories = useGlobalStore(state => state.assetsCategories);

  const filteredAssetsCategories = assetsCategories.filter(category =>
    category.categoryTreeName.includes('Equipment'),
  );

  const currentCategory = filteredAssetsCategories.find(
    category => category.categoryTreeResName === equipment?.categoryTreeResName,
  );
  const currentCategoryPath = currentCategory?.categoryPathList.find(
    category => category.categoryPathResName === equipment?.categoryPathResName,
  );

  const renderInitialData = () => {
    const result: Record<string, string> = {};

    for (const property of equipment?.extraProperties ?? []) {
      if (!result[property.key as string]) {
        result[property.key as string] = property.value as string;
      }
    }

    return result;
  };

  const renderExtraPropertyField = (field: string) => {
    return equipment?.extraProperties?.find(property => property.key === field);
  };

  return {
    assetsCategories,
    filteredAssetsCategories,
    currentCategory,
    currentCategoryPath,
    renderInitialData,
    renderExtraPropertyField,
  };
};
