import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';

import { WithClassName } from '@/shared/types/common.types';
import Logo from '@/assets/icons/brand.svg?react';
import BlueLogo from '@/assets/icons/brand-blue.svg?react';

type Props = WithClassName & {
  color?: 'blue' | 'white';
};

export const SiteLogo: FC<Props> = ({ className, color }) => {
  if (color === 'blue') return <BlueLogo className={classnames('h-[33px] w-[140px]', className)} />;

  return <Logo className={classnames(className)} />;
};
