import { useMemo } from 'react';

import { MAX_COUNTER } from '@/shared/utils/constants.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { useModifyAlertsData } from '@/modules/Actions/hooks/useModifyAlertsData.ts';
import { ActionCategoryEnum, AlertProps, MetricProps } from '@/shared/types/actions.types.ts';
import { useGlobalStore } from '@/shared/store/global';
import { AssetBody } from '@/shared/types/assets.types.ts';
import { findTagValue } from '@/shared/utils/assets.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { RolesEnum } from '@/shared/types/enums.ts';
import { UserChildProps } from '@/shared/types/user.types.ts';

export const useActionsCounter = () => {
  const { user } = useFetchUserData();
  const assets = useGlobalStore(store => store.assets);
  const alertsData = useAlertsStore(store =>
    store.alertsData.filter(action => actionHasAsset(action, assets, user)),
  );
  const archivedAlertsData = useAlertsStore(store =>
    store.archivedAlertsData.filter(action => actionHasAsset(action, assets, user)),
  );
  const metricsData = useAlertsStore(store =>
    store.metricsData.filter(action => actionHasAsset(action, assets, user)),
  );
  const isAlertsDataLoading = useAlertsStore(store => store.isAlertsDataLoading);
  const isMetricsDataLoading = useAlertsStore(store => store.isMetricsDataLoading);

  const actionsData = { alertsData, metricsData };

  const countForBadge = useMemo(() => {
    const totalData = [...alertsData, ...metricsData];
    if (totalData.length > MAX_COUNTER) return `${MAX_COUNTER}+`;
    return totalData.length.toString();
  }, [alertsData, metricsData]);

  const filterAlerts = (category: ActionCategoryEnum) => {
    return actionsData.alertsData.filter(item => item.alertCategory === category);
  };

  const { modifiedAlertsData } = useModifyAlertsData();

  const metricsResult = modifiedAlertsData(actionsData.metricsData) ?? [];
  const systemAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.SYSTEM)) ?? [];
  const safetyAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.SAFETY)) ?? [];
  const equipmentAlertsResult =
    modifiedAlertsData(filterAlerts(ActionCategoryEnum.EQUIPMENT)) ?? [];
  const zoneAlertsResult = modifiedAlertsData(filterAlerts(ActionCategoryEnum.ZONE)) ?? [];
  const archivedAlertsResult = modifiedAlertsData(archivedAlertsData) ?? [];

  const counts = {
    all: alertsData.length + metricsData.length,
    equipment: equipmentAlertsResult.length,
    forBadge: countForBadge,
    safety: safetyAlertsResult.length,
    system: systemAlertsResult.length,
    zones: zoneAlertsResult.length,
    archived: archivedAlertsData.length,
  };

  const isActionsDataLoading = isAlertsDataLoading || isMetricsDataLoading;

  const hasPriority = actionsData?.alertsData.length > 0;

  const isActionsDataEmpty =
    actionsData.alertsData.length <= 0 && actionsData.metricsData.length <= 0;

  return {
    actionsData,
    counts,
    equipmentAlertsResult,
    hasPriority,
    isActionsDataLoading,
    isActionsDataEmpty,
    metricsResult,
    systemAlertsResult,
    safetyAlertsResult,
    zoneAlertsResult,
    archivedAlertsResult,
  };
};

function actionHasAsset(
  action: AlertProps | MetricProps,
  assets: AssetBody[],
  user?: Partial<UserChildProps> | null,
) {
  const itemIsAlert =
    Object.prototype.hasOwnProperty.call(action, 'alertResName') ||
    Object.prototype.hasOwnProperty.call(action, 'alertName');

  if (itemIsAlert) {
    const alert = action as AlertProps;

    if (alert.alertCategory === ActionCategoryEnum.EQUIPMENT && user?.role === RolesEnum.KOTO) {
      return false;
    }

    return assets.some(asset => {
      return (
        asset.assetResName === alert.assetResName || alert.deviceResName === findTagValue(asset)
      );
    });
  } else {
    // TODO: Change logic during full metrics implementation
    return false;
  }
}
