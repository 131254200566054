import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { SiteOperationsAPINamespace } from '@/shared/api/site-operations';

export const useGetSitePolicies = () => {
  const uiStore = useUIStore();
  const site = useUIStore(state => state.site);

  const { user } = useFetchUserData();

  const getSitePolicies = async () => {
    const sitePolicies = await SiteOperationsAPINamespace.getSitePolicies({
      accountResName: user?.accountResourceName as string,
      siteResName: site,
    });

    uiStore.setSitePolicies(sitePolicies);

    return sitePolicies;
  };

  return useQuery({
    queryKey: [QUERY_KEY.SITE_POLICIES_GET, user?.accountResourceName, user?.userResourceName],
    queryFn: getSitePolicies,
    enabled: !!user?.accountResourceName && !!user?.userResourceName,
  });
};
