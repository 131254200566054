import { createQueryKeys } from '@lukemorales/query-key-factory';

export const geofencesKeys = createQueryKeys('analytics', {
  // [QUERY_KEY.GEOFENCES_GET, site, floor, isHistoricalMode],
  all: (site: string, floor: string, isHistoricalMode: boolean) => [
    'get-geofences',
    site,
    floor,
    isHistoricalMode,
  ],
  create: null,
  update: null,
  delete: null,
});
