export type AllSettingsRequest = {
  accountResName: string;
  userResName: string;
  entityType?: EntityTypeEnum;
};

export enum EntityTypeEnum {
  User = 'user',
  Account = 'account',
  Organization = 'organization',
  UserGroup = 'usergroup',
}

export type AllSettingsResponse = {
  page: SettingsPageProps;
  results: SettingsResultProps[];
};

export type CreateSettingsRequest = {
  accountResName: string;
  userResName: string;
  entityType?: EntityTypeEnum;
  settingsName: SettingsNameEnum;
  extraProperties: ExtraPropertyProps[];
};

export type CreateSettingsMutationProps = {
  entityType?: EntityTypeEnum;
  settingsName: SettingsNameEnum;
  extraProperties: ExtraPropertyProps[];
};

export type UpdateSettingsMutationProps = {
  entityType?: EntityTypeEnum;
  settingsResName: string;
  settingsName: SettingsNameEnum;
  extraProperties: ExtraPropertyProps[];
};

export type UpdateSettingsRequest = {
  accountResName: string;
  userResName: string;
  entityType: EntityTypeEnum;
  settingsResName: string;
  settingsName: SettingsNameEnum;
  extraProperties: ExtraPropertyProps[];
};

export enum SettingsKeyEnum {
  ArchivedAlerts = 'archived-alerts',
  Equipment = 'equipment',
  FilterSettings = 'filter-settings',
  HistoricalFilterSettings = 'historical-filter-settings',
  FloorResName = 'floorResName',
  UserSettings = 'user-settings',
  ViewedZoneList = 'viewedZoneList',
  Worker = 'worker',
  Reader = 'reader',
}

export enum SettingsNameEnum {
  AlertSettings = 'alert-settings',
  HistoricalFilterSettings = 'historical-filter-settings',
  MapFilterSettings = 'map-filter-settings',
  MapSettings = 'map-settings',
  UISettings = 'ui-settings',
  UserSettings = 'user-settings',
}

export type SettingsPageProps = {
  pageItemsCount: number;
  pageStartResPointer: string;
  pageEndResPointer: string;
  hasNext: boolean;
  forward: boolean;
};

export type SettingsResultProps = {
  entityType: EntityTypeEnum;
  entityResName: string;
  settingsResName: string;
  settingsName: SettingsNameEnum;
  extraProperties: ExtraPropertyProps[];
  creationTime: CreationTimeProps;
  lastUpdateTime: LastUpdateTimeProps;
};

export type ExtraPropertyProps = {
  key: SettingsKeyEnum;
  value: string;
};

export type CreationTimeProps = {
  epochTime: number;
};

export type LastUpdateTimeProps = {
  epochTime: number;
};
