import { FC } from 'react';

import { Reader } from '@/modules/Home/components/map/Reader';

import { ReadersProps } from '@/shared/types/readers.types.ts';
import { Vector3NumberProps } from '@/modules/Home/types/MapModel.types';

type Props = {
  readers: ReadersProps[];
};

export const Readers: FC<Props> = ({ readers = [] }) => {
  return (
    <>
      {readers?.map(reader => {
        const positionX = Number(reader?.readerLocation?.x ?? 0);
        const positionY = Number(reader?.readerLocation?.y ?? 0);

        const calculatedPositionX = positionX / 10000;
        const calculatedPositionY = positionY / 10000;

        const position = [calculatedPositionX, 2, calculatedPositionY * -1] as Vector3NumberProps;

        return <Reader key={reader?.readerResName} position={position} />;
      })}
    </>
  );
};
