import { FC, useEffect } from 'react';

import { Fade } from '@/shared/components/animation/Fade';

import { MFASetupForm } from '@/modules/Auth/components/MFASetupForm';
import { useMFALogin } from '@/modules/Auth/hooks/useMFALogin';
import { useLocation } from 'react-router-dom';
import { MFAVerifyForm } from '@/modules/Auth/components/MFAVerifyForm';
import { RoutesEnum } from '@/shared/types/enums';
import { useGlobalStore } from '@/shared/store/global';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const MFASetup: FC = () => {
  const { handleVerifyMFACode, showVerificationForm, setShowVerificationForm, navigate } =
    useMFALogin();

  const location = useLocation();
  const state = location.state as {
    totpURI: string;
    secretCode: string;
    accountName: string;
    username: string;
    rememberMe: boolean;
    termsOfUse: 'done' | 'idle';
    password: string;
    isMFAEnabled: boolean;
  };

  const currentCognitoUser = useGlobalStore(store => store.currentCognitoUser);

  const onSetupFormSubmit = async () => {
    try {
      setShowVerificationForm(true);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const onVerifyFormSubmit = async (code: string) => {
    try {
      handleVerifyMFACode(
        code,
        state.accountName,
        state.rememberMe,
        currentCognitoUser as CognitoUser,
        state.isMFAEnabled,
      );
    } catch (error: unknown) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentCognitoUser) {
      navigate(RoutesEnum.LOGIN);
      return;
    }
    setShowVerificationForm(state.isMFAEnabled);
  }, [currentCognitoUser]);

  const handleCancelSetup = () => navigate(RoutesEnum.LOGIN);
  const handleCancelVerification = () => {
    if (state.isMFAEnabled) {
      navigate(RoutesEnum.LOGIN);
    } else {
      setShowVerificationForm(false);
    }
  };

  return (
    <Fade className="fullHeightWithoutFooter">
      <div className="flex h-full min-h-full items-center justify-center bg-slate-100">
        <div className="grid w-full max-w-[476px] gap-y-5 rounded-2xl border border-slate-200 bg-white px-5 py-[30px]">
          {showVerificationForm ? (
            <MFAVerifyForm
              handleSubmit={onVerifyFormSubmit}
              handleCancel={handleCancelVerification}
            />
          ) : (
            <MFASetupForm
              handleSubmit={onSetupFormSubmit}
              handleCancel={handleCancelSetup}
              totpURI={state.totpURI}
              setupKey={state.secretCode}
            />
          )}
        </div>
      </div>
    </Fade>
  );
};
