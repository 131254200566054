import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { cognitoUserPoolService } from '@/shared/services/cognito-user-pool.service';
import { useAlertsStore } from '@/shared/store/actions';
import { useAuthStatus } from '@/shared/store/auth';
import { useGlobalStore } from '@/shared/store/global';
import { useLocalStore } from '@/shared/store/local';
import { useModalStore } from '@/shared/store/modal';
import { usePopupStore } from '@/shared/store/popup';
import { userStorageService } from '@/shared/services/user-storage.service';

import { GeofenceCategoryEnum } from '@/shared/types/geofences.types';
import { RoutesEnum, StatusEnum } from '@/shared/types/enums';
import { BROADCAST_CHANNEL_NAME, BROADCAST_LOGOUT_KEY } from '@/shared/utils/constants.ts';
import { shutdown } from '@intercom/messenger-js-sdk';
import { ZPSUser } from '@/shared/types/user.types';

export const useClearGlobalStore = () => {
  const [bc] = useState(new BroadcastChannel(BROADCAST_CHANNEL_NAME));
  const navigate = useNavigate();

  const setAuthorized = useAuthStatus(state => state.setAuthorized);
  const closeModal = useModalStore(state => state.setIsClose);
  const closeActivePopups = usePopupStore(state => state.closeActivePopups);
  const setAlertsData = useAlertsStore(state => state.setAlertsData);
  const setMetricsData = useAlertsStore(state => state.setMetricsData);
  const setSocketInstance = useLocalStore(state => state.setSocketInstance);
  const store = useGlobalStore(state => state);

  const {
    setCurrentGeofence,
    setCurrentGeofenceId,
    setEquipment,
    setGeofences,
    setReaders,
    setStatus,
    setWorkers,
    setZPSUser,
  } = store;

  const handleClearGlobalStore = () => {
    closeActivePopups();
    closeModal();
    setAlertsData([]);
    setAuthorized(false);
    setCurrentGeofence([], GeofenceCategoryEnum.INACTIVE);
    setCurrentGeofenceId('');
    setEquipment([]);
    setGeofences([]);
    setMetricsData([]);
    setReaders([]);
    setSocketInstance(null);
    setStatus(StatusEnum.IDLE);
    setWorkers([]);
    shutdown();
    setZPSUser({} as ZPSUser);
    sessionStorage.clear();
    userStorageService.migrateToSession();
    void cognitoUserPoolService.signOut();
    navigate(RoutesEnum.LOGIN);
  };

  const registerLogoutBroadcastListener = () => {
    bc.onmessage = event => {
      if (event.data === BROADCAST_LOGOUT_KEY) {
        handleClearGlobalStore();
      }
    };
  };

  const initiateLogout = () => {
    bc.postMessage(BROADCAST_LOGOUT_KEY);
  };

  return { handleClearGlobalStore, registerLogoutBroadcastListener, initiateLogout };
};
