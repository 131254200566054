import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Mesh, Vector3 } from 'three';

export const useSmoothAnchorPosition = (position: number[]) => {
  const anchorRef = useRef<Mesh>(null);
  const prevAnchorRef = useRef<Mesh | null>(null);

  useFrame(() => {
    const vec = new Vector3(position[0], position[1], position[2]);

    prevAnchorRef.current
      ? anchorRef.current?.position.lerp(vec, 0.01)
      : anchorRef.current?.position.set(vec.x, vec.y, vec.z);

    prevAnchorRef.current = anchorRef.current;
  });

  return {
    anchorRef,
  };
};
