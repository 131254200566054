import { Mesh, Shape, Vector2, Vector3 } from 'three';
import { ZoneShapeType } from '@/modules/Home/types/ZoneEditor.types.ts';
import { forwardRef, useMemo } from 'react';
import {
  ZONE_MESH_COLOR,
  ZONE_MESH_LINE_COLOR,
  ZONE_MESH_OVERLAP_COLOR,
} from '@/modules/Home/utils/ZoneEditor.const.ts';
import { ThreeEvent } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import { zoneOverlapsSelf } from '@/modules/Home/utils/ZoneEditor.utils.ts';

interface OutlinedShapeProps {
  type: ZoneShapeType;
  points: Vector3[];
  onPress?: (event: ThreeEvent<PointerEvent>) => void;
}

interface ShapeMesh {
  rect?: { center: Vector3; size: Vector3 };
  custom?: { position: Vector3; shape: Shape };
  points?: Vector3[];
}

export const OutlinedShape = forwardRef<Mesh, OutlinedShapeProps>(
  ({ type, points, onPress }: OutlinedShapeProps, ref) => {
    const mesh = useMemo(() => {
      const computed: ShapeMesh = {};
      const tl = points[0] ?? null;
      const br = points[1] ?? null;

      if (!tl || !br) return computed;

      switch (type) {
        case ZoneShapeType.RECTANGLE: {
          const size = new Vector3(br.x - tl.x, br.y - tl.y);
          const center = new Vector3(tl.x + 0.5 * size.x, tl.y + 0.5 * size.y);
          const outlinePoints = [
            new Vector3(tl.x, tl.y, tl.z),
            new Vector3(tl.x, br.y, tl.z),
            new Vector3(br.x, br.y, br.z),
            new Vector3(br.x, tl.y, br.z),
            // Last point is same as first point for a closed shape
            new Vector3(tl.x, tl.y, tl.z),
          ];

          computed.rect = { center, size };
          computed.points = outlinePoints;
          break;
        }

        case ZoneShapeType.CUSTOM: {
          const firstPoint = points[0];
          const outlinePoints = [...points];

          if (firstPoint) {
            outlinePoints.push(firstPoint);

            computed.custom = {
              position: firstPoint,
              shape: new Shape(
                points.map(point => {
                  const x = point.x - firstPoint.x;
                  const y = point.y - firstPoint.y;
                  return new Vector2(x, y);
                }),
              ),
            };
          }

          computed.points = outlinePoints;
          break;
        }
      }

      return computed;
    }, [points, type]);

    const { meshColor, meshLineColor, meshLineWidth } = useMemo(() => {
      const overlap = zoneOverlapsSelf(points);

      return {
        meshColor: overlap ? ZONE_MESH_OVERLAP_COLOR : ZONE_MESH_COLOR,
        meshLineColor: overlap ? ZONE_MESH_OVERLAP_COLOR : ZONE_MESH_LINE_COLOR,
        meshLineWidth: overlap ? 2 : 1,
      };
    }, [points]);

    const handlePointerDown = (event: ThreeEvent<PointerEvent>) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onPress?.(event);
    };

    return (
      <>
        {mesh.rect && (
          <mesh
            ref={ref}
            position={mesh.rect.center} //NOSONAR
            onPointerDown={handlePointerDown}
          >
            <boxGeometry
              args={[mesh.rect.size.x, mesh.rect.size.y, mesh.rect.size.z]} //NOSONAR
            />
            <meshStandardMaterial
              color={meshColor}
              opacity={0.25}
              transparent //NOSONAR
            />
          </mesh>
        )}

        {mesh.custom && (
          <mesh
            ref={ref}
            position={mesh.custom.position} //NOSONAR
            onPointerDown={handlePointerDown}
          >
            <shapeGeometry
              args={[mesh.custom.shape]} //NOSONAR
            />
            <meshStandardMaterial
              color={meshColor}
              opacity={0.25}
              transparent //NOSONAR
            />
          </mesh>
        )}

        {mesh.points && (
          <Line points={mesh.points} color={meshLineColor} lineWidth={meshLineWidth} />
        )}
      </>
    );
  },
);
