import { FC, useState } from 'react';
import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { Label } from '@/shared/components/form/Label';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useGlobalStore } from '@/shared/store/global';

import { StatusEnum } from '@/shared/types/enums';
import { QRCodeCanvas } from 'qrcode.react';
import { MFASetupFormProps } from '../../types/types';
import { notifyService } from '@/shared/services/notify.service';

export const MFASetupForm: FC<MFASetupFormProps> = props => {
  const { handleSubmit, handleCancel, totpURI, setupKey } = props;

  const status = useGlobalStore(state => state.status);
  const isLoading = status === StatusEnum.LOADING;
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(setupKey);
      setCopied(true);
      setTimeout(() => notifyService.success(translate('messages.copySuccess')), 2000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SiteLogo className="mx-auto" color="blue" />

      <div className="grid gap-y-1 text-center">
        <Typography className="text-xl font-semibold text-slate-950" as="strong">
          {translate('auth.mfaSetupTitle')}
        </Typography>

        <Typography className="text-sm font-medium text-slate-600">
          {translate('auth.mfaSetupSubtitle')}
        </Typography>
      </div>

      <form className="grid gap-y-3.5" onSubmit={handleSubmit} autoComplete="off">
        <Label className="grid gap-y-2" labelText={translate('auth.mfaSetupLabel')}>
          <div className="inline-flex items-center gap-x-3">
            <Input
              value={setupKey}
              aria-label="mfa-code-textfield"
              autoFocus={true}
              disabled={isLoading}
              onChange={() => null}
            />
            <Button
              variant="outline"
              disabled={copied}
              withIcon
              aria-label="mfa-setup-copy"
              onClick={handleCopy}
            >
              {translate(copied ? 'filters.copied' : 'filters.copy')}
            </Button>
          </div>
        </Label>

        <div className="my-5 flex justify-center">{<QRCodeCanvas value={totpURI} />}</div>

        <div className="grid gap-y-1 text-left">
          <Typography className="text-sm font-medium text-slate-600">
            {translate('auth.mfaSetupInstruction')}
          </Typography>
        </div>
        <div className="inline-flex items-center gap-x-3">
          <Button
            variant="outline"
            disabled={isLoading}
            withIcon
            aria-label="mfa-setup-cancel"
            onClick={handleCancel}
            className="w-[50%]"
          >
            {translate('filters.cancel')}
          </Button>

          <Button
            variant="solid"
            type="submit"
            disabled={isLoading}
            withIcon
            isLoading={isLoading}
            aria-label="mfa-setup-continue"
            className="w-[50%]"
          >
            {translate('filters.next')}
          </Button>
        </div>
      </form>
    </>
  );
};
