import { SettingsService } from '@/shared/services/settings.service.ts';
import { useUserSettingsControlFlag } from '@/shared/feature-flags/useUserSettingsControlFlag.ts';
import { useUserSettingsMutation } from '@/shared/hooks/settings/useUserSettingsMutation.ts';

export const useUserSettings = (
  userSettingsService: SettingsService,
  withLoadingStatus = false,
) => {
  const { create, update, isCreating, isUpdating } = useUserSettingsMutation(withLoadingStatus);

  const currentSetting = userSettingsService.getExistingSetting();
  const settingsKey = userSettingsService.getSettingsKey();
  const settingsName = userSettingsService.getSettingsName();

  const { userSettingsControlFlag } = useUserSettingsControlFlag();

  const handleSaveSettings = async (data: Record<string, unknown>) => {
    if (!userSettingsControlFlag.enabled) return;

    const newProperty = {
      key: settingsKey,
      value: JSON.stringify(data),
    };

    if (currentSetting) {
      const updatedExtraProperties = currentSetting.extraProperties.map(property =>
        property.key === settingsKey
          ? { ...property, value: JSON.stringify({ ...JSON.parse(property.value), ...data }) }
          : property,
      );

      if (!updatedExtraProperties.some(property => property.key === settingsKey)) {
        updatedExtraProperties.push(newProperty);
      }

      const body = {
        entityType: currentSetting.entityType,
        settingsName: currentSetting.settingsName,
        settingsResName: currentSetting.settingsResName,
        extraProperties: [newProperty],
      };

      update(body);
      return;
    }

    const body = {
      settingsName,
      extraProperties: [newProperty],
    };

    create(body);
  };

  return { isCreating, isUpdating, handleSaveSettings };
};
