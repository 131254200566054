export type FormTypes = 'invite' | 'firmEdit' | 'workerEdit';

export const ExtraPropertyKeys = {
  givenName: 'givenName',
  familyName: 'familyName',
  role: 'role',
  groups: 'groups',
  tag: 'tag',
  currentZone: 'currentZone',
  email: 'email',
  phone: 'phone',
  firm: 'firm',
  color: 'color',
  rentalCompany: 'rentalCompany',
  zpsAccess: 'zpsAccess',
  allowedSites: 'allowedSites',
};
