import { useQuery } from '@tanstack/react-query';

import { AssetsAPINamespace } from '@/shared/api/assets';
import { QUERY_KEY } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useGlobalStore } from '@/shared/store/global';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams';
import { useUIStore } from '@/shared/store/ui';

import { AssetBody } from '@/shared/types/assets.types.ts';
import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { UserProps } from '@/shared/types/user.types';
import { useAssetStatus } from './useAssetStatus';
import { ExtraPropertyKeys } from '@/shared/components/ui/Modal/Subcontractor/types';

export const useSetWorkers = () => {
  const setWorkers = useGlobalStore(state => state.setWorkers);
  const setWorkersInUse = useGlobalStore(state => state.setWorkersInUse);
  const site = useUIStore(state => state.site);
  const workerVisibility = useUIStore(state => state.workerVisibility);
  const assetsCategories = useGlobalStore(state => state.assetsCategories);
  const assetsTags = useGlobalStore(state => state.assetsTags);
  const zpsUsers = useGlobalStore(state => state.zpsUsers);
  const sitesList = useUIStore(state => state.sitesList);

  const { user } = useFetchUserData();
  const { isHistoricalMode } = useLocalSearchParams();

  const currentWorkerResourceName = assetsCategories?.find(category =>
    category.categoryTreeName.includes(AssetTypeEnum.Worker),
  );

  const { isShowInactive } = useAssetStatus();

  const { data: workers } = useQuery({
    queryKey: [
      QUERY_KEY.WORKERS,
      user?.accountResourceName,
      site,
      isHistoricalMode,
      workerVisibility,
      currentWorkerResourceName,
      currentWorkerResourceName?.categoryTreeResName,
      user,
      isShowInactive,
      assetsTags,
    ],

    queryFn: async () => {
      if (!currentWorkerResourceName) return [];

      const { results } = await AssetsAPINamespace.getAll(
        user?.accountResourceName as string,
        site,
        currentWorkerResourceName?.categoryTreeResName,
      );

      const assetsWithDetails = await AssetsAPINamespace.getWithDetails(
        results,
        user as UserProps,
        site,
        workerVisibility,
      );

      const dataWithUpdatedTags = assetsWithDetails?.map((asset: AssetBody) => {
        const matchingTag = assetsTags?.find(tag => tag.asset?.assetName === asset.assetName);

        if (matchingTag) {
          return {
            ...asset,
            extraProperties: [
              ...(asset?.extraProperties ?? []),
              { key: 'tag', value: matchingTag.tag.tagResName },
            ],
          };
        }

        return asset;
      });

      const dataWithUpdatedZpsAccess = dataWithUpdatedTags?.map(asset => {
        const zpsAccess =
          zpsUsers.find(
            user =>
              user.status === 'active' &&
              user.emailAddress ==
                asset.extraProperties?.find(p => p.key === ExtraPropertyKeys.email)?.value,
          )?.orgResName || '';

        return {
          ...asset,
          extraProperties: [
            ...(asset?.extraProperties ?? []),
            { key: 'zpsAccess', value: zpsAccess },
          ],
        };
      });

      const dataWithUpdatedAllowedSites = dataWithUpdatedZpsAccess?.map(asset => {
        let allowedSitesArray = [];
        const allowedSites =
          zpsUsers
            .find(
              user =>
                user.status === 'active' &&
                user.emailAddress ==
                  asset.extraProperties?.find(p => p.key === ExtraPropertyKeys.email)?.value,
            )
            ?.extraProperties?.find(p => p.key === 'allowed_sites')?.value || '';
        if (allowedSites) {
          allowedSitesArray = JSON.parse(allowedSites);
          allowedSitesArray = allowedSitesArray.map(
            (site: string) => sitesList.find(s => s.siteResName === site)?.siteName,
          );
        } else {
          // if no allowed sites, it means the user has access to all sites
          allowedSitesArray = sitesList.map(site => site.siteName);
        }

        return {
          ...asset,
          extraProperties: [
            ...(asset?.extraProperties ?? []),
            { key: 'allowedSites', value: allowedSitesArray },
          ],
        };
      });

      const workersInUse = dataWithUpdatedAllowedSites?.filter(asset => {
        const isTagExists = assetsTags?.find(tag => tag.asset?.assetName === asset.assetName);
        return !!isTagExists;
      });

      const workersToShow = dataWithUpdatedAllowedSites?.filter(asset => {
        const show =
          asset.assetStatus === 'active' || (asset.assetStatus === 'inactive' && isShowInactive);
        return !!show;
      });

      setWorkersInUse(workersInUse);
      setWorkers(workersToShow);

      return assetsWithDetails;
    },

    enabled:
      !!user?.accountResourceName && !!site && !!assetsCategories?.length && !!assetsTags?.length,
  });

  return { workers };
};
