import { FC } from 'react';
import { FieldValues, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { Button } from '@/shared/components/buttons/Button';
import { Input, SearchInput } from '@/shared/components/form/Input';
import { Toggle } from '@/shared/components/form/Toggle';
import { Typography } from '@/shared/components/ui/Typography';
import { translate } from '@/i18n';
import { useForm } from '@/shared/components/ui/Modal/Groups/hooks/useForm';

type FormBodyProps = {
  params: UseFormReturn;
  handler: SubmitHandler<FieldValues>;
  type: 'edit' | 'create';
  extraHandler?: () => void;
};

export const GroupsFormBody: FC<FormBodyProps> = ({
  params,
  handler,
  type = 'create',
  extraHandler,
}) => {
  const { register, getValues, setValue, watch, handleSubmit } = params;

  const { closeModal } = useForm();

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return translate('global.editGroup');
      case 'create':
        return translate('global.createGroup');
    }
  };

  const renderActions = () => {
    return (
      <>
        {type === 'edit' && (
          <Button
            variant="solid"
            className="bg-red-500 hover:bg-red-600"
            type="button"
            onClick={extraHandler}
            disabled
          >
            {translate('confirm.delete')}
          </Button>
        )}

        <div className="ml-auto inline-flex gap-x-[10px]">
          <Button
            className="border-slate-200 bg-white text-slate-800 hover:bg-slate-50 hover:text-slate-900 focus:bg-slate-50 focus:text-slate-900"
            variant="outline"
            type="reset"
            onClick={closeModal}
          >
            {translate('filters.cancel')}
          </Button>

          <Button variant="solid" type="submit" disabled>
            {translate('settings.save')}
          </Button>
        </div>
      </>
    );
  };

  watch();

  return (
    <form
      className="flex w-full max-w-md flex-col gap-y-3 pb-[70px]"
      onSubmit={handleSubmit(handler)}
    >
      <Typography className="text-xl font-bold tracking-[0.1px]" as="strong">
        {renderTitle()}
      </Typography>

      <div className="flex flex-col items-start gap-x-3 gap-y-2">
        <Typography className="inline-flex gap-x-0.5 text-start text-sm font-medium">
          {translate('global.groupName')}
        </Typography>

        <div className="inline-flex w-full gap-x-3">
          <Input className="w-full" {...register('title')} />
        </div>
      </div>

      {type === 'edit' && (
        <div className="inline-flex items-center gap-x-3">
          <Toggle
            checked={getValues('isActive')}
            onChange={() => setValue('isActive', !getValues('isActive'))}
          />

          <Typography className="text-sm font-medium">
            {getValues('isActive') ? translate('confirm.activate') : translate('confirm.inactive')}
          </Typography>
        </div>
      )}

      <div className="inline-flex items-center gap-x-3">
        <Toggle
          checked={getValues('isPublic')}
          onChange={() => setValue('isPublic', !getValues('isPublic'))}
        />

        <Typography className="text-sm font-medium">
          {getValues('isPublic')
            ? translate('global.publicGroup')
            : translate('global.privateGroup')}
        </Typography>
      </div>

      <div className="relative inline-flex w-full items-center">
        <SearchInput className="w-full" disabled />
      </div>

      <div className="absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-between gap-x-2 rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2">
        {renderActions()}
      </div>
    </form>
  );
};
