import { FC } from 'react';

import { Anchor } from '@/modules/Home/components/map/Anchor';

import {
  AssetTypeEnum,
  ClustersArray,
  Vector3NumberProps,
} from '@/modules/Home/types/MapModel.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

type Props = {
  anchors: Partial<SocketMapDataProps>[];
  filteredClusters: ClustersArray[];
  offset: number;
};

export const Anchors: FC<Props> = ({ anchors = [], filteredClusters, offset }) => {
  return (
    <>
      {anchors?.map(anchor => {
        const positionX = Number(anchor?.x ?? 0);
        const positionY = Number(anchor?.y ?? 0);

        const position = [positionX, offset, positionY * -1] as Vector3NumberProps;

        // Time interval constants in milliseconds
        const INTERVAL_TIME_DEFAULT = 15 * 60 * 1000; // 15 minutes for equipment
        const INTERVAL_TIME_WORKER = 2 * 60 * 1000; // 2 minutes for workers

        // Determining whether an asset is an employee
        const isWorker = anchor?.categoryTreeName === AssetTypeEnum.Worker;

        // Calculating the interval for an asset
        const intervalTime = isWorker ? INTERVAL_TIME_WORKER : INTERVAL_TIME_DEFAULT;

        // Check if the data is outdated. Use arrivalTime of the websocket data to the browser
        const isOutdated = Date.now() - (anchor?.arrivalTimestamp as number) > intervalTime;

        // Visibility check: if the asset is an employee and the data is outdated, we do not show it
        if (isWorker && isOutdated) {
          return null;
        }

        //  Checking the availability of an asset in filtered clusters
        const isVisible = filteredClusters.some((item: ClustersArray) =>
          item.trackersData ? item.trackersData.includes(anchor) : false,
        );

        return (
          <Anchor
            key={anchor?.tagResName}
            anchor={anchor}
            position={position}
            size={[0.5, 0.5, 0.5]}
            visible={!isVisible}
            active={!isOutdated}
          />
        );
      })}
    </>
  );
};
