import { baseQuery } from '@/shared/api/utils';

import { EquipmentListResponse, EquipmentResponse } from '@/shared/api/zildee/equipment/types';

export namespace EquipmentAPINamespace {
  const client = baseQuery('ZILDEE');

  export const getAll = async (siteResourceName: string): Promise<EquipmentListResponse> => {
    const result = await client.get(`/equipment?site=${siteResourceName}`);
    return result.data;
  };
  export const getOne = async (id: string): Promise<EquipmentResponse> => {
    const result = await client.get(`/equipment/${id}`);
    return result.data;
  };
  export const getReservations = async (id: string): Promise<unknown[]> => {
    const result = await client.get(`/equipment/${id}/reservations`);
    return result.data;
  };
}
