import { FC } from 'react';
import { FaMouse } from 'react-icons/fa';
import { RiShape2Line } from 'react-icons/ri';
import { TbEaseInOutControlPoints } from 'react-icons/tb';

import { Button } from '@/shared/components/buttons/Button';
import { Select } from '@/shared/components/form/Select';
import { Typography } from '@/shared/components/ui/Typography';

import { ZoneShapeType } from '@/modules/Home/types/ZoneEditor.types.ts';
import { translate } from '@/i18n';

interface ZoneEditorHeaderProps {
  shapeType: ZoneShapeType;
  showResetZoneButton?: boolean;
  onTypeChange: (type: ZoneShapeType) => void;
  onResetZoneData?: () => void;
  onCancel: () => void;
}

const selectData = [
  { id: ZoneShapeType.RECTANGLE, name: translate('zoneEditor.rectangle') },
  { id: ZoneShapeType.CUSTOM, name: translate('zoneEditor.custom') },
];

export const ZoneEditorHeader: FC<ZoneEditorHeaderProps> = ({
  shapeType,
  showResetZoneButton,
  onTypeChange,
  onResetZoneData,
  onCancel,
}) => (
  <header className="absolute left-1/2 top-4 z-10 -translate-x-1/2">
    <div className="flex flex-wrap items-center gap-4 rounded-xl border-2 border-gray-100 bg-white px-4 py-3 shadow-xl md:justify-center">
      {shapeType === ZoneShapeType.RECTANGLE && (
        <>
          <FaMouse className="mx-auto text-blue-500 md:mx-0" />

          <Typography className="whitespace-nowrap text-wrap font-medium tracking-wide">
            {translate('zoneEditor.rectHeader')}
          </Typography>
        </>
      )}

      {shapeType === ZoneShapeType.CUSTOM && (
        <>
          <TbEaseInOutControlPoints className="text-blue-500" />

          <div className="flex flex-col">
            <Typography className="whitespace-nowrap font-medium tracking-wide">
              {translate('zoneEditor.customHeader')}
            </Typography>

            <Typography className="whitespace-nowrap text-sm italic text-gray-600" as="span">
              {translate('zoneEditor.customSubHeader')}
            </Typography>
          </div>
        </>
      )}

      <div className="w-full md:w-40">
        <Select
          variant="default"
          className="bg-white"
          data={selectData}
          defaultValue={shapeType}
          onChange={type => onTypeChange(type as ZoneShapeType)}
          icon={<RiShape2Line />}
        />
      </div>

      <div className="flex w-full items-center justify-center gap-x-2 md:w-fit">
        {showResetZoneButton && (
          <Button variant="outline" onClick={onResetZoneData}>
            {translate('zoneEditor.reset')}
          </Button>
        )}

        <Button variant="solid" className="bg-rose-600 hover:bg-rose-800" onClick={onCancel}>
          {translate('zoneEditor.cancel')}
        </Button>
      </div>
    </div>
  </header>
);
