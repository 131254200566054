import { baseQuery, signRequest } from '@/shared/api/utils';

import {
  AllGeofencesParamsProps,
  GeofencesCreateRequest,
  GeofencesUpdateRequest,
} from '@/shared/api/geofences/geofences.types.ts';
import { GeofencesRootProps, UpdateGeofenceServiceResponse } from '@/shared/types/geofences.types';

export namespace GeofencesAPINamespace {
  const client = baseQuery('GEOFENCES');

  export const getAll = async ({
    accountResName,
    siteResName,
    floorResName,
  }: AllGeofencesParamsProps): Promise<GeofencesRootProps> => {
    if (floorResName) {
      const url = `/accounts/${accountResName}/sites/${siteResName}/geofences/?floorResName=${floorResName}&limit=1000`;

      const signedRequest = await signRequest('GEOFENCES', url, 'GET');

      const result = await client.get(signedRequest.url, {
        headers: signedRequest.headers,
      });

      return result.data;
    }

    return {
      page: {
        hasNext: false,
        pageItemsCount: 0,
      },
      results: [],
    };
  };

  export const createOne = async ({
    accountResName,
    siteResName,
    ...body
  }: GeofencesCreateRequest): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/geofences`;

    const signedRequest = await signRequest('GEOFENCES', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateOne = async ({
    accountResName,
    siteResName,
    ...body
  }: GeofencesUpdateRequest): Promise<UpdateGeofenceServiceResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/geofences/${body.geofenceResName}`;

    const _body = { ...body } as never as { [k: string]: string };
    delete _body.geofenceResName;

    const signedRequest = await signRequest('GEOFENCES', url, 'PATCH', _body);

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
