import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useFavicon, useTitle } from 'react-use';

import { AppLayout } from '@/shared/components/layout/AppLayout';
import { Footer } from '@/shared/components/layout/Footer';
import { Header } from '@/shared/components/layout/Header';
import { InitialHooks } from '@/shared/components/service/InitialHooks';
import { Main } from '@/shared/components/layout/Main';
import { normalizedPathname } from '@/shared/utils/helpers';
import { useLayoutOperations } from '@/layout/Layout/hooks/useLayoutOperations';

export const Layout: FC = () => {
  const { isHomePage, pathname } = useLayoutOperations();

  useFavicon('/favicon.svg');
  useTitle(`ZaiNar | ${normalizedPathname(pathname)}`);

  return (
    <AppLayout>
      <Header isDisabledHomeLink={isHomePage} />

      <Main>
        <InitialHooks />

        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </Main>

      <Footer variant="default" />
    </AppLayout>
  );
};
