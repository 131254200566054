import { FieldValues, UseFormReturn, Controller } from 'react-hook-form';
import { UseMutateFunction } from '@tanstack/react-query';

import { Button } from '@/shared/components/buttons/Button';
import { Input } from '@/shared/components/form/Input';
import { InputWrapper } from '@/shared/components/ui/Modal/Equipment/components/InputWrapper';
import { RolesWrapper } from '@/shared/wrappers/Roles.tsx';
import { Select } from '@/shared/components/form/Select';
import { Toggle } from '@/shared/components/form/Toggle';
import { Typography } from '@/shared/components/ui/Typography';
import {
  currenciesList,
  groupsList,
  periodList,
} from '@/shared/components/ui/Modal/Subcontractor/data';
import { translate } from '@/i18n';
import { useEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEquipment';
import { useFiltersHideGroupsZonesShowMapFlag } from '@/shared/feature-flags/useFiltersHideGroupsZonesShowMapFlag.ts';
import { useModalHandlerTypes } from '@/shared/hooks/global/useModalHandlerTypes.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';

import { RolesEnum } from '@/shared/types/enums.ts';

type FormBodyProps<T, K> = {
  params: UseFormReturn;
  type: 'edit' | 'create';
  mutate: UseMutateFunction<T, Error, FieldValues, unknown>;
  states: Record<string, K>;
  deleteMutate?: UseMutateFunction<T, Error, void, unknown>;
  deleteStates?: Record<string, K>;
};

export function EquipmentFormBody<T, K>({
  params,
  type = 'create',
  mutate,
  states,
  deleteMutate,
  deleteStates,
}: Readonly<FormBodyProps<T, K>>) {
  const {
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = params;

  const { modifiedCategoriesPaths, modifiedGeofences, closeModal, modifiedUnusedSiteTags } =
    useEquipment(params);

  const { renderItemVisibility } = useRole();
  const { equipmentDeleteHandler } = useModalHandlerTypes();
  const { filtersHideGroupsZonesShowMapFlag } = useFiltersHideGroupsZonesShowMapFlag();

  const isVisible = renderItemVisibility([RolesEnum.MANAGER]);

  const renderTitle = () => {
    switch (type) {
      case 'edit':
        return translate('global.editEquipment');
      case 'create':
        return translate('global.addEquipment');
    }
  };

  const renderActions = () => {
    return (
      <>
        {type === 'edit' && (
          <>
            <Button
              variant="solid"
              className="bg-red-500 hover:bg-red-600"
              type="button"
              onClick={() => {
                if (deleteMutate)
                  equipmentDeleteHandler({
                    ...getValues(),
                    deleteMutate,
                  });
              }}
              disabled={deleteStates?.isPending as boolean}
              isLoading={deleteStates?.isPending as boolean}
              withIcon
            >
              {translate('confirm.delete')}
            </Button>

            <Button
              variant="solid"
              className="trun text-nowrap bg-red-600 hover:bg-red-700"
              type="button"
              withIcon
              disabled={(states?.isPending as boolean) || !getValues('tag')}
              isLoading={states?.isPending as boolean}
              onClick={() => {
                if (mutate)
                  mutate({
                    ...getValues(),
                    deleteTags: true,
                  });
              }}
            >
              {translate('assets.removeTags')}
            </Button>
          </>
        )}

        <div className="ml-auto inline-flex gap-x-[10px]">
          <Button
            className="border-slate-200 bg-white text-slate-800 hover:bg-slate-50 hover:text-slate-900 focus:bg-slate-50 focus:text-slate-900"
            variant="outline"
            type="reset"
            onClick={closeModal}
          >
            {translate('filters.cancel')}
          </Button>

          <Button
            variant="solid"
            type="submit"
            withIcon
            disabled={states.isPending as boolean}
            isLoading={states.isPending as boolean}
          >
            {translate('settings.save')}
          </Button>
        </div>
      </>
    );
  };

  const onSubmit = (data: FieldValues) => {
    try {
      mutate(data);
    } catch (error) {
      console.error(error);
    }
  };

  watch();

  return (
    <form className="grid w-full max-w-md gap-y-3 pb-[70px]" onSubmit={handleSubmit(onSubmit)}>
      <Typography className="text-xl font-bold tracking-[0.1px]" as="strong">
        {renderTitle()}
      </Typography>

      <InputWrapper label={translate('global.title')} isRequired>
        <div className="inline-flex w-full gap-x-3">
          <Input
            className="w-full"
            {...register('title')}
            error={errors?.title?.message as string}
            isErrorLabelHidden
          />
        </div>
      </InputWrapper>

      <RolesWrapper action="edit_equipment_types_subtypes">
        {getValues('categoryType') &&
          modifiedCategoriesPaths &&
          modifiedCategoriesPaths?.length > 0 && (
            <InputWrapper label={translate('filters.subType')} isRequired>
              <div className="w-full">
                <Select
                  className="w-full"
                  variant="default"
                  defaultValue={getValues('categorySubType')}
                  data={getValues('categoryType') && modifiedCategoriesPaths}
                  onChange={value => setValue('categorySubType', value)}
                />
              </div>
            </InputWrapper>
          )}
      </RolesWrapper>

      <div className="flex items-center justify-start gap-x-2.5">
        {/*<Toggle checked={true} onChange={() => null} />*/}

        <Controller
          render={({ field }) => <Toggle checked={field.value} onChange={field.onChange} />}
          name="active"
          control={control}
        />

        <Typography as="span" className="text-sm font-medium">
          {translate('assets.active')}
        </Typography>
      </div>

      <InputWrapper label={translate('global.ownerRentalCompany')} isRequired>
        <div className="inline-flex w-full gap-x-3">
          <Input
            className="w-full"
            {...register('rentalCompany')}
            error={errors?.rentalCompany?.message as string}
            isErrorLabelHidden
          />
        </div>
      </InputWrapper>

      {isVisible && (
        <InputWrapper
          label={<Typography as="span">{translate('global.price')} (Period)</Typography>}
        >
          <div className="inline-flex w-full gap-x-3">
            <Input
              className="w-full"
              defaultValue="0"
              {...register('price')}
              error={errors?.price?.message as string}
              isErrorLabelHidden
            />

            <Select
              variant="default"
              label="auth.select"
              defaultValue={getValues('period')}
              data={periodList}
              onChange={value => setValue('period', value)}
            />
          </div>
        </InputWrapper>
      )}

      {isVisible && (
        <InputWrapper label={translate('settings.currency')}>
          <div className="w-full">
            <Select
              variant="default"
              label="Select"
              data={currenciesList}
              defaultValue={getValues('iso4217')}
              onChange={value => setValue('iso4217', value)}
            />
          </div>
        </InputWrapper>
      )}

      {filtersHideGroupsZonesShowMapFlag.enabled && (
        <InputWrapper label={translate('global.groups')}>
          <div className="w-full">
            <Select
              variant="default"
              label="auth.select"
              disabled
              data={groupsList}
              onChange={() => null}
            />
          </div>
        </InputWrapper>
      )}

      <InputWrapper label={translate('global.tags')}>
        <div className="w-full">
          <Select
            variant="default"
            label="auth.select"
            defaultValue={getValues('tag')}
            data={modifiedUnusedSiteTags(getValues('tag'))}
            onChange={value => setValue('tag', value)}
          />
        </div>
      </InputWrapper>

      <InputWrapper label={translate('global.dropOff')}>
        <div className="w-full">
          <Select
            className="w-full"
            variant="default"
            label="auth.select"
            defaultValue={getValues('dropOff')}
            data={modifiedGeofences}
            onChange={value => setValue('dropOff', value)}
          />
        </div>
      </InputWrapper>

      <div className="absolute bottom-0 left-0 z-20 flex h-[70px] w-full items-center justify-between gap-x-2 overflow-x-auto rounded-b-2xl border-t border-slate-200 bg-slate-100 px-3 py-2">
        {renderActions()}
      </div>
    </form>
  );
}
