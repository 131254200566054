import { useEffect, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { SOCKET } from '@/shared/utils/constants.ts';
import { SystemViewNamespace } from '@/shared/services/system-view.service.ts';
import { useConnectLocalSockets } from '@/shared/ws/local';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useSocketMapDataStore } from '@/shared/store/socket-map-data';
import { useUIStore } from '@/shared/store/ui';

import { SocketConnectionStatusProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { useAlertsStore } from '@/shared/store/actions';
import { useGlobalStore } from '@/shared/store/global';

export const useWebSocketConnection = () => {
  const didUnmount = useRef(false);

  const site = useUIStore(state => state.site);
  const setSocketMapData = useSocketMapDataStore(state => state.setSocketMapData);
  const setConnectionStatus = useSocketMapDataStore(state => state.setConnectionStatus);
  const setWsAlertAvailable = useAlertsStore(store => store.setWsAlertAvailable);
  const addAlertData = useAlertsStore(store => store.addAlertData);
  const archiveAlert = useAlertsStore(store => store.moveAlertToArchive);

  const { socketUrl } = useConnectLocalSockets();
  const { user } = useFetchUserData();

  const { sendMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {
      console.info(SOCKET.EVENT.CLIENT_OPENED);
    },
    onClose: () => {
      console.info(SOCKET.EVENT.CLIENT_CLOSED);
    },
    onError: error => {
      console.error(error);
    },
    onReconnectStop: numberAttempts => {
      console.info(SOCKET.EVENT.ON_RECONNECT_STOP, numberAttempts);
    },
    onMessage: (event: { data: unknown }) => {
      if (!event.data) return;

      const parsedData = JSON.parse(event.data as string);
      if (parsedData?.message === SOCKET.EVENT.INTERNAL_SERVER_ERROR) return;

      if (parsedData?.notification && parsedData?.data) {
        const assets = useGlobalStore.getState().assets;
        const alerts = useAlertsStore.getState().alertsData;
        const assetResourceName = parsedData.data?.assetResName;
        const alertResourceName = parsedData.messageAttributes.alertResName.Value;

        if (assetResourceName && alertResourceName) {
          const existingAlert = alerts.find(alert => alert.alertResName === alertResourceName);
          const alertAsset = assets.find(asset => asset.assetResName === assetResourceName);
          const alertIsActive = parsedData.data.active?.toString() === 'true';

          if (!existingAlert && alertAsset && alertIsActive) {
            addAlertData({
              alertResName: alertResourceName,
              assetResName: parsedData.data.assetResName,
              accountResName: parsedData.messageAttributes.accountResName.Value,
              alertDescription: '',
              deviceResName: parsedData.messageAttributes.deviceResName.Value,
              siteResName: parsedData.messageAttributes.siteResName.Value,
              timestamp: new Date().getTime(),
              metadata: parsedData.data.metadata,
              // actionUrls
              alertName: parsedData.data.alertName,
              alertSeverity: parsedData.data.alertSeverity,
              alertCategory: parsedData.data.alertCategory,
              attribute: parsedData.data.attribute,
              active: alertIsActive,
            });
            setWsAlertAvailable(true);
          } else archiveAlert(alertResourceName);
        }
      } else {
        const arrivalTimestampResult = { arrivalTimestamp: Date.now() };

        const transformedResult =
          SystemViewNamespace.LocationsNamespace.transformSystemView(parsedData);

        setSocketMapData({ ...arrivalTimestampResult, ...transformedResult });
      }
    },
    shouldReconnect: () => {
      console.info(SOCKET.EVENT.SHOULD_RECONNECT);

      return !didUnmount.current;
    },
    retryOnError: true,
    reconnectAttempts: SOCKET.OPTIONS.RECONNECT_ATTEMPTS,
    reconnectInterval: SOCKET.OPTIONS.RECONNECT_INTERVAL,
  });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'CONNECTING',
    [ReadyState.OPEN]: 'OPEN',
    [ReadyState.CLOSING]: 'CLOSING',
    [ReadyState.CLOSED]: 'CLOSED',
    [ReadyState.UNINSTANTIATED]: 'UNINSTANTIATED',
  }[readyState] as SocketConnectionStatusProps;

  useEffect(() => {
    setConnectionStatus(connectionStatus);
  }, [connectionStatus, readyState, setConnectionStatus]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const data = JSON.stringify({
        action: SOCKET.MESSAGE.ACTION,
        accountResName: user?.accountResourceName,
        siteResName: site,
        messageTypes: SOCKET.MESSAGE.MESSAGE_TYPES,
      });

      if (!data) return;

      sendMessage(data);
    }
  }, [readyState, sendMessage, site, user?.accountResourceName]);
};
