import { Euler, Object3D, Vector3 } from 'three';

import { GeofencesProps } from '@/shared/types/geofences.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';
import { Location } from '@/shared/types/locations.types.ts';
import { PopupState } from '@/shared/store/popup/popup.types.ts';

export type Vector3NumberProps = [number, number, number];
export type MapCoordinates = {
  rotation: Euler;
  scale: Vector3 | number;
  position: Vector3;
  target?: Vector3;
};

export type MapViewType = 'full' | 'top' | 'side';
export type MapKey = 'mapThumbnailKey' | 'mapKey';

export type useMapModelProps = {
  filteredSocketMapData: SocketMapDataProps[];
};

export type MapModelProps = {
  isGridVisible: boolean;
  mapCoordinates: MapCoordinates;
  mapKey: MapKey;
  mapView: MapViewType;
  isThumbnail?: boolean;
  thumbnailMapCoords?: MapCoordinates;
  onUpdateMapCoords?: (coords: MapCoordinates) => void;
};

export type CustomOrbitsControlProps = {
  popups: PopupState[];
  mapView: MapViewType;
  thumbnailMapCoords?: MapCoordinates;
  onUpdateMapCoords?: (coords: MapCoordinates) => void;
};

export type PathProps = {
  id: string;
  color: string;
  type: string;
  name: string;
  companyName: string;
  locations: Location[];
  visible: boolean;
};

export type CurrentLocationElementProps = {
  timeOfLocation: {
    epochTime: number;
  };
  x: number;
  y: number;
  z: number;
  company: string;
};

export type AnchorProps = {
  position: Vector3NumberProps;
  size: Vector3NumberProps;
  visible: boolean;
  anchor?: Partial<SocketMapDataProps> & { tracker?: boolean };
  color?: string;
  active: boolean;
};

export type ClusterProps = {
  id: string;
  position: Vector3NumberProps;
  workerPercentage: number;
  totalNumber: number;
  posX: number;
  posY: number;
  visible: boolean;
  trackersData: Partial<SocketMapDataProps>[];
};

export interface ClusterObject {
  [key: string]: Partial<SocketMapDataProps>[];
}

export interface ClustersArray {
  id: string;
  workerPercetage: number;
  totalNumber: number;
  posX: number;
  posY: number;
  trackersData: Partial<SocketMapDataProps>[];
}

export type ReaderProps = {
  position: Vector3NumberProps;
};

export type ColladaModelProps = {
  map: string;
  mapKey: string;
};

export type ColladaModelSceneProps = {
  scene: Object3D;
};

export type OriginProps = {
  position: Vector3NumberProps;
  size: Vector3NumberProps;
  color?: string;
};

export type GeofenceElementProps = {
  geofence: GeofencesProps;
};

export enum AssetTypeEnum {
  Equipment = 'site/Equipment',
  Worker = 'site/Worker',
}

export type MappedLocationsHistoryProps = {
  key: string;
  color: string;
  type: string;
  name: string;
  locations: {
    timeOfLocation: { epochTime: number };
    x: number;
    y: number;
    z: number;
  }[];
  companyName: string;
};

export interface SiteMapRemoteConfigProps {
  SIDE: {
    rotation: Euler;
    scale: number;
    position: Vector3;
  };
  TOP: {
    rotation: Euler;
    scale: number;
    position: Vector3;
  };
  FULL: {
    rotation: Euler;
    scale: number;
    position: Vector3;
  };
  ZONE_EDITOR_OFFSETS: number[];
}
