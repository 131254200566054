import { FC, useState } from 'react';

import { AlertsNotification } from '@/shared/components/ui/UserMenu/AlertsNotification';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Intercom } from '@/shared/components/service/Intercom';
import { LanguageMenuItem } from '@/shared/components/ui/UserMenu/LanguageMenuItem';
import { LanguageSelect } from '@/shared/components/ui/UserMenu/LanguageSelect';
import { LicenseAndReleasesLinks } from '@/shared/components/navigation/LicenseAndReleasesLinks';
import { MobileDialog } from '@/shared/components/ui/MobileDialog';
import { Nav } from '@/shared/components/navigation/Nav';
import { NavLink } from '@/shared/components/navigation/Navbar/NavLink';
import { SettingsMenuItem } from '@/shared/components/ui/UserMenu/SettingsMenuItem';
import { SingOutMenuItem } from '@/shared/components/ui/UserMenu/SingOutMenuItem/SingOutMenuItem.tsx';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { SiteMenuItem } from '@/shared/components/ui/UserMenu/SiteMenuItem';
import { SiteSelect } from '@/shared/components/ui/UserMenu/SiteSelect';
import { User } from '@/shared/components/ui/UserMenu/User';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useNavbarLinks } from '@/shared/hooks/ui/useNavbarLinks.ts';

import { DropdownType } from '@/shared/components/ui/UserMenu/UserMenu.types.ts';
import { NavLinkProps } from '@/shared/components/navigation/Navbar/Navbar.types.tsx';
import { RoutesEnum } from '@/shared/types/enums.ts';

type Props = {
  onClearStateData: () => void;
  onSignOut: () => void;
};

export const MobileMenu: FC<Props> = ({ onClearStateData, onSignOut }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [menuType, setMenuType] = useState(DropdownType.MOBILE);

  const { user } = useFetchUserData();
  const { filteredLinks } = useNavbarLinks();

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  const handleRenderBody = () => {
    if (menuType === DropdownType.SITE_SELECT) return <SiteSelect setMenuType={setMenuType} />;

    if (menuType === DropdownType.LANGUAGE) return <LanguageSelect setMenuType={setMenuType} />;

    if (menuType === DropdownType.MOBILE) {
      return (
        <>
          <Nav className="grid justify-items-start gap-y-2 pb-2">
            {filteredLinks.map((link: NavLinkProps) => (
              <NavLink
                key={link.name.toLowerCase()}
                withCounter={link.path === RoutesEnum.ACTIONS}
                onClick={closeMenu}
                {...link}
              />
            ))}
          </Nav>

          <SiteMenuItem
            className="text-wrap px-3 py-3 text-left text-base text-white"
            onClick={() => setMenuType(DropdownType.SITE_SELECT)}
          />

          <div className="space-y-4 px-3 py-3 pb-5">
            <div className="flex items-center gap-x-2">
              <User user={user} />
            </div>

            <LanguageMenuItem
              className="test-base py-2 text-base text-white"
              onClick={() => setMenuType(DropdownType.LANGUAGE)}
            />

            <SettingsMenuItem
              className="inline-flex w-full items-center text-base text-white"
              onClick={() => {
                closeMenu();
                onClearStateData();
              }}
            />

            <SingOutMenuItem
              className="inline-flex w-full items-center text-base text-red-300"
              onClick={() => {
                closeMenu();
                onSignOut();
              }}
            />
          </div>

          <LicenseAndReleasesLinks
            className="p-2 text-base font-normal text-white [&_a]:py-2 hover:[&_a]:text-blue-500 md:focus:[&_a]:text-blue-500"
            onClick={closeMenu}
          />
        </>
      );
    }
  };

  return (
    <div className="inline-flex md:hidden">
      <IconButton className="text-white" icon="burger" onClick={openMenu} />

      <MobileDialog
        className="top-0 bg-blue-600 px-3 md:hidden"
        overlayBg="blue"
        isOpen={isOpen}
        onClose={closeMenu}
      >
        <header className="flex h-16 items-center gap-6 bg-blue-600 px-3 py-1.5">
          <SiteLogo className="h-6 w-[105px]" />

          <div className="ml-auto flex items-center gap-x-4">
            <Intercom />
            <AlertsNotification />
            <IconButton className="text-white" icon="close" onClick={closeMenu} />
          </div>
        </header>

        <div className="divide-y divide-blue-500 py-2">{handleRenderBody()}</div>
      </MobileDialog>
    </div>
  );
};
