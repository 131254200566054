import { FC, useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';

import { AnchorPopup } from '@/modules/Home/components/map/AnchorPopup';
import { CLUSTER_CONFIG } from '@/modules/Home/utils/MapModel.config';
import {
  useAnchorPopupState,
  useMapModelDetails,
  useSmoothAnchorPosition,
} from '@/modules/Home/hooks/map';
import { useGlobalStore } from '@/shared/store/global';

import { AssetTypeEnum, AnchorProps } from '@/modules/Home/types/MapModel.types';
import { CustomIconType } from '@/shared/components/ui/CustomIcon/CustomIcon.types.ts';
import { useUIStore } from '@/shared/store/ui';

export const Anchor: FC<AnchorProps> = ({ position, anchor, active, visible }) => {
  const { camera } = useThree();
  const locatedAnchors = useGlobalStore(state => state.locatedAnchor);
  const setLocatedAnchor = useGlobalStore(state => state.setLocatedAnchor);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const isLocatedAnchor = locatedAnchors.includes(anchor?.tagResName as string);

  useEffect(() => {
    setIsOpened(isLocatedAnchor);
  }, [isLocatedAnchor]);

  const { setIsHovered } = useMapModelDetails();
  const { anchorRef } = useSmoothAnchorPosition(position);

  useAnchorPopupState(isOpened, setIsOpened);

  const renderPinType = (): CustomIconType => {
    const isWorker = anchor?.categoryTreeName?.includes(AssetTypeEnum.Worker);
    const isEquipment = anchor?.categoryTreeName?.includes(AssetTypeEnum.Equipment);

    if (isEquipment) {
      return anchor?.tracker || !active ? 'trackerEquipmentPin' : 'equipmentPin';
    }

    if (isWorker) {
      return anchor?.tracker ?? !active ? 'trackerWorkerPin' : 'workerPin';
    }

    return 'equipmentPin';
  };

  const handleTogglePopup = () => {
    if (isLocatedAnchor && isOpened) {
      setLocatedAnchor(anchor?.tagResName as string);
    }

    setIsOpened(isOpened => !isOpened);
  };
  const siteId = useUIStore(state => state.site);
  const zoomLevel =
    CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL_CUSTOM[siteId] ?? CLUSTER_CONFIG.CLUSTER_ZOOM_LEVEL;

  if (camera.position.z < zoomLevel || visible) {
    return (
      <mesh
        ref={anchorRef}
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
        renderOrder={1} //NOSONAR
      >
        <AnchorPopup
          anchor={anchor ?? {}}
          isOpened={isOpened}
          type={renderPinType()}
          togglePopup={handleTogglePopup}
        />
      </mesh>
    );
  }
};
