export const APIGatewayLinks = {
  ACTIONS: import.meta.env.VITE_ACTIONS_API_LINK,
  APPS_BOOTSTRAP: import.meta.env.VITE_APPS_BOOTSTRAP_API_LINK,
  ASSETS: import.meta.env.VITE_ASSETS_API_LINK,
  USER: import.meta.env.VITE_USER_API_LINK,
  BUILD_TIME_URL: '/buildtime',
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT_API_LINK,
  EXECUTION_INIT: import.meta.env.VITE_EXECUTION_INIT_API_LINK,
  GEOFENCES: import.meta.env.VITE_GEOFENCES_API_LINK,
  SITES: import.meta.env.VITE_SITES_API_LINK,
  ZILDEE: import.meta.env.VITE_ZILDEE_API_LINK,
  LOCATION_HISTORY: import.meta.env.VITE_LOCATIONS_HISTORY_API_LINK,
  RESERVATIONS: import.meta.env.VITE_RESERVATIONS_API_LINK,
  MAPS: import.meta.env.VITE_MAPS_API_LINK,
  ANALYTICS: import.meta.env.VITE_ANALYTICS_API_LINK,
  SITE_OPERATIONS: import.meta.env.VITE_SITE_OPERATIONS_API_LINK,
};
