import { RolesEnum } from '@/shared/types/enums.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';

export function useRole() {
  const { user } = useFetchUserData();

  // list as dep to filter by user role
  const renderItemVisibility = (list: RolesEnum[]) => list.includes(user?.role ?? RolesEnum.WORKER);

  return { renderItemVisibility };
}
