import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { SettingsAPINamespace } from '@/shared/api/settings/settings.requests.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';

import {
  CreateSettingsMutationProps,
  UpdateSettingsMutationProps,
  UpdateSettingsRequest,
} from '@/shared/api/settings/settings.types.ts';

export const useUserSettingsMutation = (withLoadingStatus = false) => {
  const queryClient = useQueryClient();
  const { user } = useFetchUserData();

  const { mutate: create, isPending: isCreating } = useMutation({
    mutationKey: [QUERY_KEY.USER_SETTINGS_CREATE],

    mutationFn: async (settings: CreateSettingsMutationProps) => {
      const body = {
        ...settings,
        accountResName: user?.accountResourceName as string,
        userResName: user?.userResourceName as string,
      };

      if (withLoadingStatus) {
        await notifyService.promise(SettingsAPINamespace.createOne(body), {
          success: 'Settings created successfully',
          error: 'Failed to create settings',
          loading: 'Creating settings...',
        });

        return;
      }

      await SettingsAPINamespace.createOne(body);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.USER_SETTINGS_GET],
      });
    },

    onError: error => {
      console.error(error);
    },
  });

  const { mutate: update, isPending: isUpdating } = useMutation({
    mutationKey: [QUERY_KEY.USER_SETTINGS_UPDATE],

    mutationFn: async (settings: UpdateSettingsMutationProps) => {
      const body = {
        ...settings,
        accountResName: user?.accountResourceName as string,
        userResName: user?.userResourceName as string,
      } as UpdateSettingsRequest;

      if (withLoadingStatus) {
        await notifyService.promise(SettingsAPINamespace.updateOne(body), {
          success: 'Settings updated successfully',
          error: 'Failed to update settings',
          loading: 'Updating settings...',
        });

        return;
      }

      await SettingsAPINamespace.updateOne(body);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.USER_SETTINGS_GET],
      });
    },

    onError: error => {
      console.error(error);
    },
  });

  return { create, update, isCreating, isUpdating };
};
