import { CLUSTER_CONFIG } from '@/modules/Home/utils/MapModel.config.ts';

import {
  AssetTypeEnum,
  ClusterObject,
  ClustersArray,
} from '@/modules/Home/types/MapModel.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

export const useClusters = (filteredSocketMapData: Partial<SocketMapDataProps>[]) => {
  const clusterSocketMapData: ClusterObject = {};

  //worker with outdated data should be filtered out
  //outdated worker = arrival time is > 2 minutes
  const filteredFilteredSocketMapdata = filteredSocketMapData.filter(tracker => {
    if (tracker?.categoryTreeName === AssetTypeEnum.Worker) {
      const INTERVAL_TIME_WORKER = 2 * 60 * 1000;
      const isOutdated = Date.now() - (tracker?.arrivalTimestamp as number) > INTERVAL_TIME_WORKER;
      return !isOutdated;
    }
    return true;
  });

  filteredFilteredSocketMapdata.map(tracker => {
    const posId = `${Math.ceil(
      Number(tracker?.x ?? 0) / CLUSTER_CONFIG.CLUSTER_GRID_SIZE,
    )}:${Math.ceil(Number(tracker?.y ?? 0) / CLUSTER_CONFIG.CLUSTER_GRID_SIZE)}`;
    if (clusterSocketMapData[posId]) {
      clusterSocketMapData[posId].push(tracker);
    } else {
      clusterSocketMapData[posId] = [];
      clusterSocketMapData[posId].push(tracker);
    }
  });

  const filteredClusters: ClustersArray[] = [];

  Object.keys(clusterSocketMapData).forEach(key => {
    if (clusterSocketMapData[key].length > 1) {
      const clstr = {
        id: key,
        workerPercetage: clusterSocketMapData[key].reduce(
          (a: number, b: Partial<SocketMapDataProps>) =>
            +a + (b?.categoryTreeName === AssetTypeEnum.Worker ? 1 : 0),
          0,
        ),
        totalNumber: clusterSocketMapData[key].length,
        trackersData: [...clusterSocketMapData[key]],
        posX:
          clusterSocketMapData[key].reduce(
            (a: number, b: Partial<SocketMapDataProps>) => +a + (b.x ? +b.x : 0),
            0,
          ) / clusterSocketMapData[key].length,
        posY:
          clusterSocketMapData[key].reduce(
            (a: number, b: Partial<SocketMapDataProps>) => +a + (b.y ? +b.y : 0),
            0,
          ) / clusterSocketMapData[key].length,
      };

      filteredClusters.push(clstr);
    }
  });

  return { filteredClusters };
};
