import { generateId } from '@/shared/utils/helpers';
import { MISSING_DATA } from '@/shared/utils/constants';

import { AuditLogDatasource } from '@/shared/types/reports.types';

export const auditLogsList: AuditLogDatasource[] = Array.from({
  length: 20,
}).map(_ => {
  return {
    id: generateId(),
    event: MISSING_DATA,
    details: MISSING_DATA,
    timestamp: MISSING_DATA,
    actor: MISSING_DATA,
    source: MISSING_DATA,
  };
});
