import { Signer } from 'aws-amplify';
import axios, { AxiosInstance } from 'axios';

import { APIGatewayLinks } from '@/shared/api/api-gateway-links.ts';
import { getSessionCredentials } from '@/shared/utils/aws-signature';

import { MethodProps, SignRequestProps } from '@/shared/types/aws-signature.types';

export const signRequest = async (
  _host: keyof typeof APIGatewayLinks,
  url: string,
  method: MethodProps,
  data?: unknown,
): Promise<SignRequestProps> => {
  const serviceInfo = {
    region: import.meta.env.VITE_AWS_REGION,
    service: import.meta.env.VITE_AWS_SERVICE_NAME,
  };

  const { secretAccessKey, accessKeyId, sessionToken } = getSessionCredentials();

  const params = {
    method,
    url: APIGatewayLinks[_host] + url,
    data: JSON.stringify(data),
  };

  const credentials = {
    secret_key: secretAccessKey,
    access_key: accessKeyId,
    session_token: sessionToken,
  };

  const signedRequest = Signer.sign(params, credentials, serviceInfo);

  delete signedRequest.headers['host'];

  return signedRequest;
};

export const baseQuery = (baseURL: keyof typeof APIGatewayLinks): AxiosInstance => {
  if (!baseURL) {
    throw new Error('Please define the right environment variable inside .env');
  }

  return axios.create({
    baseURL: APIGatewayLinks[baseURL],
  });
};
