import { FC } from 'react';

import { useActionsHook } from '@/shared/api/actions/useActionsHook.ts';

export const ActionsHooks: FC = () => {
  useActionsHook.getActions();
  // useActionsHook.getActionsMetrics();

  return null;
};
