import { useMutation, useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues, useForm } from 'react-hook-form';

import { useUIStore } from '@/shared/store/ui';
import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { ReservationsAPINamespace } from '@/shared/api';
import { QUERY_KEY, RESERVATIONS } from '@/shared/utils/constants';
import { reservationFormSchema } from '@/shared/schemas/validate-schemas';
import { useRef } from 'react';
import { updateReservationEvent } from '@/shared/api/reservation/types';
import { useWorkflowEngine } from './useWorkflowEngine';

export function useCreateReservations() {
  const typeRef = useRef('Draft');

  const { site } = useUIStore(({ site }) => ({ site }));
  const { setIsClose, setIsSubmitting } = useModalStore(({ setIsClose, setIsSubmitting }) => ({
    setIsClose,
    setIsSubmitting,
  }));
  const company = useGlobalStore(store => store.companies);

  const params = useForm<FieldValues>({
    mode: 'onChange',
    resolver: zodResolver(reservationFormSchema),
  });

  const { user } = useFetchUserData();
  const workFlowCall = useWorkflowEngine();

  const queryClient = useQueryClient();
  const { mutate, ...states } = useMutation({
    mutationKey: [],

    mutationFn: async (data: FieldValues) => {
      setIsSubmitting(true);
      const { asset, fromDate, toDate, firm, type } = data;
      typeRef.current = type;

      const selectedCompany = await company.find(item => item.orgResName == firm);

      return await ReservationsAPINamespace.createReservations({
        status: 'active',
        accountResName: user?.accountResourceName ?? '',
        siteResName: site,
        appResName: 'pending',
        workflowResName: 'workflow-12345',
        user: {
          userId: user?.userResourceName,
          orgName: selectedCompany?.orgName,
          orgResName: selectedCompany?.orgResName,
          orgType: 'pending',
        },
        assetResName: asset,
        reservationPeriod: {
          startTime: {
            epochTime: Date.parse(fromDate),
          },
          endTime: {
            epochTime: Date.parse(toDate),
          },
        },
      });
    },

    onSuccess: async successData => {
      if (typeRef.current == 'Draft') {
        setIsSubmitting(false);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
        setIsClose();

        notifyService.success('Reservation create success');
      } else {
        const stateUpdate: updateReservationEvent = {
          accountResName: user?.accountResourceName ?? '',
          siteResName: site,
          reservationResName: String(successData),
          userResName: user?.userResourceName,
          triggerEventType: RESERVATIONS.eventPendingSub,
        };

        workFlowCall.mutateAsync(stateUpdate);
      }
    },

    onError: error => {
      console.error(error);
      setIsSubmitting(false);
      notifyService.error('Reservation create failed');
    },
  });

  return { params, mutate, states };
}
