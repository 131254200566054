import { create } from 'zustand';

import { LocalStoreProps } from '@/shared/store/local/local.types';

export const useLocalStore = create<LocalStoreProps>((set, _) => ({
  socketInstance: null,
  setSocketInstance: (payload: WebSocket | null) => {
    set({
      socketInstance: payload,
    });
  },
}));
