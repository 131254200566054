import { FC, useState } from 'react';
import { Html } from '@react-three/drei';

import { AnchorCard } from '@/modules/Home/components/map/AnchorCard';
import { Button } from '@/shared/components/buttons/Button';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { classnames } from '@/shared/utils/classnames.ts';

import { CustomIconType } from '@/shared/components/ui/CustomIcon/CustomIcon.types.ts';
import { SocketMapDataProps } from '@/shared/store/socket-map-data/socket-map-data.types.ts';

type Props = {
  anchor: Partial<SocketMapDataProps>;
  isOpened: boolean;
  type: CustomIconType;
  togglePopup: () => void;
};

export const AnchorPopup: FC<Props> = ({ anchor, isOpened, type, togglePopup }) => {
  const defaultValue = type === 'trackerEquipmentPin' || type === 'trackerWorkerPin' ? 1e6 : 1e8;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Html zIndexRange={[1e9, 0]}>
        <Button
          className="anchorShowAnimation absolute -bottom-2.5 left-1/2 -translate-x-1/2"
          variant="custom"
          onClick={togglePopup}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          data-anchor={true}
        >
          <CustomIcon type={type} isActive={isHovered || isOpened} />
        </Button>
      </Html>

      {isOpened && (
        <Html zIndexRange={[isOpened ? 1e20 : defaultValue, 0]}>
          <AnchorCard
            className={classnames(
              'absolute left-1/2 -translate-x-1/2 p-3 pe-10 text-sm font-normal text-slate-500 shadow-xl xl:left-8 xl:-translate-y-3/4',
              isOpened && 'canvasPopupAnimation',
            )}
            body={anchor}
            togglePopup={togglePopup}
          />
        </Html>
      )}
    </>
  );
};
