import { ForwardedRef, forwardRef, useState } from 'react';

import { IconButton } from '@/shared/components/buttons/IconButton';
import { Input } from '@/shared/components/form/Input/Input';

import { ChildElementProps } from '@/shared/components/form/Input/Input.types';

export const PasswordInput = forwardRef(
  (props: ChildElementProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [isTypeText, setIsTypeText] = useState<boolean>(false);

    const handleSetTypeText = () => {
      setIsTypeText(prev => !prev);
    };

    return (
      <>
        <Input
          ref={ref}
          className="pr-[42px]"
          type={isTypeText ? 'text' : 'password'}
          autoComplete="on"
          {...props}
        />

        <IconButton
          icon={isTypeText ? 'eye-sm' : 'pass-eye-sm'}
          className="absolute right-4 top-[70%] h-3.5 w-3.5 -translate-y-1/2 transform"
          onClick={handleSetTypeText}
          disabled={props.disabled}
        />
      </>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
