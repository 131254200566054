import { ReserveEquipment } from '@/shared/components/ui/Modal/Equipment/extensions/ReserveEquipment';
import { useCreateReservations } from '@/modules/Reservations/hooks/useCreateReservations.ts';

export function CreateReservation() {
  const { params, mutate, states } = useCreateReservations();

  const props = {
    params,
    mutate,
    states,
  };

  return <ReserveEquipment {...props} />;
}
