import { MISSING_DATA } from '@/shared/utils/constants';
import { TagDiagnosticsDatasource } from '@/shared/types/reports.types.ts';

export const tagDiagnosticsList: TagDiagnosticsDatasource[] = Array.from({ length: 20 }).map(_ => {
  return {
    device_id: MISSING_DATA,
    asset: MISSING_DATA,
    mac_address: MISSING_DATA,
    rssi: MISSING_DATA,
    last_transmission: MISSING_DATA,
    last_movement: MISSING_DATA,
    firmware_version: MISSING_DATA,
    hub_ip: MISSING_DATA,
    battery: MISSING_DATA,
  };
});
