import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { Typography } from '@/shared/components/ui/Typography';

import Logo from '@/assets/icons/brand-blue.svg?react';

export const ErrorFallback: FC<FallbackProps> = ({ error }) => {
  console.error('[error]:', error);

  return (
    <div
      role="alert"
      className="flex h-[100vh] w-full flex-col items-center gap-y-32 bg-slate-50 py-20"
    >
      <Logo />

      <div className="flex flex-col gap-y-10 rounded-xl border bg-red-50/70 p-6 shadow">
        <Typography as="strong" className="text-lg">
          Something went wrong:
        </Typography>

        <Typography as="p" className="text-center font-medium text-red-700">
          {error?.message}
        </Typography>
      </div>
    </div>
  );
};
