import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useSessionStorage, useTitle } from 'react-use';

import { AppLayout } from '@/shared/components/layout/AppLayout';
import { Footer } from '@/shared/components/layout/Footer';
import { Main } from '@/shared/components/layout/Main';
import { LS_KEYS, TEST_ID } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';

import { RoutesEnum } from '@/shared/types/enums';
import { useTermsOfUse } from '@/shared/hooks/observer/useTermsOfUse.ts';
import { useEffect } from 'react';

export const AuthLayout = () => {
  const site = useUIStore(store => store.site);

  const { isAuthorized } = useFetchUserData();
  const [location] = useSessionStorage(LS_KEYS.LOCATION);
  const { termsOfUseStatus, handleInitializeTermOfUse } = useTermsOfUse();
  const [searchParams] = useSearchParams();

  useTitle('ZaiNar | Login');

  useEffect(() => {
    handleInitializeTermOfUse(termsOfUseStatus);
  }, [termsOfUseStatus, handleInitializeTermOfUse]);

  if (isAuthorized && site) {
    const storedLocation = location || RoutesEnum.HOME;
    const navigateTo =
      searchParams.size > 0 ? `${storedLocation}?${searchParams.toString()}` : storedLocation;

    return <Navigate to={navigateTo} data-testid={TEST_ID.NAVIGATE} />;
  }

  return (
    <AppLayout>
      <Main className="bg-white md:bg-slate-100 content-center">
        <Outlet data-testid={TEST_ID.OUTLET} />
      </Main>

      <Footer variant="guest" />
    </AppLayout>
  );
};
