import React, { FC } from 'react';
import { useLoader } from '@react-three/fiber';
import { ColladaLoader } from 'three/addons/loaders/ColladaLoader.js';

import { ColladaModelProps, ColladaModelSceneProps } from '@/modules/Home/types/MapModel.types';

//Collada file loader
const ColladaModel: FC<ColladaModelProps> = ({ map, mapKey }) => {
  const { scene } = useLoader(ColladaLoader, map) as ColladaModelSceneProps;

  return (
    <primitive
      key={`${mapKey}-primitive`}
      object={scene} //NOSONAR
      dispose={null} //NOSONAR
    />
  );
};

export default React.memo(ColladaModel);
