import { useDebouncedCallback } from 'use-debounce';
import { useMedia } from 'react-use';

import { Button } from '@/shared/components/buttons/Button';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { SiteSelectItem } from '@/shared/components/ui/UserMenu/SelectItem';
import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames';
import { handleRenderCurrentLanguage } from '@/shared/components/ui/UserMenu/UserMenu.utils';
import { languages } from '@/i18n/data';
import { translate } from '@/i18n';
import { useUIStore } from '@/shared/store/ui';
import { useUserSettings } from '@/shared/hooks/settings/useUserSettings.ts';

import { DropdownType, LanguageSelectProps } from '@/shared/components/ui/UserMenu/UserMenu.types';
import { LocaleEnum } from '@/shared/types/i18n.types';
import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';
import ChevronRight from '@/assets/icons/chevron-right.svg?react';
import SelectCheck from '@/assets/icons/select-check.svg?react';

export const LanguageSelect = ({ setMenuType }: LanguageSelectProps) => {
  const userSettings = useUIStore(state => state.userSettings);
  const currentLanguage = useUIStore(state => state.currentLanguage);
  const setCurrentLanguage = useUIStore(state => state.setCurrentLanguage);

  const isMobile = useMedia('(max-width: 767px)');

  const userSettingsService = new SettingsService(
    userSettings,
    SettingsNameEnum.UserSettings,
    SettingsKeyEnum.UserSettings,
  );

  const { handleSaveSettings } = useUserSettings(userSettingsService);

  const fullFilledLanguage = handleRenderCurrentLanguage(currentLanguage);

  const handleSetLanguage = async (lng: { id: LocaleEnum }) => {
    await handleSaveSettings({ language: lng.id });
    setCurrentLanguage(lng.id);
  };

  const debouncedHandleSetLanguage = useDebouncedCallback(
    language => handleSetLanguage(language),
    300,
  );

  return (
    <div className="flex flex-col p-2">
      <Button
        variant="custom"
        onClick={() => setMenuType(isMobile ? DropdownType.MOBILE : DropdownType.DEFAULT)}
        aria-label="cancel-language-select"
        className={classnames(
          'inline-flex w-full items-center gap-3 rounded-md  text-sm font-medium transition-none duration-300',
          isMobile ? 'mb-4 text-white' : 'px-3 py-2',
        )}
      >
        <ChevronRight className="rotate-180" />
        <Typography>{translate(isMobile ? 'global.back' : 'global.language')}</Typography>
      </Button>

      {languages.map(language => {
        const isChosen = fullFilledLanguage?.id === language.id;

        return (
          <SiteSelectItem
            key={language.id}
            onClick={() => debouncedHandleSetLanguage(language)}
            aria-label={`language-${language.id}-option`}
            className={classnames(
              isChosen && !isMobile && 'text-blue-600',
              isMobile && 'py-2 text-base text-white',
            )}
          >
            {language.name}
            {isChosen && <SelectCheck />}
          </SiteSelectItem>
        );
      })}
    </div>
  );
};
