import { FC, useRef } from 'react';
import { Html } from '@react-three/drei';

import { CustomIcon } from '@/shared/components/ui/CustomIcon';

import { ReaderProps } from '@/modules/Home/types/MapModel.types';

export const Reader: FC<ReaderProps> = ({ position }) => {
  const anchorRef = useRef(null);

  return (
    <mesh
      ref={anchorRef}
      position={position} //NOSONAR
      renderOrder={-2} //NOSONAR
    >
      <Html zIndexRange={[1000, 0]}>
        <CustomIcon type="readerPin" className="anchorShowAnimation" />
      </Html>
    </mesh>
  );
};
