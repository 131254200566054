import { ForwardedRef, forwardRef } from 'react';

import { classnames } from '@/shared/utils/classnames';
import { Loader } from '@/shared/components/ui/Loader';

import { IButtonProps } from '@/shared/components/buttons/Button/Button.types';

export const Button = forwardRef(
  (
    { children, variant, className, withIcon, isLoading, disabled, ...props }: IButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        ref={ref}
        className={classnames(
          {
            'inline-flex items-center justify-center rounded-lg px-3 py-[9px] text-sm font-semibold tracking-wider duration-300 active:opacity-80 disabled:pointer-events-none disabled:opacity-50':
              variant === 'solid' || variant === 'outline' || variant === 'ghost',
            'gap-x-2': withIcon,
            'bg-blue-600 text-white shadow-sm hover:bg-blue-700': variant === 'solid',
            'border bg-white text-blue-600 shadow-sm hover:bg-blue-50': variant === 'outline',
            'bg-transparent text-blue-600 hover:bg-blue-100': variant === 'ghost',
          },
          className,
        )}
        disabled={disabled || isLoading}
        type="button"
        {...props}
      >
        {withIcon && isLoading && <Loader appearance="inline" />}

        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';
