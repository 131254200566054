import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Navbar } from '@/shared/components/navigation/Navbar';
import { SiteLogo } from '@/shared/components/ui/SiteLogo';
import { UserMenu } from '@/shared/components/ui/UserMenu';
import { classnames } from '@/shared/utils/classnames.ts';

import { RoutesEnum } from '@/shared/types/enums.ts';

type Props = {
  isDisabledHomeLink: boolean;
};

export const Header: FC<Props> = ({ isDisabledHomeLink }) => {
  return (
    <header className="flex h-16 items-center gap-6 bg-blue-600 px-6 py-1.5" role="banner">
      <Link
        to={RoutesEnum.HOME}
        className={classnames({ 'pointer-events-none': isDisabledHomeLink })}
        aria-label="home page"
      >
        <SiteLogo className="h-6 w-[105px]" />
      </Link>

      <Navbar //NOSONAR
        role="navigation"
      />

      <UserMenu role="menu" />
    </header>
  );
};
