import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { LS_KEYS } from '@/shared/utils/constants.ts';

export function useTermsOfUse() {
  const termsOfUseStatus = WebAPIStorageNamespace.LocalStorageService.getLocalItemParsed(
    LS_KEYS.TERM_OF_USE,
  );

  const handleUpdateTermsOfUse = (value: 'done' | 'idle') =>
    WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.TERM_OF_USE, value);

  const handleInitializeTermOfUse = (status: 'done' | 'idle') => {
    if (status !== 'done')
      WebAPIStorageNamespace.LocalStorageService.setLocalItem(LS_KEYS.TERM_OF_USE, 'idle');
  };

  return { termsOfUseStatus, handleUpdateTermsOfUse, handleInitializeTermOfUse };
}
