import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { URL_PARAMS } from '@/shared/utils/constants';
import { getTimestampInSec } from '@/shared/utils/date-fns';
import { initial } from '@/shared/utils/calendar';
import { useCurrentRoute } from '@/shared/hooks/global/useCurrentRoute.ts';
import { useLocalSearchParams } from '@/shared/hooks/global/useLocalSearchParams.ts';

import { SidebarParameters } from '@/shared/types/parameters.types';

export const useGlobalSearchParams = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const { isHomePage, isActionsPage, isSettingsPage } = useCurrentRoute();
  const { isHistoricalMode } = useLocalSearchParams();

  useEffect(() => {
    if (isHomePage) {
      setSearchParameters(searchParameters => {
        const sidebarParameter = searchParameters.get(URL_PARAMS.SIDEBAR);

        if (!sidebarParameter) {
          searchParameters.set(URL_PARAMS.SIDEBAR, SidebarParameters.VIEW);
        }

        if (!isHistoricalMode) {
          searchParameters.delete(URL_PARAMS.START_TIME);
          searchParameters.delete(URL_PARAMS.END_TIME);
        }

        return searchParameters;
      });
    }

    if (isActionsPage) {
      setSearchParameters(searchParameters => {
        const sidebarParameter = searchParameters.get(URL_PARAMS.ACTIONS);

        switch (sidebarParameter) {
          case SidebarParameters.SYSTEM:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.SYSTEM);
            break;

          case SidebarParameters.SAFETY:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.SAFETY);
            break;

          case SidebarParameters.EQUIPMENT:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.EQUIPMENT);
            break;

          case SidebarParameters.ZONE:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.ZONE);
            break;

          case SidebarParameters.ARCHIVE:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.ARCHIVE);
            break;

          default:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.ALL);
        }

        return searchParameters;
      });
    }

    if (isSettingsPage) {
      setSearchParameters(searchParameters => {
        const sidebarParameter = searchParameters.get(URL_PARAMS.ACTIONS);

        switch (sidebarParameter) {
          case SidebarParameters.PROFILE:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.PROFILE);
            break;

          case SidebarParameters.SITE_HOURS:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.SITE_HOURS);
            break;

          case SidebarParameters.AUTO_APPROVAL:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.AUTO_APPROVAL);
            break;

          case SidebarParameters.ACTIONS:
            searchParameters.set(URL_PARAMS.ACTIONS, SidebarParameters.ACTIONS);
            break;
        }

        return searchParameters;
      });
    }
  }, [isHomePage, isActionsPage, isSettingsPage, setSearchParameters, isHistoricalMode]);

  useEffect(() => {
    const startDate = searchParameters.get(URL_PARAMS.START_TIME);
    const endDate = searchParameters.get(URL_PARAMS.END_TIME);

    if (startDate && endDate) return;

    if (isHistoricalMode) {
      setSearchParameters(parameter => {
        parameter.set(URL_PARAMS.START_TIME, getTimestampInSec(initial.startDate));
        parameter.set(URL_PARAMS.END_TIME, getTimestampInSec(initial.endDate));

        return parameter;
      });
    }
  }, [isHistoricalMode, searchParameters, setSearchParameters]);
};
