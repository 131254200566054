import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReservationsAPINamespace } from '@/shared/api';
import { useModalStore } from '@/shared/store/modal';
import { notifyService } from '@/shared/services/notify.service';
import { ReservationUpdateParamsWithEvent } from '@/shared/types/reservations.types';
import { updateReservationEvent } from '@/shared/api/reservation/types';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useWorkflowEngine } from './useWorkflowEngine';
import { useUIStore } from '@/shared/store/ui';
import { QUERY_KEY, RESERVATIONS } from '@/shared/utils/constants';
import { useRef } from 'react';

export function useUpdateReservations() {
  const isEvent = useRef(false);

  const { setIsSubmitting } = useModalStore(({ setIsClose, setIsSubmitting }) => ({
    setIsClose,
    setIsSubmitting,
  }));
  const { site } = useUIStore(({ site }) => ({ site }));

  const { user } = useFetchUserData();
  const workFlowCall = useWorkflowEngine();
  const queryClient = useQueryClient();

  const { mutateAsync, ...states } = useMutation({
    mutationKey: [],

    mutationFn: async (data: ReservationUpdateParamsWithEvent) => {
      const { event, ...body } = data;
      isEvent.current = event;
      setIsSubmitting(true);
      return await ReservationsAPINamespace.updateReservation(body);
    },

    onSuccess: async successData => {
      if (isEvent.current) {
        const stateUpdate: updateReservationEvent = {
          accountResName: user?.accountResourceName ?? '',
          siteResName: site,
          reservationResName: String(successData.reservationResName),
          userResName: user?.userResourceName,
          triggerEventType:
            successData.workflowContext.currentStateName == RESERVATIONS.conflictedSub
              ? RESERVATIONS.eventResolveConflictSub
              : successData.workflowContext.currentStateName == RESERVATIONS.rejectedSub
              ? RESERVATIONS.eventNewFromRejectSub
              : RESERVATIONS.eventPendingSub,
        };

        await workFlowCall.mutateAsync(stateUpdate);
      } else {
        setIsSubmitting(false);
        await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RESERVATION] });
        notifyService.success('Reservation updated successfully');
      }
    },

    onError: error => {
      console.error(error);
      setIsSubmitting(false);
      notifyService.error('Reservation update failed');
    },
  });

  return { mutateAsync, states };
}
