import { findTagValue } from '@/shared/utils/assets.ts';
import { useGlobalStore } from '@/shared/store/global';

import { AssetBody } from '@/shared/types/assets.types';

export const useUpdateAssetCompanyName = (assets: AssetBody[]) => {
  const companies = useGlobalStore(store => store.companies);

  const updatedData = assets.map(asset => {
    const companyId = findTagValue(asset, 'company') ?? findTagValue(asset, 'firm');

    const companyName = companies.find(item => item.orgResName === companyId);

    return {
      ...asset,
      companyName: companyName?.orgName ?? 'N/A',
    };
  });

  return {
    updatedData,
  };
};
