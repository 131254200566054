import { translate } from '@/i18n';

export const ERROR_MESSAGES = {
  LOGIN_FAILED: translate('errors.loginFailed'),
  LOGIN_SUCCESS: translate('errors.loginSuccess'),
  SITES_LIST_ERROR: translate('errors.sitesListError'),
  SIGN_OUT: translate('errors.userSignOut'),
  UPDATE_USER_SUCCESS: translate('errors.updateUserSuccess'),
  UPDATE_USER_FAILED: translate('errors.updateUserFailed'),
  UPDATE_PASSWORD_SUCCESS: translate('errors.updatePasswordSuccess'),
  UPDATE_PASSWORD_FAILED: translate('errors.updatePasswordFailed'),
  USER_PASSWORD_RESET_SUCCESS: translate('errors.userPasswordResetSuccess'),
  USER_PASSWORD_RESET_ERROR: translate('errors.userPasswordResetError'),
  USER_NOT_FOUND: translate('errors.userNotFound'),
  INVALID_INPUT: translate('errors.invalidInput'),
  NETWORK_ERROR: translate('errors.networkError'),
  UNAUTHORIZED_ACCESS: translate('errors.unauthorizedAccess'),
  SERVER_ERROR: translate('errors.serverError'),
  VALIDATION_ERROR: translate('errors.validationError'),
  CHOICE_SITE: translate('errors.choiceSite'),
  GEOFENCE_CREATE_SUCCESS: translate('errors.geofenceCreateSuccess'),
  GEOFENCE_CREATE_FAILED: translate('errors.geofenceCreateFailed'),
  GEOFENCE_UPDATING: translate('errors.geofenceUpdating'),
  GEOFENCE_UPDATE_SUCCESS: translate('errors.geofenceUpdateSuccess'),
  GEOFENCE_UPDATE_FAILED: translate('errors.geofenceUpdateFailed'),
  GEOFENCE_DELETING: translate('errors.geofenceDeleting'),
  GEOFENCE_DELETE_SUCCESS: translate('errors.geofenceDeleteSuccess'),
  GEOFENCE_DELETE_FAILED: translate('errors.geofenceDeleteFailed'),
  NO_SELECTED_TAGS: translate('errors.noSelected'),
  NO_TAG_DATA_FOUND: translate('errors.noTagDataFound'),
  NO_TAG_DATA: translate('errors.noTagData'),
  NO_RESULTS: translate('errors.noResults'),
  EQUIPMENTS_UPDATE_SUCCESS: translate('alert.equipment.update.success'),
  EQUIPMENTS_UPDATE_FAILED: translate('alert.equipment.update.failed'),
  EQUIPMENTS_DEACTIVATE_SUCCESS: translate('alert.equipment.deactivate.success'),
  EQUIPMENTS_DEACTIVATE_FAILED: translate('alert.equipment.deactivate.failed'),
  ASSET_STATUS_SUCCESS: translate('alert.equipment.status.success'),
  ASSET_STATUS_FAILED: translate('alert.equipment.status.failed'),
  ASSET_STATUS_LOADING: translate('alert.equipment.status.loading'),
  MFA_SETUP_FAILED: translate('errors.mfaSetupFailed'),
  MFA_VERIFICATION_FAILED: translate('errors.mfaVerificationFailed'),
};
