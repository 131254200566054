import { queryOptions, useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/shared/utils/constants.ts';
import { SettingsAPINamespace } from '@/shared/api/settings/settings.requests.ts';
import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData.ts';
import { useUIStore } from '@/shared/store/ui';
import { useUserSettingsControlFlag } from '@/shared/feature-flags/useUserSettingsControlFlag.ts';

export namespace useSettingsHooks {
  export const getAll = () => {
    const uiStore = useUIStore();

    const { user } = useFetchUserData();
    const { userSettingsControlFlag } = useUserSettingsControlFlag();

    const getUserSettings = async () => {
      const settings = await SettingsAPINamespace.getAll({
        accountResName: user?.accountResourceName as string,
        userResName: user?.userResourceName as string,
      });

      uiStore.setUserSettings(settings?.results);

      const createSettingsService = (nameEnum: SettingsNameEnum, keyEnum: SettingsKeyEnum) =>
        new SettingsService(settings?.results, nameEnum, keyEnum);

      const userSettings = createSettingsService(
        SettingsNameEnum.UserSettings,
        SettingsKeyEnum.UserSettings,
      );

      const filterSettings = createSettingsService(
        SettingsNameEnum.MapFilterSettings,
        SettingsKeyEnum.FilterSettings,
      );

      const workerSettings = createSettingsService(
        SettingsNameEnum.MapFilterSettings,
        SettingsKeyEnum.Worker,
      );

      const equipmentSettings = createSettingsService(
        SettingsNameEnum.MapFilterSettings,
        SettingsKeyEnum.Equipment,
      );

      const readerSettings = createSettingsService(
        SettingsNameEnum.MapFilterSettings,
        SettingsKeyEnum.Reader,
      );

      const historicalFilterSettings = createSettingsService(
        SettingsNameEnum.HistoricalFilterSettings,
        SettingsKeyEnum.HistoricalFilterSettings,
      );

      // User settings
      uiStore.setCurrentLanguage(userSettings.getSelectedLanguage());
      uiStore.setCurrency(userSettings.getSelectedCurrency());
      uiStore.setSiteTimeZone(userSettings.getSelectedSiteTimeZone());
      uiStore.setDisplayTimeZone(userSettings.getSelectedDisplayTimeZone());
      uiStore.setTimeFormat(userSettings.getSelectedTimeFormat());
      uiStore.setSystemView(userSettings.getSelectedMeasurementSystem());
      // Map Filter settings
      uiStore.setMapFilter(filterSettings.getSelectedFilters());
      uiStore.setWorkerVisibility(workerSettings.getSelectedWorkersVisibility());
      uiStore.setEquipmentVisibility(equipmentSettings.getSelectedEquipmentVisibility());
      uiStore.setReaderVisibility(readerSettings.getSelectedReadersVisibility());
      // Historical Filter settings
      uiStore.clearTagsId();
      uiStore.setStartTime('');
      uiStore.setEndTime('');

      const tags = historicalFilterSettings.getSelectedTags().split(',');
      tags.map(tag => uiStore.setTagId(tag));
      uiStore.setStartTime(historicalFilterSettings.getSelectedStartTime());
      uiStore.setEndTime(historicalFilterSettings.getSelectedEndTime());

      return settings;
    };

    const settingsQueryOptions = queryOptions({
      queryKey: [QUERY_KEY.USER_SETTINGS_GET, user?.accountResourceName, user?.userResourceName],

      queryFn: getUserSettings,

      enabled:
        !!user?.accountResourceName && !!user?.userResourceName && userSettingsControlFlag.enabled,
    });

    return useQuery(settingsQueryOptions);
  };
}
