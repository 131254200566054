import { FC } from 'react';
import { RichTextField } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/common.types.ts';

type Props = WithClassName & {
  field: RichTextField | null | undefined;
};

export const ProseContent: FC<Props> = ({ field, className }) => {
  return (
    <div
      className={classnames(
        'prose text-slate-800 prose-headings:text-xl prose-h2:font-medium prose-a:text-blue-600 prose-a:transition-colors prose-a:duration-300 hover:prose-a:text-blue-400 focus:prose-a:text-blue-400',
        className,
      )}
    >
      <PrismicRichText field={field} />
    </div>
  );
};
