import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

export const useClearStateData = () => {
  const setSidebarFilterValue = useGlobalStore(state => state.setSidebarFilterValue);
  const clearLocationHistory = useGlobalStore(state => state.clearLocationsHistory);
  const clearTagsId = useUIStore(state => state.clearTagsId);

  return {
    clearTagsId,
    setSidebarFilterValue,
    clearLocationHistory,
    clearAllStateData: () => {
      clearLocationHistory();
      clearTagsId();
      setSidebarFilterValue('');
    },
  };
};
