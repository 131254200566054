import { EquipmentFormBody } from '@/shared/components/ui/Modal/Equipment/FormBody.tsx';
import { useEditEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useEditEquipment.ts';

export function EditEquipment() {
  const { params, mutate, states, deleteMutate, deleteStates } = useEditEquipment();

  const props = {
    params,
    type: 'edit' as 'edit' | 'create',
    mutate,
    states,
    deleteMutate,
    deleteStates,
  };

  return <EquipmentFormBody {...props} />;
}
