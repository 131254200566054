import { FC } from 'react';

import { ClusterIconProps } from '@/shared/components/ui/ClusterIcon/ClusterIcon.types';

export const ClusterIcon: FC<ClusterIconProps> = ({ totalTrackers, workerPercentage }) => {
  const getBorder = () => {
    return {
      background: `conic-gradient(#6366F1 0deg ${workerPercentage}deg, #FB923C ${workerPercentage}deg) top right`,
    };
  };
  return (
    <div className="anchorShowAnimation rounded-full p-2 ring-2 ring-white" style={getBorder()}>
      <div className="grid min-h-8 min-w-8 place-items-center rounded-full bg-black text-center text-base">
        <span className="font-bold text-white">{totalTrackers}</span>
      </div>
    </div>
  );
};
