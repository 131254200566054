import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';

import { WithClassName } from '@/shared/types/common.types';

interface ErrorMessageProps {
  errMsg: string;
}

export const ErrorMessage: FC<WithClassName & ErrorMessageProps> = ({ errMsg, className }) => {
  return (
    <span
      className={classnames(
        'inputErrorAnimation absolute -bottom-[13px] left-0.5 w-full truncate text-left text-[10px] text-rose-500 hover:overflow-visible',
        className,
      )}
      role="alert"
    >
      {translate(errMsg)}
    </span>
  );
};
