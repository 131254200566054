import { z } from 'zod';

import { LENGTHS, MESSAGES, REGEX } from '@/shared/schemas/constants';

export const BASE_SCHEMA = {
  LOGIN: {
    ACCOUNT_NAME: z
      .string()
      .min(1, { message: MESSAGES.EMPTY })
      .regex(REGEX.PASSWORD.WITHOUT_SPACE),
    NAME: z
      .string()
      .regex(REGEX.PASSWORD.WITHOUT_SPACE)
      .min(1, { message: MESSAGES.EMPTY })
      .transform(value => value.replace(/\s+/g, ''))
      .pipe(z.string().min(1, { message: MESSAGES.EMPTY })),
    PASSWORD: z
      .string()
      .min(1, { message: MESSAGES.EMPTY })
      .transform(value => value.replace(/\s+/g, ''))
      .pipe(z.string().min(1, { message: MESSAGES.EMPTY })),
  },
  EMAIL: {
    DEFAULT: z
      .string()
      .regex(REGEX.PASSWORD.WITHOUT_SPACE, {
        message: MESSAGES.EMAIL,
      })
      .min(LENGTHS.MIN.TEXT, { message: MESSAGES.TEXT.MIN_LENGTH })
      .regex(REGEX.EMAIL, { message: MESSAGES.INVALID_EMAIL })
      .trim(),
  },
  PHONE_NUMBER: {
    DEFAULT: z
      .string()
      .min(LENGTHS.MIN.PHONE, { message: MESSAGES.PHONE_NUMBER })
      .max(LENGTHS.MAX.PHONE, { message: MESSAGES.PHONE_NUMBER })
      .transform(value => value.replace(/[^\d+]/g, '')) // Sanitize the input
      .refine(value => REGEX.PHONE.test(value), {
        message: 'Phone number must start with a "+" sign and contain only digits.',
      }),
  },
  TEXT: {
    DEFAULT: z
      .string()
      .regex(REGEX.PASSWORD.WITHOUT_SPACE, {
        message: MESSAGES.USERNAME,
      })
      .regex(REGEX.USERNAME, { message: MESSAGES.USERNAME })
      .min(LENGTHS.MIN.TEXT, { message: MESSAGES.TEXT.MIN_LENGTH })
      .max(LENGTHS.MAX.TEXT, { message: MESSAGES.TEXT.MAX_LENGTH })
      .trim(),
    SECONDARY: z
      .string()
      .trim()
      .min(LENGTHS.MIN.TEXT, { message: MESSAGES.TEXT.MIN_LENGTH })
      .max(LENGTHS.MAX.SECONDARY_TEXT, {
        message: MESSAGES.TEXT.SECONDARY_MAX_LENGTH,
      }),
    OPTIONAL: z.string().optional(),
    COMMON: z
      .string()
      .min(LENGTHS.MIN.TEXT, { message: MESSAGES.TEXT.MIN_LENGTH })
      .max(LENGTHS.MAX.TEXT, { message: MESSAGES.TEXT.MAX_LENGTH }),
    PRICE: z
      .string()
      .regex(REGEX.OTP, {
        message: MESSAGES.OTP.DEFAULT,
      })
      .max(LENGTHS.MAX.PRICE, { message: MESSAGES.TEXT.MAX_LENGTH }),
    PHONE: z.string().min(LENGTHS.MIN.PHONE, { message: MESSAGES.TEXT.MIN_LENGTH }),
  },
  NUMBER: {
    OPTIONAL: z.number().optional(),
  },
  OTP: {
    DEFAULT: z
      .string()
      .min(LENGTHS.MIN.OTP, { message: MESSAGES.OTP.MIN_LENGTH })
      .max(LENGTHS.MAX.OTP, { message: MESSAGES.OTP.MAX_LENGTH })
      .trim()
      .regex(REGEX.OTP, { message: MESSAGES.OTP.DEFAULT }),
    SECONDARY: z.string().trim().regex(REGEX.OTP, { message: MESSAGES.OTP.DEFAULT }),
  },
  PASSWORD: {
    DEFAULT: z
      .string()
      .min(LENGTHS.MIN.PASSWORD, { message: MESSAGES.PASSWORD.MIN_LENGTH })
      .max(LENGTHS.MAX.PASSWORD, { message: MESSAGES.PASSWORD.MAX_LENGTH }),
    REGEX: z
      .string()
      .regex(REGEX.PASSWORD.WITHOUT_SPACE, {
        message: MESSAGES.PASSWORD.WITHOUT_SPACE,
      })
      .min(LENGTHS.MIN.PASSWORD, { message: MESSAGES.PASSWORD.MIN_LENGTH })
      .max(LENGTHS.MAX.PASSWORD, { message: MESSAGES.PASSWORD.MAX_LENGTH })
      .regex(REGEX.PASSWORD.DIGIT, { message: MESSAGES.PASSWORD.DIGIT })
      .regex(REGEX.PASSWORD.UPPER_CASE, {
        message: MESSAGES.PASSWORD.UPPER_CASE,
      })
      .regex(REGEX.PASSWORD.SPECIAL_CHAR, {
        message: MESSAGES.PASSWORD.SPECIAL_CHAR,
      }),
  },
  POINTS: {
    POINT: z.string().regex(REGEX.POINTS),
    DEFAULT: z.array(
      z.object({
        x: z.string().regex(REGEX.POINTS),
        y: z.string().regex(REGEX.POINTS),
        z: z.string().regex(REGEX.POINTS),
      }),
    ),
    SECONDARY: z.array(
      z.object({
        x: z.string().regex(REGEX.POINTS),
        y: z.string().regex(REGEX.POINTS),
      }),
    ),
  },
  COLOR: {
    DEFAULT: z.string().length(LENGTHS.MAX.COLOR).regex(REGEX.HEX),
  },
  DATE: {
    DEFAULT: z.date().optional(),
  },
  GEOFENCE: {
    CATEGORY: z.string(),
    NAME: z
      .string()
      .transform(value => value.replace(/\s+/g, ''))
      .pipe(z.string().min(LENGTHS.MIN.TEXT)),
  },
  FLOOR: {
    DEFAULT: z.string().optional(),
  },
  BOOLEAN: {
    DEFAULT: z.boolean().optional(),
  },
};
