import { FC, useMemo, useState } from 'react';
import {
  BoxGeometry,
  Euler,
  ExtrudeGeometry,
  ExtrudeGeometryOptions,
  Shape,
  Vector2,
  Vector3,
} from 'three';

import { GEOFENCE_INITIAL } from '@/modules/Home/utils/MapModel.config';
import { GeofenceElementProps } from '@/modules/Home/types/MapModel.types';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Zone } from '@/shared/components/ui/Modal/Zone';
import { geofenceIsRectangle } from '@/modules/Home/components/map/Geofence/Geofence.utils';
import { useMapModelDetails } from '@/modules/Home/hooks/map/useMapModelDetails';
import { useSetGeofencePosition } from '@/modules/Home/hooks/map/useSetGeofencePosition';

import { GeofenceCategoryEnum, GeofencesProps } from '@/shared/types/geofences.types';
import { Html } from '@react-three/drei';
import { useUIStore } from '@/shared/store/ui';
import { I18nProvider } from '@/i18n';
import { useSearchParams } from 'react-router-dom';
import { URL_PARAMS } from '@/shared/utils/constants.ts';
import { SidebarParameters } from '@/shared/types/parameters.types.ts';
import { ThreeEvent } from '@react-three/fiber';

export const Geofence: FC<GeofenceElementProps> = ({ geofence }) => {
  const [, setSearchParameters] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const { ref, isHovered, setIsHovered } = useMapModelDetails();

  const { position: rectPosition, size: rectSize } = useSetGeofencePosition(
    geofence?.polygon?.points ?? [],
    geofence?.polygon?.maxHeight ?? 0,
  );

  const { customPosition, customShape } = useMemo(() => {
    const points = (geofence.polygon?.points || []).map(
      pt => new Vector3(pt.x as number, pt.y as number, pt.z as number),
    );

    const anchorPoint = points[0];

    const customShape = new Shape(
      points.map(point => {
        const x = point.x - anchorPoint.x;
        const y = point.y - anchorPoint.y;
        return new Vector2(x, y);
      }),
    );

    return {
      customPosition: anchorPoint,
      customShape,
    };
  }, [geofence]);

  const geofenceColor = useMemo(() => {
    const status = geofence?.status ?? GeofenceCategoryEnum.INACTIVE;

    if (status === GeofenceCategoryEnum.INACTIVE) return GEOFENCE_INITIAL.INACTIVE_COLOR;
    else if (geofence?.geofenceCategory === GeofenceCategoryEnum.RED_ZONE)
      return GEOFENCE_INITIAL.RED_ZONE_COLOR;
    else return GEOFENCE_INITIAL.GREEN_ZONE_COLOR;
  }, [geofence]);

  const geofenceOpacity = useMemo(
    () => (showPopup || isHovered ? GEOFENCE_INITIAL.OPACITY * 2.5 : GEOFENCE_INITIAL.OPACITY),
    [showPopup, isHovered],
  );

  const isGeofenceRectangular = useMemo(() => {
    return geofenceIsRectangle(geofence);
  }, [geofence]);

  const { customGroupPosition, customGroupRotation, customGeometryOptions } = useMemo(() => {
    const minZ = (geofence.polygon?.points[0].z as number) ?? 0;
    const maxZ = geofence.polygon?.maxHeight ?? 0;
    const depth = Math.abs(maxZ - minZ);

    return {
      customGroupPosition: new Vector3(0, minZ, 0),
      customGroupRotation: new Euler(-Math.PI / 2, 0, 0),
      customGeometryOptions: {
        depth: depth,
        bevelEnabled: false,
      } as ExtrudeGeometryOptions,
    };
  }, [geofence]);

  const consumeMouseEvent = (event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();
  };

  const handleClickGeofence = (event: ThreeEvent<MouseEvent>) => {
    consumeMouseEvent(event);
    setShowPopup(!showPopup);
  };

  const handleEditGeofence = () => {
    if (geofence) {
      setSearchParameters({
        [URL_PARAMS.SIDEBAR]: SidebarParameters.EDIT_ZONE,
        [URL_PARAMS.GEOFENCE_RES_NAME]: geofence?.geofenceResName ?? '',
      });
    }
  };

  const handlePointerMissedGeofence = (event: MouseEvent) => {
    const targetElement = event.target as HTMLElement;
    const hasButtonAncestor = targetElement.closest?.('button');
    if (!hasButtonAncestor) setShowPopup(false);
  };

  return isGeofenceRectangular ? (
    <group
      position={rectPosition} //NOSONAR
      onPointerUp={consumeMouseEvent}
      onPointerMissed={handlePointerMissedGeofence}
    >
      <mesh
        ref={ref}
        renderOrder={GEOFENCE_INITIAL.DEFAULT_ORDER} //NOSONAR
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
        onClick={handleClickGeofence}
      >
        <boxGeometry
          args={rectSize} //NOSONAR
        />

        <meshStandardMaterial
          color={geofenceColor}
          opacity={geofenceOpacity}
          transparent //NOSONAR
        />

        {showPopup && (
          <GeofencePopup
            geofence={geofence}
            onEdit={handleEditGeofence}
            onClose={() => setShowPopup(false)}
          />
        )}
      </mesh>

      <lineSegments>
        <edgesGeometry
          args={[new BoxGeometry(rectSize[0], rectSize[1], rectSize[2])]} //NOSONAR
        />
        <lineBasicMaterial color={geofenceColor} />
      </lineSegments>
    </group>
  ) : (
    <group
      position={customGroupPosition} //NOSONAR
      rotation={customGroupRotation} //NOSONAR
      onPointerUp={consumeMouseEvent}
      onPointerMissed={handlePointerMissedGeofence}
    >
      <mesh
        ref={ref}
        position={customPosition} //NOSONAR
        renderOrder={GEOFENCE_INITIAL.DEFAULT_ORDER} //NOSONAR
        onPointerOver={() => setIsHovered(true)}
        onPointerOut={() => setIsHovered(false)}
        onClick={handleClickGeofence}
      >
        <extrudeGeometry
          args={[customShape, customGeometryOptions]} //NOSONAR
        />

        <meshStandardMaterial
          color={geofenceColor}
          opacity={geofenceOpacity}
          transparent //NOSONAR
        />

        {showPopup && (
          <GeofencePopup
            geofence={geofence}
            onEdit={handleEditGeofence}
            onClose={() => setShowPopup(false)}
          />
        )}
      </mesh>

      <lineSegments
        position={customPosition} //NOSONAR
      >
        <edgesGeometry
          args={[new ExtrudeGeometry(customShape, customGeometryOptions)]} //NOSONAR
        />
        <lineBasicMaterial color={geofenceColor} />
      </lineSegments>
    </group>
  );
};

interface GeofencePopupProps {
  geofence: GeofencesProps;
  onEdit: () => void;
  onClose: () => void;
}

const GeofencePopup = ({ geofence, onClose, onEdit }: GeofencePopupProps) => {
  const currentLanguage = useUIStore(store => store.currentLanguage);

  return (
    <Html zIndexRange={[1e20, 0]}>
      <I18nProvider locale={currentLanguage}>
        <div className="flex w-[387px] flex-col gap-1 rounded-lg border-[1px] border-slate-200 bg-white p-3">
          <IconButton icon="close" className="absolute right-2 top-2" onClick={onClose} />
          <Zone geofence={geofence} onEdit={onEdit} />
        </div>
      </I18nProvider>
    </Html>
  );
};
