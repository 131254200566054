import { useEffect } from 'react';
import { useUIStore } from '@/shared/store/ui';

export const useAnchorPopupState = (isOpened: boolean, togglePopup: (value: boolean) => void) => {
  const isCanvasPopupsOpen = useUIStore(state => state.isCanvasPopupsOpen);
  const setIsCanvasPopupsOpen = useUIStore(state => state.setIsCanvasPopupsOpen);

  useEffect(() => {
    if (isOpened) setIsCanvasPopupsOpen(true);
  }, [isOpened, setIsCanvasPopupsOpen]);

  useEffect(() => {
    if (!isCanvasPopupsOpen) togglePopup(false);
  }, [isCanvasPopupsOpen, togglePopup]);
};
