import { Camera, Vector2, Vector3 } from 'three';
import { DEFAULT_FLOOR_HEIGHT } from '@/shared/utils/constants.ts';
import { CONCAVE_ZONE_MAX_LINE_INTERSECTIONS } from '@/modules/Home/utils/ZoneEditor.const.ts';

export const getPointerWorldPosition = (pointer: Vector2, camera: Camera) => {
  const vec = new Vector3(pointer.x, pointer.y, 0);
  vec.unproject(camera);
  vec.setZ(DEFAULT_FLOOR_HEIGHT);

  return new Vector3(
    roundNumberToDp(vec.x, 2),
    roundNumberToDp(vec.y, 2),
    roundNumberToDp(vec.z, 2),
  );
};

export const roundNumberToDp = (n: number | string, dp: number = 2) => {
  const base = Math.pow(10, dp);
  return Math.round((Number.parseFloat(n as string) + Number.EPSILON) * base) / base;
};

export const zoneOverlapsSelf = (points: Vector3[]) => {
  if (points.length <= 1) return false;

  let maxIntersections = 0;

  for (let ii = 0; ii < points.length; ii++) {
    const sdx1 = ii % points.length;
    const edx1 = (ii + 1) % points.length;
    const sp1 = points[sdx1];
    const ep1 = points[edx1];

    let intersections = 0;

    for (let jj = 0; jj < points.length; jj++) {
      if (ii === jj) continue;

      const sdx2 = jj % points.length;
      const edx2 = (jj + 1) % points.length;
      const sp2 = points[sdx2];
      const ep2 = points[edx2];

      if (linesIntersect(sp1.x, sp1.y, ep1.x, ep1.y, sp2.x, sp2.y, ep2.x, ep2.y)) intersections++;
    }

    if (intersections > maxIntersections) maxIntersections = intersections;
  }

  return maxIntersections > CONCAVE_ZONE_MAX_LINE_INTERSECTIONS;
};

const linesIntersect = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
) => {
  x1 = roundNumberToDp(x1, 2);
  y1 = roundNumberToDp(y1, 2);
  x2 = roundNumberToDp(x2, 2);
  y2 = roundNumberToDp(y2, 2);
  x3 = roundNumberToDp(x3, 2);
  y3 = roundNumberToDp(y3, 2);
  x4 = roundNumberToDp(x4, 2);
  y4 = roundNumberToDp(y4, 2);

  const det = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);
  if (det === 0) return false;

  const lambda = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / det;
  const gamma = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / det;
  return -0.01 < lambda && lambda < 1.01 && -0.01 < gamma && gamma < 1.01;
};

export const minmax = (value: number, min: number, max: number) => {
  return Math.min(max, Math.max(min, value));
};
