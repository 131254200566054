import { useQuery } from '@tanstack/react-query';

import { QUERY_KEY } from '@/shared/utils/constants';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

export const useSetAssets = () => {
  const site = useUIStore(state => state.site);
  const companies = useGlobalStore(state => state.companies);
  const equipments = useGlobalStore(state => state.equipment);
  const workers = useGlobalStore(state => state.workers);
  const setAssets = useGlobalStore(state => state.setAssets);
  const assetsCategories = useGlobalStore(state => state.assetsCategories);
  const assetsTags = useGlobalStore(state => state.assetsTags);

  const { user } = useFetchUserData();

  return useQuery({
    queryKey: [
      QUERY_KEY.ASSETS,
      QUERY_KEY.SITES,
      user?.accountResourceName,
      site,
      assetsCategories,
      assetsTags,
      companies,
      equipments,
      workers,
    ],

    queryFn: async () => {
      const filteredAssets = [...workers, ...equipments].filter(
        asset => asset.assetStatus !== 'deleted',
      );

      setAssets(
        filteredAssets,
        assetsCategories ?? [],
        companies,
        [], // assetsGroup
        assetsTags ?? [],
        false,
      );

      return []; //assetsGroups;
    },

    enabled: !!user?.accountResourceName && !!site,
  });
};
