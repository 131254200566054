import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { classnames } from '@/shared/utils/classnames';
import { translate } from '@/i18n';

import { RoutesEnum } from '@/shared/types/enums';
import { WithClassName } from '@/shared/types/common.types';

type Props = WithClassName & {
  onClick: () => void;
};

export const SettingsMenuItem = forwardRef<HTMLAnchorElement, Props>(
  ({ className, onClick }, ref) => {
    return (
      <Link
        ref={ref}
        className={classnames(className)}
        aria-label="settings-action"
        to={RoutesEnum.SETTINGS}
        onClick={onClick}
      >
        {translate('navbar.settings')}
      </Link>
    );
  },
);

SettingsMenuItem.displayName = 'SettingsMenuItem';
