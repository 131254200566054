import { Point } from '@/shared/types/geofences.types';

export const generatePoints = (points: Point[]) => {
  const point1 = points[0];
  const point2 = points[1];

  const point3 = {
    x: point2?.x,
    y: point1?.y,
    z: point1?.z,
  };

  const point4 = {
    x: point1?.x,
    y: point2?.y,
    z: point1?.z,
  };

  return [point1, point3, point2, point4];
};
