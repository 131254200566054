import { GroupsFormBody } from '@/shared/components/ui/Modal/Groups/FormBody.tsx';
import { useEditGroup } from '@/shared/components/ui/Modal/Groups/hooks/useEditGroup.ts';

export function EditGroup() {
  const { params } = useEditGroup();

  const props = {
    params,
    type: 'edit' as 'edit' | 'create',
    handler: () => null,
  };

  return <GroupsFormBody {...props} />;
}
