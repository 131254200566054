import { baseQuery, signRequest } from '@/shared/api/utils';
import { ReadersGetPayload } from './readers.types.ts';
import { ReadersRootProps } from '@/shared/types/readers.types';

export namespace ReadersAPINamespace {
  const client = baseQuery('ENVIRONMENT');

  export const getAll = async ({
    accountResourceName,
    siteResourceName,
  }: ReadersGetPayload): Promise<ReadersRootProps> => {
    const url = `/accounts/${accountResourceName}/sites/${siteResourceName}/readers?limit=1000`;

    const signedRequest = await signRequest('ENVIRONMENT', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
