import Delete from '@/assets/icons/triangle-error.svg?react';
import Save from '@/assets/icons/question.svg?react';
import { FormTypes } from './Subcontractor/types';

export const confirmIcons = {
  delete: Delete,
  save: Save,
};

export type ConfirmProps = {
  type: 'delete' | 'save';
};

export type ReservationConfirmProps = {
  type: 'delete' | 'approve' | 'submit' | 'reject';
};

export type ConfirmAssetDeleteProps = {
  type: FormTypes;
};
