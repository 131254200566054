import { FC } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { AuthWrapper } from '@/modules/Auth/components/AuthWrapper';
import { Form } from '@/modules/Auth/components/Form';
import { useLoginUser } from '@/modules/Auth/hooks/useLoginUser';
import { useUpdateCognitoPool } from '@/modules/Auth/hooks/useUpdateCognitoPool';

import { LoginInputsProps } from '@/modules/Auth/types/types';

export const Login: FC = () => {
  useUpdateCognitoPool();

  const {
    register,
    handleSubmit,
    remembered,
    formState: { errors },
    watch,
    mutateAsync,
    clearSiteData,
  } = useLoginUser();

  const onFormSubmit: SubmitHandler<LoginInputsProps> = async values => {
    try {
      clearSiteData();
      await mutateAsync(values);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  watch();

  return (
    <AuthWrapper>
      <Form
        remembered={remembered}
        onFormSubmit={onFormSubmit}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </AuthWrapper>
  );
};
