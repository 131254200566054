import { baseQuery, signRequest } from '@/shared/api/utils.ts';

import {
  GetReservationProps,
  ReservationResponse,
  ReservationsListResponse,
  createReservationProps,
  updateReservationEvent,
} from '@/shared/api/reservation/types.ts';
import { ReservationType, ReservationUpdateParams } from '@/shared/types/reservations.types.ts';

export namespace ReservationsAPINamespace {
  const client = baseQuery('RESERVATIONS');

  export const getAllReservations = async ({
    accountResName,
    siteResName,
  }: GetReservationProps): Promise<ReservationResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations?status=active&limit=100`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
  export const getReservationHistory = async ({
    accountResName,
    siteResName,
  }: GetReservationProps): Promise<ReservationsListResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
  export const createReservations = async ({
    accountResName,
    siteResName,
    ...body
  }: createReservationProps): Promise<string> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'POST', {
      ...body,
      accountResName,
      siteResName,
    });

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });
    return result.data;
  };

  export const updateReservationState = async ({
    accountResName,
    siteResName,
    reservationResName,
    ...body
  }: updateReservationEvent): Promise<ReservationsListResponse> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations/${reservationResName}/workflow-events`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'POST', body);

    const result = await client.post(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const updateReservation = async ({
    accountResName,
    siteResName,
    reservationResName,
    ...body
  }: ReservationUpdateParams): Promise<ReservationType> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/assets/reservations/${reservationResName}`;

    const signedRequest = await signRequest('RESERVATIONS', url, 'PATCH', {
      ...body,
      siteResName,
    });

    const result = await client.patch(signedRequest.url, JSON.parse(signedRequest.body), {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
