import { EquipmentFormBody } from '@/shared/components/ui/Modal/Equipment/FormBody.tsx';
import { useCreateEquipment } from '@/shared/components/ui/Modal/Equipment/hooks/useCreateEquipment.ts';

export function CreateEquipment() {
  const { params, mutate, states } = useCreateEquipment();

  const props = {
    params,
    type: 'create' as 'edit' | 'create',
    mutate,
    states,
  };

  return <EquipmentFormBody {...props} />;
}
