import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '@/shared/components/ui/Fallbacks/Error';

export const ErrorLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Outlet />
  </ErrorBoundary>
);
