import { useState, useEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { PopupTypeEnum } from '@/shared/types/enums.ts';

export const useProgressBar = (type: PopupTypeEnum, isOpen: boolean) => {
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    if (!isOpen && percentage > 0) {
      setPercentage(0);
    }
  }, [isOpen, percentage]);

  const handleScrollDebounced = useThrottledCallback(target => {
    if (!target || type !== PopupTypeEnum.TERMS_OF_USE) return;

    const scrollHeight = target.scrollHeight - target.offsetHeight;
    const scrolled = target.scrollTop;
    const value = (scrolled / scrollHeight) * 100;

    setPercentage(value);
  }, 300);

  return {
    percentage,
    handleScrollDebounced,
    setPercentage,
  };
};
