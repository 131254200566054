import { UseFormReturn } from 'react-hook-form';

import { useGlobalStore } from '@/shared/store/global';
import { useModalStore } from '@/shared/store/modal';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';

export const useEquipment = (params?: UseFormReturn) => {
  const siteTagsList = useGlobalStore(store => store.assetsTags);
  const geofences = useGlobalStore(store => store.geofences);
  const company = useGlobalStore(store => store.companies);
  const allAssets = useGlobalStore(state => state.assets);
  const closeModal = useModalStore(state => state.setIsClose);

  const { assetsCategories, filteredAssetsCategories } = useAssetsCategories();

  const categoryTreeResourceName = assetsCategories.find(cat =>
    cat.categoryTreeName.includes('Equipment'),
  )?.categoryTreeResName;

  const assets = allAssets.filter(asset => asset.categoryTreeResName === categoryTreeResourceName);

  const modifiedCategories = filteredAssetsCategories.map(category => {
    return {
      id: category.categoryTreeResName,
      name: category.categoryTreeName,
    };
  });

  const modifiedCategoriesPaths = assetsCategories
    .find(category => category.categoryTreeResName === params?.getValues('categoryType'))
    ?.categoryPathList.map(category => {
      return {
        id: category.categoryPathResName,
        name: category.categoryPath.split('/').reverse()[0],
      };
    });

  const modifiedSiteTags = siteTagsList.map(tag => {
    return {
      id: tag.tag.tagResName,
      name: tag.tag.tagName,
    };
  });

  const modifiedUnusedSiteTags = <T>(item?: T) => {
    const result = siteTagsList.filter(tag => {
      const tagResourceName = tag.tag.tagResName;

      if (tag.asset && tagResourceName !== item) {
        return false;
      }

      const providedTagsList = assets.map(asset => {
        const tag = asset.extraProperties?.find(property => property.key === 'tag'); //NOSONAR
        return tag?.value;
      });

      return tagResourceName === item || !providedTagsList.includes(tagResourceName);
    });

    return result
      .map(item => {
        return {
          id: item.tag.tagResName,
          name: item.tag.tagName,
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase()));
  };

  const modifiedGeofences = geofences
    .map(geofence => {
      return {
        id: geofence.geofenceResName,
        name: geofence.geofenceName,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const modifiedCompanies = company.map(company => {
    return {
      id: String(company.orgResName),
      name: company.orgName,
    };
  });

  const modifiedActiveCompanies = company
    .filter(com => com.status === 'active')
    .map(company => {
      return {
        id: String(company.orgResName),
        name: company.orgName,
      };
    });

  const modifiedAssets = assets.map(asset => {
    return {
      id: String(asset.assetResName),
      name: asset.assetName,
    };
  });

  const modifiedActiveAssets = assets
    .filter(
      dt =>
        dt.assetStatus == 'active' &&
        dt.categoryTreeResName == params?.getValues('categoryType') &&
        dt.categoryPathResName == params?.getValues('categorySubType'),
    )
    .map(asset => {
      return {
        id: String(asset.assetResName),
        name: asset.assetName,
      };
    });

  const activeAssets = assets.filter(dt => dt.assetStatus == 'active');

  return {
    assets,
    categoryTreeResourceName,
    modifiedActiveCompanies,
    modifiedAssets,
    modifiedCategories,
    modifiedCategoriesPaths,
    modifiedSiteTags,
    modifiedUnusedSiteTags,
    modifiedGeofences,
    modifiedCompanies,
    modifiedActiveAssets,
    activeAssets,
    closeModal,
  };
};
