import { FC } from 'react';

import { Cluster } from '@/modules/Home/components/map/Cluster';

import { ClustersArray, Vector3NumberProps } from '@/modules/Home/types/MapModel.types.ts';

type Props = {
  clusters: ClustersArray[];
};

export const Clusters: FC<Props> = ({ clusters = [] }) => {
  return (
    <>
      {clusters?.map((clusteredTracker: ClustersArray) => {
        const positionX = Number(clusteredTracker?.posX ?? 0);
        const positionY = Number(clusteredTracker?.posY ?? 0);

        const position = [positionX, 2, positionY * -1] as Vector3NumberProps;

        return (
          <Cluster
            key={clusteredTracker?.id}
            id={clusteredTracker?.id}
            trackersData={clusteredTracker.trackersData}
            workerPercentage={clusteredTracker.workerPercetage}
            totalNumber={clusteredTracker.totalNumber}
            posX={clusteredTracker.posX}
            posY={clusteredTracker.posY}
            position={position}
            visible={true}
          />
        );
      })}
    </>
  );
};
