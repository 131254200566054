import { FC } from 'react';

import { Anchor } from '@/modules/Home/components/map/Anchor';

import { InitialTrackersProps } from '@/modules/Home/types/InitialTrackers.types.ts';
import { ClustersArray, Vector3NumberProps } from '@/modules/Home/types/MapModel.types.ts';

const SIZES: Vector3NumberProps = [0.5, 0.5, 0.5];

export const InitialTrackers: FC<InitialTrackersProps> = ({
  trackers = [],
  offset,
  showLastLocation,
  filteredClusters = [],
}) => {
  if (trackers.length === 0) return null;

  return (
    <>
      {trackers.map(tracker => {
        if (!tracker?.visible) return null;

        const positionX = Number(tracker?.location?.x) / 100;
        const positionY = Number(tracker?.location?.y) / 100;
        const position: Vector3NumberProps = [positionX, offset, positionY * -1];
        const isVisible = filteredClusters.some((item: ClustersArray) =>
          item.trackersData
            ? item.trackersData.some(tData => tData.assetResName == tracker.anchor.assetResName)
            : false,
        );

        return (
          <Anchor
            key={tracker?.tagResName}
            anchor={tracker?.anchor}
            position={position}
            size={SIZES}
            visible={!isVisible}
            active={showLastLocation}
          />
        );
      })}
    </>
  );
};
