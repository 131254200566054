import { format, formatDistanceToNow, parse } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';

import { LocaleEnum } from '@/shared/types/i18n.types';

const fallbackDate = new Date();

export const getCurrentLocale = (locale: LocaleEnum) => {
  return locale === LocaleEnum.JPN ? ja : enUS;
};

export const getCurrentTime = (date: Date): string => {
  try {
    return format(date, 'HH:mm:ss');
  } catch (error) {
    console.error('getCurrentTime', error);
    return format(fallbackDate, 'HH:mm:ss');
  }
};

export const getCurrentDate = (date: Date, locale: LocaleEnum): string => {
  return format(date ?? fallbackDate, 'E MMM dd', {
    locale: getCurrentLocale(locale),
  });
};

export const getCurrentDateAndTime = (date: Date, locale: LocaleEnum): string => {
  return format(date ?? fallbackDate, 'PPpp', {
    locale: getCurrentLocale(locale),
  });
};

export const getCurrentDay = (date: Date): string => {
  try {
    return format(date, 'EEEE');
  } catch (error) {
    console.error('getCurrentDay', error);
    return format(fallbackDate, 'EEEE');
  }
};

export const getTime = (date: number): string => {
  try {
    return format(new Date(date), 'HH:mm');
  } catch (error) {
    console.error('getTime', error);
    return format(fallbackDate, 'HH:mm');
  }
};

export const getDistanceTime = (date: number, locale: LocaleEnum): string => {
  if (!date) return '-';

  return formatDistanceToNow(new Date(date), {
    locale: locale === LocaleEnum.JPN ? ja : enUS,
  });
};

export const getDateForCalendar = (date: Date, locale: LocaleEnum): string => {
  try {
    return format(date, 'PP HH:mm', {
      locale: getCurrentLocale(locale),
    });
  } catch (error) {
    console.error('getDateForCalendar', error);
    return format(fallbackDate, 'PP HH:mm', {
      locale: getCurrentLocale(locale),
    });
  }
};

export const getTimestampInSec = (date: Date): string => {
  try {
    return format(date, 't');
  } catch (error) {
    console.error('getTimestampInSec', error);
    return format(fallbackDate, 't');
  }
};

export const getDateFromTimestamp = (timestamp: string): Date => {
  return new Date(+timestamp * 1000);
};

export const formatTimestamp = (timestamp: number, formatString: string) => {
  return format(new Date(timestamp), formatString);
};

export const formatDate = (date: Date) => {
  return format(date, 'yy-MM-dd');
};

export const convertStringToDate = (dateString: string, timeString: string) => {
  const dateTimeString = `${dateString} ${timeString}`;

  return parse(dateTimeString, 'yy-MM-dd HH:mm', new Date());
};
