import { baseQuery, signRequest } from '@/shared/api/utils';
import {
  AnalyticsDefaultProps,
  AnalyticsTripProps,
  AnalyticsUtilizationProps,
} from '@/shared/api/analytics/analytics.types.ts';
import { Response } from '@/shared/types/common.types.ts';
import { Utilization } from '@/shared/types/analytics.types.ts';
import { TripDatasource } from '@/shared/types/reports.types.ts';

export namespace AnalyticsAPINamespace {
  const client = baseQuery('ANALYTICS');

  export const getGeofenceSummaries = async ({
    accountResName,
    siteResName,
    startTime,
    endTime,
  }: AnalyticsDefaultProps): Promise<Response<Record<string, string | object>[]>> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/datasets/geofences/dwell-times/summaries?startTime=${startTime}&endTime=${endTime}&includeEpisodes=true`;
    const signedRequest = await signRequest('ANALYTICS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getGeofenceSummariesStatistics = async ({
    accountResName,
    siteResName,
  }: AnalyticsDefaultProps): Promise<unknown> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/datasets/geofences/dwell-times/summaries/statistics`;
    const signedRequest = await signRequest('ANALYTICS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getInteriorGeofenceSummaries = async ({
    accountResName,
    siteResName,
  }: AnalyticsDefaultProps): Promise<unknown> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/datasets/interior-geofences/dwell-times/summaries`;
    const signedRequest = await signRequest('ANALYTICS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getAssetUtilizationSummaries = async ({
    accountResName,
    siteResName,
    geofenceResourceNameList,
    startTime,
    endTime,
    categoryTreeResourceName,
  }: AnalyticsDefaultProps & AnalyticsUtilizationProps): Promise<Response<Utilization[]>> => {
    const mappedGeofenceResourceNameList = geofenceResourceNameList
      .map(item => `&geofenceResNames=${item}`)
      .join('');

    let url = `/accounts/${accountResName}/sites/${siteResName}/datasets/assets-utilization/motion-summaries?startTime=${startTime}&endTime=${endTime}&includeMotionIntervals=true${mappedGeofenceResourceNameList}`;

    if (categoryTreeResourceName)
      url = `/accounts/${accountResName}/sites/${siteResName}/datasets/assets-utilization/motion-summaries?treeResName=${categoryTreeResourceName}&startTime=${startTime}&endTime=${endTime}&includeMotionIntervals=true${mappedGeofenceResourceNameList}`;

    const signedRequest = await signRequest('ANALYTICS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };

  export const getGeofenceTripsSummaries = async ({
    accountResName,
    siteResName,
    startTime,
    endTime,
    startGeofenceResourceName,
    endGeofenceResourceName,
    assetResourceNameList,
  }: AnalyticsDefaultProps & AnalyticsTripProps): Promise<Response<TripDatasource[]>> => {
    const url = `/accounts/${accountResName}/sites/${siteResName}/datasets/geofences/trips/summaries?startTime=${startTime}&endTime=${endTime}&startGeofenceResName=${startGeofenceResourceName}&endGeofenceResName=${endGeofenceResourceName}&assetResNameList=${assetResourceNameList.join(
      ', ',
    )}`;
    const signedRequest = await signRequest('ANALYTICS', url, 'GET');

    const result = await client.get(signedRequest.url, {
      headers: signedRequest.headers,
    });

    return result.data;
  };
}
