import { FC, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthLayout } from '@/layout/Auth';
import { ConnectionLayout } from '@/layout/Connection';
import { ErrorLayout } from '@/layout/Error';
import { Layout } from '@/layout/Layout';
import { LazyRoutesNamespace } from '@/routes/lazy-app-routes';
import { Login } from '@/pages';
import { SecureLayout } from '@/layout/Secure';
import { UpdaterLayout } from '@/layout/Updater';
import { useMixpanelConfigUpdate } from '@/shared/hooks/global/useMixpanelConfigUpdate.ts';
import { useScrollToTop } from '@/shared/hooks/global/useScrollToTop.ts';
import { newRelicService } from '@/shared/services/newrelic.service';

import { RoutesEnum } from '@/shared/types/enums';
import { useAlertsFeatureFlag } from '@/shared/feature-flags/useAlertsFeatureFlag.ts';
import { useReservationsControlFlag } from '@/shared/feature-flags/useReservationsControlFlag.ts';
import { MFASetup } from '@/pages/Auth/MFASetup';
import { MobileAuth } from '@/pages/Auth/MobileAuth';

newRelicService.init();

export const AppRoutes: FC = () => {
  const { alertsImplementation } = useAlertsFeatureFlag();
  const { reservationControlFlag } = useReservationsControlFlag();

  const [renderAlertsRoute, setRenderAlertsRoute] = useState(false);
  const [renderReservationsRoute, setRenderReservationsRoute] = useState(false);

  useScrollToTop();
  useMixpanelConfigUpdate();

  useEffect(() => {
    setRenderAlertsRoute(alertsImplementation?.enabled as boolean);
    setRenderReservationsRoute(reservationControlFlag.enabled);
  }, [alertsImplementation.enabled, reservationControlFlag.enabled]);

  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route element={<UpdaterLayout />}>
          <Route element={<SecureLayout />}>
            <Route element={<ConnectionLayout />}>
              <Route path={RoutesEnum.HOME} element={<Layout />}>
                <Route index element={<LazyRoutesNamespace.Home />} />

                {renderReservationsRoute && (
                  <Route
                    path={RoutesEnum.RESERVATIONS}
                    element={<LazyRoutesNamespace.Reservations />}
                  />
                )}

                <Route path={RoutesEnum.EQUIPMENT} element={<LazyRoutesNamespace.Equipment />} />
                <Route path={RoutesEnum.PEOPLE} element={<LazyRoutesNamespace.Subcontractors />} />
                <Route path={RoutesEnum.REPORTS} element={<LazyRoutesNamespace.Reports />} />

                {renderAlertsRoute && (
                  <Route path={RoutesEnum.ACTIONS} element={<LazyRoutesNamespace.Actions />} />
                )}

                <Route path={RoutesEnum.SETTINGS} element={<LazyRoutesNamespace.Settings />} />
                <Route path={RoutesEnum.RELEASES} element={<LazyRoutesNamespace.Releases />} />
                <Route path={RoutesEnum.LICENSES} element={<LazyRoutesNamespace.Licences />} />
              </Route>
            </Route>
          </Route>

          <Route element={<AuthLayout />}>
            <Route path={RoutesEnum.LOGIN} element={<Login />} />
            <Route path={RoutesEnum.MFA} element={<MFASetup />} />
            <Route path={RoutesEnum.TERMS} element={<LazyRoutesNamespace.TermsOfUse />} />
            <Route path={RoutesEnum.SITE_SELECT} element={<LazyRoutesNamespace.SiteSelect />} />
            <Route
              path={RoutesEnum.RESTORE_PASSWORD}
              element={<LazyRoutesNamespace.RestorePassword />}
            />
            <Route path={RoutesEnum.MOBILE_AUTH} element={<MobileAuth />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<LazyRoutesNamespace.ErrorPage />} />
    </Routes>
  );
};
