import { FC } from 'react';

import { ActionsHooks } from '@/modules/Home/components/helpers/ActionsHooks';
import { AnalyticsHooks } from '@/modules/Home/components/helpers/AnalyticsHooks';
import { AssetHooks } from '@/modules/Home/components/helpers/AssetHooks';
import { FloorHooks } from '@/modules/Home/components/helpers/FloorHooks';
import { GeofenceHooks } from '@/modules/Home/components/helpers/GeofenceHooks';
import { LayoutHooks } from '@/modules/Home/components/helpers/LayoutHooks';
import { LocationsHooks } from '@/modules/Home/components/helpers/LocationHistoryHooks';
import { SocketConnectionHooks } from '@/modules/Home/components/helpers/SocketConnectionHooks';
import { UserSettingsHooks } from '@/modules/Home/components/helpers/UserSettingsHooks';

export const InitialHooks: FC = () => {
  return (
    <>
      <SocketConnectionHooks />
      <UserSettingsHooks />
      <LayoutHooks />
      <ActionsHooks />
      <FloorHooks />
      <GeofenceHooks />
      <AssetHooks />
      <LocationsHooks />
      <AnalyticsHooks />
    </>
  );
};
