import { ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorMessage } from '@/shared/components/form/ErrorMessage';
import { classnames } from '@/shared/utils/classnames';
import { keyExistsInTranslation } from '@/shared/utils/translation';

import { InputProps } from '@/shared/components/form/Input/Input.types';

export const Input = forwardRef(
  (
    { className, error, isErrorLabelHidden = false, errorClassName, ...props }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const isKeyExists = keyExistsInTranslation(`${props.placeholder}`);

    const renderInput = () => {
      if (isKeyExists) {
        return (
          <FormattedMessage
            id={
              keyExistsInTranslation(`${props.placeholder}`)
                ? props.placeholder
                : 'global.undefined'
            }
          >
            {message => (
              <input
                ref={ref}
                className={classnames(
                  'defaultInputStyle',
                  {
                    '!border-rose-500 focus:border-rose-300 focus:outline-none focus:ring focus:ring-rose-200':
                      error,
                  },
                  className,
                )}
                data-testid="input"
                {...props}
                placeholder={props.placeholder && message.join('')}
              />
            )}
          </FormattedMessage>
        );
      }

      return (
        <input
          ref={ref}
          className={classnames(
            'defaultInputStyle',
            {
              '!border-rose-500 focus:border-rose-300 focus:outline-none focus:ring focus:ring-rose-200':
                error,
            },
            className,
          )}
          data-testid="input"
          {...props}
        />
      );
    };

    return (
      <div className="relative w-full">
        {renderInput()}

        {error && !isErrorLabelHidden && <ErrorMessage className={errorClassName} errMsg={error} />}
      </div>
    );
  },
);

Input.displayName = 'Input';
