import { Dispatch, SetStateAction } from 'react';

export enum DropdownType {
  DEFAULT = 'default',
  MOBILE = 'mobile',
  LANGUAGE = 'language',
  SITE_SELECT = 'site-select',
  SYSTEM = 'system',
}

export type LanguageSelectProps = {
  setMenuType: Dispatch<SetStateAction<DropdownType>>;
};
