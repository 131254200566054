import { FC } from 'react';
import { Html } from '@react-three/drei';

import { Card } from '@/shared/components/ui/Card';
import { CustomIcon } from '@/shared/components/ui/CustomIcon';
import { IconButton } from '@/shared/components/buttons/IconButton';
import { Typography } from '@/shared/components/ui/Typography';
import { getCurrentDateAndTime } from '@/shared/utils/date-fns';

import { AssetTypeEnum } from '@/modules/Home/types/MapModel.types.ts';
import { LocaleEnum } from '@/shared/types/i18n.types';
import Clock from '@/assets/icons/clock-lg.svg?react';
import Organization from '@/assets/icons/organization.svg?react';

type Props = {
  color: string;
  name: string;
  type: string;
  currentLanguage: LocaleEnum;
  company: string;
  epochTime: number;
  handlePopupClose: () => void;
};

export const PathHtmlElement: FC<Props> = props => {
  const { color, company, currentLanguage, epochTime, handlePopupClose, name, type } = props;

  const iconType = type === AssetTypeEnum.Worker ? 'historicalWorkerPin' : 'historicalEquipmentPin';

  return (
    <Html>
      <div className="absolute -right-[26px] -top-14">
        <CustomIcon type={iconType} color={color} />

        <Card className="canvasPopupAnimation mapPopup">
          <IconButton icon="close" className="absolute right-2 top-2" onClick={handlePopupClose} />

          <Typography className="truncate font-semibold text-slate-800">{name}</Typography>

          <Typography className="inline-flex items-center gap-x-2 truncate">
            <Organization className="h-5 w-5 shrink-0" />

            {company}
          </Typography>

          <Typography className="inline-flex items-center gap-x-2 truncate font-medium">
            <Clock className="h-5 w-5 shrink-0" />

            {getCurrentDateAndTime(new Date(epochTime), currentLanguage)}
          </Typography>
        </Card>
      </div>
    </Html>
  );
};
