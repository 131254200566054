import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SettingsService } from '@/shared/services/settings.service.ts';
import { useUIStore } from '@/shared/store/ui';
import { useModalStore } from '@/shared/store/modal';
import { useUserSettings } from '@/shared/hooks/settings/useUserSettings.ts';

import { MapFilterPropEnum, MapFilterType } from '@/shared/types/filters.types';
import { SettingsKeyEnum, SettingsNameEnum } from '@/shared/api/settings/settings.types.ts';
import { useGlobalStore } from '@/shared/store/global';
import { usePeopleAsset } from '@/modules/Subcontractors/hooks/usePeopleAsset.ts';
import { useAssetsCategories } from '@/shared/hooks/assets/useAssetsCategories.ts';
import { RolesEnum } from '@/shared/types/enums.ts';

export const useMapFilter = () => {
  const setIsClose = useModalStore(state => state.setIsClose);
  const userSettings = useUIStore(state => state.userSettings);
  const mapFilter = useUIStore(state => state.mapFilter);
  const setMapFilter = useUIStore(state => state.setMapFilter);
  const companies = useGlobalStore(state => state.companies);
  const geofences = useGlobalStore(state => state.geofences);
  const assets = useGlobalStore(state => state.assets);
  const floorsList = useUIStore(state => state.floorsList);
  const { filteredAssetsCategories } = useAssetsCategories();
  const { siteTagsList } = usePeopleAsset();

  const form = useForm<Omit<MapFilterType, 'searchKey'>>({
    defaultValues: {
      firm: [],
      group: [],
      tag: [],
      workerRole: [],
      equipmentType: [],
      equipmentSubType: [],
      equipmentReservedBy: [],
      zoneType: [],
      zoneFloor: [],
      zoneItem: [],
      assetItem: [],
      resName: [],
    },
  });

  const formData = form.watch();

  const formFiltersSelected = Object.values(formData)
    .flatMap(value => Object.values(value))
    .some(value => value.length > 0);

  const savedFiltersSelected = Object.values(mapFilter)
    .flatMap(value => Object.values(value))
    .some(value => value.length > 0);

  const filtersSelected = formFiltersSelected || savedFiltersSelected;

  const { handleSaveSettings } = useUserSettings(
    new SettingsService(
      userSettings,
      SettingsNameEnum.MapFilterSettings,
      SettingsKeyEnum.FilterSettings,
    ),
  );

  const filterOptions = useMemo(() => {
    return {
      [MapFilterPropEnum.FIRM]: companies
        .map(({ orgName }) => orgName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.GROUP]: [],
      [MapFilterPropEnum.TAG]: siteTagsList
        ?.map(({ name }) => name)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.WORKER_ROLE]: Object.values(RolesEnum),
      [MapFilterPropEnum.EQUIPMENT_TYPE]: filteredAssetsCategories
        .map(item => item.categoryTreeName)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.EQUIPMENT_SUB_TYPE]: [
        ...filteredAssetsCategories.reduce((subTypeNames, category) => {
          category.categoryPathList.forEach(treePath => subTypeNames.add(treePath.categoryPath));
          return subTypeNames;
        }, new Set<string>()),
      ],
      [MapFilterPropEnum.EQUIPMENT_RESERVED_BY]: [],
      [MapFilterPropEnum.ZONE_TYPE]: geofences
        .map(({ geofenceCategory }) => geofenceCategory)
        .filter((value, index, self) => self.indexOf(value) === index),
      [MapFilterPropEnum.ZONE_FLOOR]: floorsList.map(({ name }) => name),
      [MapFilterPropEnum.ZONE_ITEM]: geofences.map(geofence => geofence.geofenceResName),
      [MapFilterPropEnum.ASSET_ITEM]: assets.map(asset => asset.assetResName),
      [MapFilterPropEnum.RES_NAME]: [],
    };
  }, [companies, filteredAssetsCategories, floorsList, geofences, assets, siteTagsList]);

  const handleFormSubmit = form.handleSubmit(async values => {
    try {
      await handleSaveSettings(values);
      setMapFilter(values);
      setIsClose();
    } catch (error) {
      console.error(error);
    }
  });

  const handleFormReset = () => {
    form.reset({
      firm: [],
      group: [],
      tag: [],
      workerRole: [],
      equipmentType: [],
      equipmentSubType: [],
      equipmentReservedBy: [],
      zoneType: [],
      zoneFloor: [],
      zoneItem: [],
      assetItem: [],
      resName: [],
    });
  };

  useEffect(() => {
    form.reset(mapFilter);
  }, [mapFilter, form]);

  return { setIsClose, form, filterOptions, filtersSelected, handleFormSubmit, handleFormReset };
};
