import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import * as CONST from '@/shared/utils/constants.ts';
import { ERROR_MESSAGES } from '@/shared/utils/error-messages.ts';
import { LocationsHistoryAPINamespace } from '@/shared/api/locations-history/locations-history.requests.ts';
import { QUERY_KEY } from '@/shared/utils/constants';
import { getTimestampInSec } from '@/shared/utils/date-fns.ts';
import { initial } from '@/shared/utils/calendar.ts';
import { mixpanelService } from '@/shared/services/mixpanel.service.ts';
import { notifyService } from '@/shared/services/notify.service.tsx';
import { useFetchUserData } from '@/shared/hooks/auth/useFetchUserData';
import { useGlobalStore } from '@/shared/store/global';
import { useUIStore } from '@/shared/store/ui';

import { StatusEnum } from '@/shared/types/enums.ts';

export namespace useLocationsHistoryHooks {
  export const getLastTrackersLocation = () => {
    const { user } = useFetchUserData();

    const site = useUIStore(state => state.site);
    const tags = useGlobalStore(state => state.siteTagsList);
    const setLastLocations = useGlobalStore(state => state.setLastLocations);

    const { isFetching, isError } = useQuery({
      queryKey: [QUERY_KEY.TRACKERS_HISTORY, user?.accountResourceName, site, tags],

      queryFn: async () => {
        const tagIds = tags.map(tag => tag.tagResName);

        const lastLocations = await LocationsHistoryAPINamespace.getLast({
          accountResName: user?.accountResourceName as string,
          siteResName: site,
          tagIds,
        });

        setLastLocations(lastLocations);
        return lastLocations;
      },

      enabled: !!user?.accountResourceName && !!site && tags.length > 0,
    });

    return { isFetching, isError };
  };

  export const getLocationHistory = (
    initialStartTime?: string,
    initialEndTime?: string,
    withError = true,
  ) => {
    const [searchParams] = useSearchParams();
    const { user } = useFetchUserData();

    const site = useUIStore(state => state.site);
    const tagsId = useUIStore(state => state.tagsId);
    const setLocationsHistory = useGlobalStore(state => state.setLocationsHistory);
    const clearLocationsHistory = useGlobalStore(state => state.clearLocationsHistory);
    const setStatus = useGlobalStore(state => state.setStatus);

    const startTime =
      searchParams.get(CONST.URL_PARAMS.START_TIME) ?? getTimestampInSec(initial.startDate);
    const endTime =
      searchParams.get(CONST.URL_PARAMS.END_TIME) ?? getTimestampInSec(initial.endDate);

    const decodedStartTime = decodeURIComponent(initialStartTime ?? '');
    const decodedEndTime = decodeURIComponent(initialEndTime ?? '');

    let parsedStartTime: Record<string, string> | null = null;
    let parsedEndTime: Record<string, string> | null = null;

    if (decodedStartTime) {
      try {
        parsedStartTime = JSON.parse(decodedStartTime);
      } catch (error) {
        console.error('Failed to parse Start Time:', error);
      }
    }

    if (decodedEndTime) {
      try {
        parsedEndTime = JSON.parse(decodedEndTime);
      } catch (error) {
        console.error('Failed to parse End Time:', error);
      }
    }

    const {
      isFetching: isLoading,
      fetchNextPage,
      hasNextPage,
      data,
      refetch,
    } = useInfiniteQuery({
      queryKey: [
        CONST.QUERY_KEY.LOCATION_HISTORY,
        user?.accountResourceName,
        site,
        tagsId,
        startTime,
        endTime,
        parsedStartTime,
        parsedEndTime,
      ],

      queryFn: async data => {
        const request = {
          accountResName: user?.accountResourceName ?? '',
          siteResName: site,
          tagIds: tagsId,
          startTime: parsedStartTime ? parsedStartTime.epochTime : startTime,
          endTime: parsedEndTime ? parsedEndTime.epochTime : endTime,
          pageEndResPointer: data.pageParam,
        };

        data.signal?.addEventListener('abort', () => {
          setStatus(StatusEnum.IDLE);
        });

        const result = await LocationsHistoryAPINamespace.getAll(request);

        if (result.results.length === 0 && withError)
          notifyService.error(ERROR_MESSAGES.NO_TAG_DATA_FOUND);

        mixpanelService.logRunHistory(
          new Date(Number(startTime) * 1000).toUTCString(),
          new Date(Number(endTime) * 1000).toUTCString(),
          tagsId.length,
          result.results.length,
        );

        clearLocationsHistory();
        setLocationsHistory(result.results);

        return result;
      },

      initialPageParam: '',

      getNextPageParam: lastPage =>
        lastPage.page.hasNext ? lastPage.page.pageEndResPointer : null,

      enabled: false,
    });

    return { refetch, isLoading, fetchNextPage, hasNextPage, data, user };
  };
}
